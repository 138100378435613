import { useOnClickOutside } from '@src/utility/hooks/useOnClickOutside'
import { useRef, useState } from 'react'
import { omnifulApps } from './constant.navbar'

const useOmnifulApps = () => {
  const [isAppModalOpen, setIsAppModalOpen] = useState(false)
  const [isVisible,setIsVisible] = useState(false)
  const [qrData, setQrData] = useState({
    appName: '',
    appURL: '',
    icon: '',
    availability: '',
    iconWidth: '',
    iconHeight: ''
  })
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleOpenPopup = (app) => {
    setIsAppModalOpen(true)
    setQrData({
      appName: app.appName,
      appURL: app.appURL,
      icon: app.icon,
      availability: app.availability,
      iconWidth: app.iconWidth,
      iconHeight: app.iconHeight
    })
  }

  const handleClosePopup = () => {
    setIsAppModalOpen(false)
  }

  const menuToggle = () => {
    setIsMenuOpen(prev => !prev)
    setIsVisible(false)
  }

  const dropdownRef = useRef()

  const onBlurHandler = () => {
    if (isMenuOpen) setIsMenuOpen(false)
  }

  useOnClickOutside(dropdownRef, onBlurHandler)
  return {
    handleOpenPopup,
    isAppModalOpen,
    isVisible,
    qrData,
    menuToggle,
    isMenuOpen,
    dropdownRef,
    handleClosePopup,
    omnifulApps
  }
}

export default useOmnifulApps