import Button from '@src/@core/components/ui/button';
import SideSheet from '@src/@core/components/ui/sideSheet';
import SidesheetFooter from '@src/@core/components/ui/sidesheet-footer';
import { RootState } from '@src/redux/store';
import { SHIPPING_ENTITY } from '@src/views/sales/constant/orders.constants';
import { EDIT_MODAL_TYPE } from '@src/views/sales/sales.constant';
import { editCustomerAndShippingDetails, editShipmentOrderDetails } from '@src/views/sales/store/store';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form, ModalBody } from 'reactstrap';
import { CustomerDetailsForm } from './components/CustomerDetailsForm';
import { ShippingDetailsForm } from './components/ShippingDetailsForm';
import { useEditDetailsForm } from './hooks/useEditDetailsForm';
import './styles.scss';
import { iEditDetails, iFormData, iModalProps } from './types';
import { buildCustomerRequestBody, buildShippingRequestBody, getCustomerFormData, getShippingFormData } from './utils/formUtils';

interface Props {
  isModalOpen: iModalProps
  // eslint-disable-next-line no-unused-vars
  setIsModalOpen: (value: React.SetStateAction<iModalProps>) => void
  editDetails: iEditDetails
  orderId: string
  entity_name?: 'forward_order' | 'shipment_order' | 'return_order'
}

const EditCustomerShippingModal: FC<Props> = ({ isModalOpen, setIsModalOpen, editDetails, orderId, entity_name = SHIPPING_ENTITY.FORWARD_ORDER.value }) => {
  // Hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Selectors
  const loading = useSelector((state: RootState) => state.sales.loading) as {
    editCustomerAndShippingDetails: boolean
    editShipmentOrderDetails: boolean
  };

  // Variables
  const isCustomerOrRecipient = useMemo(() => [EDIT_MODAL_TYPE.CUSTOMER_DETAILS.key, EDIT_MODAL_TYPE.RECIPIENT_DETAILS.key].includes(isModalOpen.key), [isModalOpen.key]);

  const isLoading = loading.editCustomerAndShippingDetails || loading.editShipmentOrderDetails;

  // Custom Hooks
  const editCustomerShippingForm = useEditDetailsForm();
  const { reset, handleSubmit, formState: { isDirty } } = editCustomerShippingForm;
  
  // Functions
  const handleOnOpened = useCallback(() => {
    const formData = isCustomerOrRecipient ? getCustomerFormData(editDetails) : getShippingFormData(editDetails);
    reset(formData);
  }, [editDetails, isCustomerOrRecipient, reset]);

  const handleEditSubmit = useCallback(
    (data: iFormData) => {
      const body = isCustomerOrRecipient ? buildCustomerRequestBody(data) : buildShippingRequestBody(data);

      if (entity_name === SHIPPING_ENTITY.SHIPMENT_ORDER.value) {
        // @ts-expect-error Fix Me
        dispatch(editShipmentOrderDetails({ shipment_order_id: orderId, body }));
      } else {
        // @ts-expect-error Fix Me
        dispatch(editCustomerAndShippingDetails({ order_id: orderId, body }));
      }
    },
    [dispatch, entity_name, isCustomerOrRecipient, orderId]
  );

  return (
    // @ts-expect-error Fix Me
    <SideSheet
      isOpen={isModalOpen.open}
      toggle={() => setIsModalOpen((prev) => ({ ...prev, open: false }))}
      modalClassName='modal-slide-in edit-customer-and-shipping-details-side-sheet'
      size='sm'
      onOpened={handleOnOpened}
      onClosed={() => reset()}
      title={EDIT_MODAL_TYPE[isModalOpen.key as keyof typeof EDIT_MODAL_TYPE].title}
    >
      <ModalBody className='mt-32px p-0'>
        {editDetails ? (
          <Form onSubmit={handleSubmit(handleEditSubmit)}>
            <div className='customer-and-shipping-details'>
              <CustomerDetailsForm
                editCustomerShippingForm={editCustomerShippingForm}
                isCustomerOrRecipient={isCustomerOrRecipient}
              />

              {!isCustomerOrRecipient && (
                <ShippingDetailsForm editCustomerShippingForm={editCustomerShippingForm} />
              )}
            </div>

            <SidesheetFooter>
              <Button className='save-details-button' type='submit' disabled={!isDirty} loading={isLoading}>
                {t('Save Details')}
              </Button>
            </SidesheetFooter>
          </Form>
        ) : null}
      </ModalBody>
    </SideSheet>
  );
};

export default EditCustomerShippingModal;
