import useLocalStorageTableColumns from '@src/utility/hooks/useTableColumn'
import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Card } from 'reactstrap'
import TableCheckbox from '../components/TableCheckbox'
import TableHeader from '../components/TableHeader'
import TablePagination from '../components/TablePagination'
import TableSortingIcon from '../components/TableSortingIcon'
import { TableLoadingState, TableNoDataState } from '../components/TableStates'
import { TABLE_ROW_STYLES } from '../globalTable.constant'
import { handleCheckActionColumnPresent, useCustomTableStyles, useTransformedTableColumns } from '../globalTable.utils'
import '../styles.scss'

export const ExpandableCustomTable = ({
  loading = false,
  error = false,
  success = true,
  isFilterOrSearchApplied = false,
  data = [],
  columns = [],
  columnName,
  selectable = false,
  handleSelectedRowsChange = false,
  meta = {},
  showPagination = true,
  handlePagination = false,
  expandableRows = false,
  expandableRowsComponent,
  defaultSortFieldId,
  defaultSortAsc,
  handleSort,
  TableHeaderComponent = false,
  showColumnsTableHeader = true,
  showColumnsDropdown = true,
  isActionColumnSticky = true,
  hideTableSearch = false,
  hasSerialNumber = false,
  getActionColumnData,
  searchcolumnsList,
  handleQueryParams,
  search_column_query,
  handleRefreshTable,
  tableTitle = null,
  CustomFooter = null,
  customStyles = {},
  conditionalRowStyles = [],
  tableWrapperClassNames,
  tableClassNames,
  ...props
}) => {

  const hasTableHeaderVisible = Boolean(showColumnsTableHeader && (searchcolumnsList || handleRefreshTable || showColumnsDropdown || TableHeaderComponent))

  const customTableStyles = useCustomTableStyles(customStyles);
  const transformedColumn = useTransformedTableColumns({columns, getActionColumnData, meta, hasSerialNumber})

  const {sortedColumn, setSortedColumn} = useLocalStorageTableColumns(columnName, transformedColumn)

  const [columnsToShow, setColumnsToShow] = useState(() => (showColumnsDropdown ? sortedColumn : transformedColumn))

  const tableHasActionColumn = isActionColumnSticky && handleCheckActionColumnPresent(columnsToShow)

  const [isPartial, setIsPartial] = useState(false)
  const onSelectedRowsChange = (data) => {
    if (!data.allSelected && data.selectedCount > 0) {
      setIsPartial(true)
    } else {
      setIsPartial(false)
    }
    handleSelectedRowsChange(data)
  }

  const handleColumnSort = (selectedColumn, sortDirection) => {
    handleSort({field:selectedColumn.key, order:sortDirection})
  }
  
  const handleColumnOrderChange = useMemo(() => {
    return (reArrangedColumns) => {
      for (let i = 0; i < reArrangedColumns.length; i++) {
        if (!columnsToShow[i].reorder && reArrangedColumns[i].key !== columnsToShow[i].key) {
          setColumnsToShow([...columnsToShow])
          return
        }
      }
      setSortedColumn(columnName, reArrangedColumns)
    }
  }, [])

  const indexedData = Array.isArray(data) ? data.map((item, index) => ({...item, stripeIndex:index})) : []

  const selectableProps = selectable ? {
    selectableRows: true,
    selectableRowsComponent: TableCheckbox,
    selectableRowsComponentProps: {isPartial, columnName},
    onSelectedRowsChange: onSelectedRowsChange
  } : {}

  const expandableProps = expandableRows ? {
    expandableRows: true,
    expandableRowsComponent: expandableRowsComponent
  } : {}

  const sortProps = handleSort ? {
    sortIcon: <TableSortingIcon />,
    onSort: handleColumnSort,
    defaultSortFieldId: defaultSortFieldId,
    defaultSortAsc: defaultSortAsc,
    sortServer: true
  } : {}

  const paginationProps = showPagination && meta ? {
    pagination: true,
    paginationComponent: handlePagination ? TablePagination : (CustomFooter ? CustomFooter : null),
    paginationComponentOptions: {meta, handlePagination},
    paginationServer: !!handlePagination
  } : {}

  useEffect(() => {
    setColumnsToShow(showColumnsDropdown ? sortedColumn : transformedColumn)
  }, [columns])

  return (
    <Card id='table-container-wrapper' className={classNames(tableWrapperClassNames, {'table-has-action-column': tableHasActionColumn, 'hide-border-bottom': (!TableHeaderComponent && !showPagination)})}>
      <TableHeader
        loading={loading}
        columns={transformedColumn}
        columnsList={columnsToShow}
        setColumnsList={setColumnsToShow}
        TableHeaderComponent={TableHeaderComponent}
        defaultColumns={transformedColumn}
        showColumnsTableHeader={showColumnsTableHeader}
        showColumnsDropdown={showColumnsDropdown}
        meta={meta}
        searchcolumnsList={searchcolumnsList}
        search_column_query={search_column_query}
        handleQueryParams={handleQueryParams}
        handleRefreshTable={handleRefreshTable}
        columnName={columnName}
        setSortedColumn={setSortedColumn}
        tableTitle={tableTitle}
        hideTableSearch={hideTableSearch}
      />
      <div className={classNames(`react-dataTable expandable-custom-table ${tableClassNames}`, {'top-radius' : !hasTableHeaderVisible, 'bottom-radius': !(showPagination && meta), 'rounded-16px': !hasTableHeaderVisible && !(showPagination && meta)})}>
        <DataTable
          persistTableHead
          fixedFooter
          progressPending={loading}
          progressComponent={null} 
          noDataComponent={null}
          noHeader
          data={indexedData}
          columns={columnsToShow}
          onColumnOrderChange={handleColumnOrderChange}
          className={`react-dataTable common_table ${isPartial ? 'header-checkbox-partial' : ''}`}
          customStyles={customTableStyles}
          {...selectableProps}
          {...expandableProps}
          {...sortProps}
          {...paginationProps}
          {...props}
          conditionalRowStyles={[...TABLE_ROW_STYLES, ...(conditionalRowStyles ? conditionalRowStyles : [])]}
        />
        {loading && <TableLoadingState/>}
        {!loading && indexedData.length === 0 && <TableNoDataState success={success} error={error} isFilterOrSearchApplied={isFilterOrSearchApplied}/>}
      </div>
    </Card>
  )
}
