import { API_ENDPOINTS } from '@src/api.urls';

interface ReportCategory {
  key_name: string;
  display_name: string;
}

export const loadReportsCategoriesConfig = {
  apiUrl: API_ENDPOINTS.REPORTS.GET_REPORTS_CATEGORIES,
  select: (data: { data: ReportCategory[] }, { search }: { search?: string }) => {
    const options = data.data
      ?.filter((ele: ReportCategory) => {
        if (search) {
          const regex = new RegExp(search, 'i');
          return regex.test(ele.display_name);
        }
        return true;
      }).map((ele) => ({
        value: ele.key_name,
        label: ele.display_name
      }));
    return options;
  },
  hasMore: () => false
};