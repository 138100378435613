// ** Custom Components

// ** Utils
// import { isUserLoggedIn } from '@utils'

// ** Store & Actions
import { logout } from '@store/authentication'
import { useDispatch, useSelector } from 'react-redux'

// ** Third Party Components
import { ChevronDown, ChevronUp, Download, Globe, LogOut, Settings } from 'react-feather'

// ** Reactstrap Imports
import { Dropdown, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap'

// ** Default Avatar Image
import QrcodeModal from '@src/@core/components/ui/app-download-qr-modal/ModalQRCode'
import RadioButton from '@src/@core/components/ui/radio-button'
import { useRTL } from '@src/utility/hooks/useRTL'
import useTenantInfo from '@src/utility/hooks/useTenantInfo'
import { getNameInitials } from '@src/utility/Utils'
import classNames from 'classnames'
import { useState } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { useTranslation } from 'react-i18next'
import './styles.scss'
import useOmnifulApps from './useOmnifulApps'

const UserDropdown = () => {
  // const { toggleWidget, isRTL } = props
  // ** Store Vars
  const dispatch = useDispatch()
  const userData = useSelector(state => state.auth.userData)
  const isLogoutLoading = useSelector(state => state.auth.loading) || false

  const [isOpen, setIsOpen] = useState(false)

  //** Vars
  // const userAvatar = (userData && userData.avatar) || defaultAvatar

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleLogout = () => {
    dispatch(logout())
  }

  // Language related logic

  const { i18n, t } = useTranslation()
  const [, setValue] = useRTL()

  const langObjCustomSelect = [
    { value: 'English', label: 'English', lang: 'en-US', countryCode: 'us', isRTL: false, additionalLang: ['en-GB', 'en'], isDefault: true },
    { value: 'العربية', label: 'العربية', lang: 'ar',  countryCode: 'sa', isRTL: true }
  ]

  const handleLangUpdated = (event) => {
    const value = event?.target?.value
    const { isRTL, lang } = langObjCustomSelect.find((item) => item.lang === value)

    setValue(isRTL)
    i18n.changeLanguage(lang)
  }

  
  const { handleOpenPopup, omnifulApps, handleClosePopup, qrData, isAppModalOpen } = useOmnifulApps()
  const { isPosEnabledOnUserPlan, isWmsEnabledOnUserPlan, isOmsEnabledOnUserPlan } = useTenantInfo()

  const permissionForApps = {
    PICKER_PLUS: isWmsEnabledOnUserPlan || isOmsEnabledOnUserPlan,
    POINT_OF_SALE: isPosEnabledOnUserPlan
  }

  const apps = omnifulApps({enabled: permissionForApps})

  const menuItemClasses = 'tw-flex tw-items-center tw-py-[6px] tw-gap-3 tw-text-left tw-px-3 tw-rounded-lg tw-border-0 tw-bg-transparent tw-appearance-none focus:tw-outline-none hover:tw-bg-[--bs-primary-lighter] tw-mx-1'

  const isLanguageChecked = (language) => {
    if (!i18n.language) return language.isDefault
    return i18n.language === language.lang || language.additionalLang?.includes(i18n.language) || false
  }

  const handleSettingsNavigation = () => {
    const settingElement = document.getElementById('__p_nav_settings')
    if (settingElement) {
      settingElement.click()
    }
  }

  return (
    <Dropdown 
      isOpen={isOpen} 
      toggle={toggleDropdown} 
      tag="li" 
      className="dropdown-user nav-item hub-user-dropdown-wrapper tw-w-[200px]"
      direction="down"
    >
      <DropdownToggle tag="a" className="nav-link dropdown-user-link mb-10px p-0">
        <div className='tw-me-2 tw-relative' >
          <span className='border height-40px width-40px rounded-circle bg-dark-1 p-2px flex-center-center text-dark tw-text-[16px]'>
            {getNameInitials(userData && userData.name)}
          </span>
          <span className='tw-absolute tw-bottom-0 tw-end-0 tw-flex tw-size-[18px] tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-background tw-bg-white tw-p-[2px]'>
            <Settings className='tw-text-[#5468fa]' size={12} />
          </span>
        </div>
        <div className='tw-flex tw-w-full tw-items-center tw-gap-2'>
          {/* <div className="tw-w-full">
            <div className='tw-flex tw-w-full'>
              <span className="user-name txt-body-rg text-dark tw-max-w-[124px]" title={userData && userData.name}>
                {userData && userData.name}
              </span>
            </div>
            <span 
              className="user-status text-dark-6 text-truncate" 
              title={userData && userData?.roles[0]?.name}
            >
              {userData && userData?.roles[0]?.name}
            </span>
          </div> */}
          <div className="tw-flex-1 tw-truncate tw-text-left tw-max-w-[130px]">
            <div title={userData && userData.name} className="tw-truncate tw-text-[14px] tw-leading-[18px] tw-font-medium tw-text-[#222]">{userData && userData.name}</div>
            <div className="tw-truncate tw-text-[12px] tw-leading-[16px] tw-text-[#666]">{userData && userData?.roles[0]?.name}</div>
          </div>
          <span>
            {isOpen ? <ChevronUp className='tw-font-semibold text-dark' size={20} /> : <ChevronDown className='tw-font-semibold text-dark' size={20} />}
          </span>
        </div>
      </DropdownToggle>
      <DropdownMenu className='tw-w-[240px] tw-px-0 tw-rounded-lg' style={{top: '40px'}} end>
        <div className='tw-flex tw-flex-col'>
          {/* Download Apps Section */}
          {apps.length === 0 ? null : (
            <>
              <QrcodeModal modalOpen={isAppModalOpen} toggle={handleClosePopup} qrData={qrData} />
              <div className="tw-flex tw-flex-col tw-mt-1">
                <div className="tw-px-3 tw-flex tw-items-center tw-gap-2 txt-body-md tw-text-[--bs-dark] tw-mb-1">
                  <Download size={20} className="tw-h-5 tw-w-5 tw-text-[--bs-dark]" />
                  <span>{t('Download Apps')}</span>
                </div>
                <div className='tw-flex tw-flex-col'>
                  {apps.map((app) => {
                    return app.isEnabled && (
                      <button
                        key={app.appName}
                        className={`${menuItemClasses} tw-no-underline tw-text-current tw-ps-9 hover:tw-bg-[--bs-primary-lighter]`}
                        onClick={() => {
                          handleOpenPopup(app)
                          setIsOpen(false)
                        }}>
                        <div className="txt-sub-rg tw-text-[--bs-dark-6]">{t(app.appName)}</div>
                      </button>
                    )
                  })}
                </div>
              </div>
              <div className="tw-h-[1px] tw-bg-[--bs-primary-lighter] tw-my-2 tw-mb-3" />
            </>
          )}

          {/* Language Section */}
          <div className="tw-flex tw-flex-col">
            <div className="tw-px-3 tw-flex tw-items-center tw-gap-2 txt-body-md tw-text-[--bs-dark] tw-mb-1">
              <Globe size={20} className="tw-h-5 tw-w-5 tw-text-[--bs-dark]" />
              <span>{t('Language')}</span>
            </div>
            <div
              className='tw-flex tw-flex-col'
              onClick={() => setIsOpen(false)}>
              {langObjCustomSelect.map((lng) => (
                <label
                  key={lng.lang}
                  htmlFor={lng.lang}
                  className='tw-flex tw-items-center tw-w-full tw-justify-between tw-flex-1 tw-cursor-pointer'
                  onClick={(e) => {
                    e.preventDefault()
                    handleLangUpdated({ target: { value: lng.lang } })
                  }}
                >
                  <div className={`${menuItemClasses} tw-ps-9 tw-w-full tw-flex tw-items-center tw-justify-between`}>
                    <div className="tw-flex tw-items-center tw-gap-2">
                      <ReactCountryFlag
                        svg
                        style={{width: '16px', height: '16px'}}
                        className="cursor-pointer"
                        countryCode={lng.countryCode}
                      />
                      <span className="txt-sub-rg tw-text-[--bs-dark-6]">{lng.label}</span>
                    </div>
                    <RadioButton
                      size='sm'
                      name="language"
                      id={lng.lang}
                      value={lng.lang}
                      checked={isLanguageChecked(lng)}
                      onChange={handleLangUpdated}
                      inputWrapperClass='pt-0 pb-0'
                    />
                  </div>
                </label>
              ))}
            </div>
          </div>

          <div className="tw-h-[1px] tw-bg-[--bs-primary-lighter] tw-my-1" />

          {/* Support & Settings Section */}
          <div className="txt-body-md tw-text-[--bs-dark] tw-flex tw-flex-col">
            {/* <>
              {isRTL && <style>{'#freshworks-frame-wrapper { left: 30px; right: auto !important;'}</style>}
              <button
                className={`${menuItemClasses} tw-text-current tw-ps-3 tw-py-0 freshdesk-button tw-flex tw-items-center tw-gap-2 txt-body-md`}
                onClick={() => {
                  setIsOpen(false)
                  toggleWidget()
                }}>
                <HelpCircle className="tw-h-5 tw-w-5 tw-text-[--bs-dark]" />
                <div className="">{t('Support')}</div>
              </button>
            </> */}
            {/* <div className="tw-h-[1px] tw-bg-[--bs-primary-lighter] tw-my-1" /> */}
            <button
              className={`${menuItemClasses} tw-text-current tw-ps-3 txt-body-md`}
              onClick={handleSettingsNavigation}>
              <Settings className="tw-h-5 tw-w-5 tw-text-[--bs-dark]" />
              <div className="">{t('Settings')}</div>
            </button>
            <div className="tw-h-[1px] tw-bg-[--bs-primary-lighter] tw-my-1" />
            {/* While logout there should be loading state */}
            <div 
              disabled={isLogoutLoading}
              onClick={handleLogout}
              className={classNames(`${menuItemClasses} tw-ps-3`, {
                'tw-cursor-not-allowed tw-bg-[--bs-light-4] tw-text-[--bs-dark-6]': isLogoutLoading
              })}
              type="button"
            >
              
              {(isLogoutLoading) ? <Spinner size="sm" className="tw-me-2" /> : <LogOut className="tw-h-5 tw-w-5 tw-text-[--bs-dark-6]" />}
              <div className="">
                {t('Logout')}
              </div>
            </div>
          </div>
        </div>
      </DropdownMenu>
    </Dropdown>
  )
}

export default UserDropdown
