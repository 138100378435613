/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import CancelledImage from '@src/assets/images/orders/orderLogs/Cancelled.svg';
import ConsolidatedImage from '@src/assets/images/orders/orderLogs/Consolidated.svg';
import DeliveredImage from '@src/assets/images/orders/orderLogs/Delivered.svg';
import Hub_Assigned from '@src/assets/images/orders/orderLogs/Hub_Assigned.svg';
import Packing from '@src/assets/images/orders/orderLogs/In_Packing.svg';
import In_Picking from '@src/assets/images/orders/orderLogs/In_Picking.svg';
import New_Order from '@src/assets/images/orders/orderLogs/New_Order.svg';
import Packing_Completed from '@src/assets/images/orders/orderLogs/Packing_Completed.svg';
import Picking_Completed from '@src/assets/images/orders/orderLogs/Picking_Completed.svg';
import Problem_Queue from '@src/assets/images/orders/orderLogs/Problem_Queue.svg';
import reorder from '@src/assets/images/orders/orderLogs/Reorder.svg';
import shipmentCancelled from '@src/assets/images/orders/orderLogs/Shipment_Cancelled.svg';
import Shipment_Created from '@src/assets/images/orders/orderLogs/Shipment_Created.svg';
import ShippedImage from '@src/assets/images/orders/orderLogs/Shipped.svg';
import ApprovedImage from '@src/assets/images/orders/orderLogs/approved.svg';
import AutoShipmentCreationFail from '@src/assets/images/orders/orderLogs/auto_shipment_creation_fail.svg';
import CreatedImage from '@src/assets/images/orders/orderLogs/created.svg';
import hubReassigned from '@src/assets/images/orders/orderLogs/hubReassigned.svg';
import invoiceUpdated from '@src/assets/images/orders/orderLogs/invoice_updated.svg';
import onHold from '@src/assets/images/orders/orderLogs/onhold.svg';
import orderUpdated from '@src/assets/images/orders/orderLogs/orderUpdated.svg';
import orderSynced from '@src/assets/images/orders/orderLogs/order_synced.svg';
import partialReturn from '@src/assets/images/orders/orderLogs/partialReturn.svg';
import partiallyDelivered from '@src/assets/images/orders/orderLogs/partially_delivered.svg';
import PickListGenerated from '@src/assets/images/orders/orderLogs/picklist_generated.svg';
import readyForHandover from '@src/assets/images/orders/orderLogs/readyForHandover.svg';
import Return_In_Progress from '@src/assets/images/orders/orderLogs/return_in_progress.svg';
import Return_To_Origin from '@src/assets/images/orders/orderLogs/return_to_origin.svg';
import returnedLog from '@src/assets/images/orders/orderLogs/returnedLog.svg';
import shipmentCreatedLog from '@src/assets/images/orders/orderLogs/shipment_created_log.svg';
import customer from '@src/assets/images/orders/orderLogs/updated_fields/customer.svg';
import items from '@src/assets/images/orders/orderLogs/updated_fields/items.svg';
import orderSummary from '@src/assets/images/orders/orderLogs/updated_fields/order_summary.svg';
import shippingAddress from '@src/assets/images/orders/orderLogs/updated_fields/shipping_address.svg';

// TODO: need to shift this on key, Once BE shifts this on key
export const LOGS_LOGO = {
  'New Order': New_Order,
  'Hub Assigned': Hub_Assigned,
  'On Hold': onHold,
  'Problem Queue': Problem_Queue,
  'Order Updated': orderUpdated,
  Approved: ApprovedImage,
  Created: CreatedImage,
  'In Picking': In_Picking,
  Picked: Picking_Completed,
  Consolidated: ConsolidatedImage,
  'In Packing': Packing,
  'Ready To Ship': Shipment_Created,
  Shipped: ShippedImage,
  Packed: Packing_Completed,
  'Shipment Cancelled': shipmentCancelled,
  Cancelled: CancelledImage,
  Delivered: DeliveredImage,
  'Return To Origin': Return_To_Origin,
  'Return In Progress': Return_In_Progress,
  'Partial Return': partialReturn,
  Returned: returnedLog,
  'Ready For Handover': readyForHandover,
  'Invoice Updated': invoiceUpdated,
  'Picklist Generated': PickListGenerated,
  'Order Synced': orderSynced,
  'Automatic Shipment Creation Failed': AutoShipmentCreationFail,
  'Partially Delivered': partiallyDelivered,
  'Shipment Created': shipmentCreatedLog,
  'Hub Reassigned': hubReassigned,
  'Reordered': reorder
} as const; 

export const ORDER_EDIT_UPDATED_FIELDS = {
  ITEMS: {
    label: 'Items',
    value: 'items',
    icon: items,
  },
  ORDER_SUMMARY: {
    label: 'Order Summary',
    value: 'order_summary',
    icon: orderSummary,
  },
  CUSTOMER_DETAILS: {
    label: 'Customer Details',
    value: 'customer_details',
    icon: customer,
  },
  SHIPPING_ADDRESS: {
    label: 'Shipping Address',
    value: 'shipping_address',
    icon: shippingAddress,
  },
};

export const ORDER_LOG_TRANSITION_TYPE = {
  ITEM_ADDED: 'item_added',
  ITEM_REMOVED: 'item_removed',
  ITEM_UPDATED: 'item_updated',
  UPDATED: 'updated',
};
