import { FC } from 'react';
import { iDetail } from '../types';
import PreviousDetails from './previous-details';

interface iDetailsListProps {
  details: iDetail[]
  showPreviousDetails?: boolean
}

/**
 * Component for displaying a list of details with optional previous values
 * Used within both DetailsCard and SkuDetailsCard
 * @component
 */
const DetailsList: FC<iDetailsListProps> = ({ details, showPreviousDetails = true }) => {
  return (
    <div className='d-flex flex-column gap-12px p-16px'>
      <div>
        {details.map((detail, index) => (
          <div key={`detailsList-${index}`}>
            <div className='d-flex flex-column gap-4px'>
              <div className='text-dark-5 txt-sub-rg'>{detail.label}</div>
              <div className='text-dark-6'>
                <span className='txt-sub-md text-break'>{detail.value || '-'}</span>
              </div>
            </div>
            {index < details.length - 1 && <hr className='my-12px bg-light-4' />}
          </div>
        ))}
      </div>

      {showPreviousDetails && <PreviousDetails details={details} />}
    </div>
  );
};

export default DetailsList; 