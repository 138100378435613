export const CONFIRM_CHANGES_FORM_FIELD_NAMES = {
  IS_COD_CHARGES_EDIT_MODE: 'is_cod_charges_edit_mode',
  COD_CHARGES_AMOUNT: 'cod_charges_amount',
  PAYMENT_METHOD: 'payment_method',
  PAYMENT_REFERENCE_NUMBER: 'payment_reference_number'
} as const;

export const PAYMENT_METHOD_OPTIONS = {
  CARD: {
    label: 'Credit/Debit Card',
    value: 'Credit/Debit Card'
  },
  NET_BANKING: {
    label: 'Net Banking',
    value: 'Net Banking'
  },
  BANK_TRANSFER: {
    label: 'Bank Transfer',
    value: 'Bank Transfer'
  },
  CASH: {
    label: 'Cash',
    value: 'Cash'
  },
  CHEQUE: {
    label: 'Cheque',
    value: 'Cheque'
  }
} as const; 

export const TRANSACTION_HANDLING_TYPES = {
  PENDING_TRANSACTION: 'pending_transaction',
  EDIT_ORDER_TRANSACTION: 'edit_order_transaction'
} as const;