import classNames from 'classnames';
import PropTypes from 'prop-types';
import { MoreVertical } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, UncontrolledTooltip } from 'reactstrap';
import { getActionIcon } from '../globalTable.utils';

const TableActionDropdown = (props) => {
  const { actionColumnData } = props
  if (!actionColumnData) return
  const {t} = useTranslation()
  return (
    <div className='flex-center-center w-100 cursor-pointer'>
      <UncontrolledDropdown>
        <DropdownToggle 
          className='border p-4px rounded-4px' 
          tag='span'
          id={actionColumnData.id}
          disabled={actionColumnData.disabled}
        >
          <MoreVertical size={16} color='var(--bs-dark)' />
        </DropdownToggle>
        {actionColumnData.tooltip && <UncontrolledTooltip 
          placement={actionColumnData.tooltip.placement}
          target={actionColumnData.id}
        >
          {actionColumnData.tooltip.message}
        </UncontrolledTooltip>}
        <DropdownMenu end container='body'>
          {
            actionColumnData.options?.map((item) => {
              return (
                <>
                  <div id={item.id}>
                    <DropdownItem
                      key={item.id}
                      id={item.id}
                      onClick={() => item.onClick()}
                      className={classNames('w-100 text-dark', {
                        'cursor-not-allowed text-dark-4': item.disabled
                      })}
                      toggle={false}
                      disabled={item.disabled}
                    >
                      <div className="flex-center-start gap-8px">
                        {item.customIcon || getActionIcon(item.icon)}
                        <span>
                          {t(item.label)}
                        </span>
                      </div>
                    </DropdownItem>
                  </div>
                  {item.tooltip?.show && <UncontrolledTooltip 
                    placement={item.tooltip.placement}
                    target={item.id}
                    offset={[0, 5]}
                  >
                    {t(item.tooltip.message)}
                  </UncontrolledTooltip>}
                </>
              )
            })
          }
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  )
}

TableActionDropdown.propTypes = {
  actionColumnData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    tooltip: PropTypes.shape({
      show: PropTypes.bool,
      placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
      message: PropTypes.string.isRequired
    }),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        icon: PropTypes.string,
        customIcon:  PropTypes.element,
        onClick: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        tooltip: PropTypes.shape({
          show: PropTypes.bool,
          placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
          message: PropTypes.string
        })
      })
    )
  })
};

TableActionDropdown.defaultProps = {
  actionColumnData: null
};

export default TableActionDropdown;