import '@src/utility/hooks/use-app-imports.ts'
import { lazy } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { ENVIRONMENT } from './App.constants'
import { store } from './redux/store'
import { sentryInitAsync } from './utility/Utils'


if (import.meta.env.VITE_ENVIRONMENT !== ENVIRONMENT.LOCAL) {
  sentryInitAsync()
}

const LazyApp = lazy(() => import('./App'))
const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <LazyApp />
  </Provider>
)
