import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import ImageActions from '@src/@core/components/ui/image-preview-modal'
import { FILE_TYPES } from '@src/App.constants'
import crossIconImg from '@src/assets/images/catalog/bundlesku/cross-icon-red.svg'
import defaultImg from '@src/assets/images/catalog/bundlesku/default-img.svg'
import maximizeIcon from '@src/assets/images/catalog/maximize.svg'
import { isFileTypeAndSizeValid } from '@src/utility/Utils'
import classNames from 'classnames'
import { useState } from 'react'
import { Plus } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { MAX_BUNDLE_IMAGE_CAN_UPLOAD } from '../../catalog.constants'
import './styles.scss'

const SkuImageHandler = ({ uploadedImages, currentSelectedImage, imageRef, loading = false, updateBundleSkusRes, imageGettingUpload, addImageRef, setCurrentSelectedImage, setUploadedImages, isView = false }) => {

  const [isImageActionModalOpen, setIsImageActionModalOpen] = useState(false)
  const validFormat = [FILE_TYPES.PNG.type, FILE_TYPES.JPEG.type, FILE_TYPES.JPG.type]
  const imageUploadHandler = (e) => {
    const copyOfUploadedImage = [...uploadedImages]
    if (copyOfUploadedImage.length <= MAX_BUNDLE_IMAGE_CAN_UPLOAD) {
      for (let i = 0; i < e.target.files.length; i++) {
        if (!isFileTypeAndSizeValid(e.target.files[i], validFormat)) {
          continue
        }
        if (!(copyOfUploadedImage[copyOfUploadedImage.length - 1].value)) {
          copyOfUploadedImage[copyOfUploadedImage.length - 1] = {
            file: e.target.files[i],
            value: URL.createObjectURL(e.target.files[i])
          }
        } else if (copyOfUploadedImage.length >= MAX_BUNDLE_IMAGE_CAN_UPLOAD) {
          CustomToast('You have uploaded maximum number of Images.', { my_type: 'error', audioReqired: true })
          break
        } else {
          const data = {
            file: e.target.files[i],
            value: URL.createObjectURL(e.target.files[i])
          }
          copyOfUploadedImage.push(data)
        }

      }
    }
    // if (copyOfUploadedImage.length === 0) {

    // }
    // if (copyOfUploadedImage.length < MAX_BUNDLE_IMAGE_CAN_UPLOAD && copyOfUploadedImage &&  (copyOfUploadedImage[copyOfUploadedImage.length - 1].value)) {
    //   copyOfUploadedImage.push({ value: '' })
    // }
    if (copyOfUploadedImage.length < MAX_BUNDLE_IMAGE_CAN_UPLOAD && (copyOfUploadedImage[copyOfUploadedImage.length - 1].value)) {
      copyOfUploadedImage.push({ value: '' })
    }
    setCurrentSelectedImage((copyOfUploadedImage[copyOfUploadedImage.length - 1].value) ? copyOfUploadedImage.length - 1 : copyOfUploadedImage.length - 2)
    setUploadedImages(copyOfUploadedImage)
  }

  const handleImageRemover = (index) => {
    const result = uploadedImages.filter((ele, ind) => {
      return ind !== index
    })
    const needAddIcon = result.filter((ele) => {
      return ele.value === ''
    })
    if (!needAddIcon.length) {
      result.push({ value: '' })
    }
    if (index === currentSelectedImage) {
      if (currentSelectedImage !== 0) {
        setCurrentSelectedImage((p) => p - 1)
      }
    } else {
      if (
        !(
          currentSelectedImage < index &&
          uploadedImages.length > currentSelectedImage
        )
      ) {
        setCurrentSelectedImage((p) => p - 1)
      }
    }
    setUploadedImages(result)
  }

  const { t } = useTranslation()
  return (
    <>
      <div className='main-img-container main-img-container-div main-img-create-sku-container d-flex flex-column gap-10px align-items-center position-relative rounded-12px'>
        {(isView ? uploadedImages.length >= 1 : uploadedImages.length >= 2) && (
          <div
            className='position-absolute maximize-image cursor-pointer'
            onClick={() => setIsImageActionModalOpen(true)}
          >
            <div className='img-wrapper'>
              <img src={maximizeIcon} />
            </div>
          </div>
        )}

        <label htmlFor='select-first-image'>
          <div
            className={classNames('img-big-container image-viewer-container img-big-container-create-sku', {
              'file-exists border border-dark-2': uploadedImages[currentSelectedImage]?.value,
              'd-flex flex-column justify-content-center align-items-center gap-8px':
                !uploadedImages[currentSelectedImage]?.value,
              'border-dashed border-primary  cursor-pointer': !uploadedImages[currentSelectedImage]?.value && !isView
            })}
          >
            <img
              src={
                uploadedImages[currentSelectedImage]?.value || defaultImg
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = defaultImg
              }}
              ref={imageRef}
              className={classNames({
                'w-100 h-100 exist-images rounded-6px':
                uploadedImages[currentSelectedImage]?.value,
                'default-width':
                !uploadedImages[currentSelectedImage]?.value
              })}
              alt='item-image'
            />

            {(uploadedImages.length < 2 && !isView) && (
              <div>
                <div className='d-flex gap-8px align-items-center '>
                  <Plus size={10} color='var(--bs-primary)' />
                  <div className='txt-sub-md cursor-pointer' style={{ color: 'var(--bs-primary)' }}>
                    {t('Add Images')}
                  </div>
                </div>

                <input
                  id='select-first-image'
                  type='file'
                  className='d-none'
                  accept='image/png, image/jpeg, image/jpg'
                  multiple
                  disabled={loading || updateBundleSkusRes.loading || imageGettingUpload}
                  onChange={(e) => imageUploadHandler(e)}
                />
              </div>
            )}
          </div>
        </label>

        {(isView ? uploadedImages.length > 0 : uploadedImages.length > 1) && <div className='gap-20px image-children-wrapper image-gallery-wrapper mt-0'>
          {
            uploadedImages.map((ele, ind) => {
              if ((ind === uploadedImages.length - 1 && !ele.value) && !isView) {
                return (
                  <div key={ind}>
                    <label htmlFor='select-image' id='info-tooltip'>
                      <div className='d-flex gap-4px'>
                        <div className='flex-center-center add-container-solid border border-dark'>
                          <div
                            className='h-100 w-100 d-flex align-items-center justify-content-center cursor-pointer'
                            ref={addImageRef}
                          >
                            <Plus color='var(--bs-dark)' />
                          </div>
                        </div>
                      </div>
                    </label>
                    <input
                      id='select-image'
                      type='file'
                      className='d-none'
                      accept='image/png, image/jpeg, image/jpg'
                      multiple
                      disabled={loading || updateBundleSkusRes.loading || imageGettingUpload}
                      onChange={(e) => imageUploadHandler(e)}
                    />
                  </div>
                )
              } else {
                return (
                  <div
                    key={ind}
                    className={classNames(
                      'd-flex justify-content-center align-items-center add-container-solid',
                      {
                        'selected-index':
                          (currentSelectedImage === ind &&
                            (isView ? uploadedImages.length > 0 : uploadedImages.length > 1)),
                        'not-selected-index':
                          currentSelectedImage !== ind
                      }
                    )}
                  >
                    <div className='h-100 w-100 d-flex align-items-center justify-content-center cursor-pointer position-relative'>
                      {!isView && <div
                        className='position-absolute image-remover d-flex justify-content-center'
                        onClick={() => handleImageRemover(ind)}
                      >
                        <img src={crossIconImg} width='20px' />
                      </div>
                      }

                      <img
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null
                          currentTarget.src = defaultImg
                        }}
                        src={ele.value}
                        className='exist-images w-100 h-100 rounded'
                        onClick={() => setCurrentSelectedImage(ind)}
                      />
                    </div>
                  </div>
                )
              }
            })}
        </div>}
      </div>
      <ImageActions
        isOpen={isImageActionModalOpen}
        toggle={() => setIsImageActionModalOpen(false)}
        imgArray={uploadedImages}
        imageUploadHandler={imageUploadHandler}
        setCurrentSelectedImage={setCurrentSelectedImage}
        currentSelectedImage={currentSelectedImage}
        uploadedImages={uploadedImages}
        handleImageRemover={handleImageRemover}
        imageRef={imageRef}
        setUploadedImages={setUploadedImages}
        isView={isView}
      />
    </>
  )
}

export default SkuImageHandler
