import { Countries } from '@src/views/hubs/utils/countries';
import { EDIT_MODAL_TYPE } from '@src/views/sales/sales.constant';
import { EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES } from '../editCustomerShippingModal.constant';
import { iCustomerBody, iEditDetails, iFormData, iShippingAddress } from '../types';

export const getCustomerFormData = (editDetails: iEditDetails) => {
  const mobileDetails = editDetails.mobile?.split('-') || [];
  const countryData = mobileDetails.length === 2 
    ? Countries.find(country => country.calling_code === mobileDetails[0]) 
    : '';

  return {
    [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.FIRST_NAME]: editDetails.first_name || '',
    [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.LAST_NAME]: editDetails.last_name || '',
    [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.EMAIL]: editDetails.email || '',
    [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.MOBILE]: {
      [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.CONTACT_NUMBER]: mobileDetails[1] || mobileDetails[0] || '',
      [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.COUNTRY_CODE]: countryData || ''
    }
  };
};

export const getShippingFormData = (editDetails: iEditDetails) => {
  const mobileDetails = editDetails.phone?.split('-') || [];
  const countryData = mobileDetails.length === 2 
    ? Countries.find(country => country.calling_code === mobileDetails[0]) 
    : '';

  return {
    [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.MOBILE]: {
      [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.CONTACT_NUMBER]: mobileDetails[1] || mobileDetails[0] || '',
      [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.COUNTRY_CODE]: countryData || ''
    },
    [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.FIRST_NAME]: editDetails.first_name || '',
    [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.LAST_NAME]: editDetails.last_name || '',
    [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.EMAIL]: editDetails.email || '',
    [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.ADDRESS1]: editDetails.address1 || '',
    [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.ADDRESS2]: editDetails.address2 || '',
    [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.COUNTRY]: editDetails.omniful_country ? {
      label: editDetails.omniful_country,
      value: editDetails.omniful_country_id,
      code: editDetails.country_code || '',
      currencyCode: editDetails.country_currency_code || ''
    } : null,
    [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.STATE]: editDetails.omniful_state ? {
      label: editDetails.omniful_state,
      value: editDetails.omniful_state_id,
      code: editDetails.state_code || ''
    } : null,
    [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.CITY]: editDetails.omniful_city ? {
      label: editDetails.omniful_city,
      value: editDetails.omniful_city_id
    } : null,
    [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.ZIP]: editDetails.zip || ''
  };
};

export const buildCustomerRequestBody = (data: iFormData) => {
  const body = {
    customer: {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email || undefined
    } as iCustomerBody,
    edit_entity_type: EDIT_MODAL_TYPE.CUSTOMER_DETAILS.value
  };

  if (data.mobile.contactNumber && typeof data.mobile.countryCode === 'object') {
    body.customer = {
      ...body.customer,
      mobile: data.mobile.contactNumber,
      country_calling_code: data.mobile.countryCode.calling_code,
      country_code: data.mobile.countryCode.country_code
    };
  }

  return body;
};

export const buildShippingRequestBody = (data: iFormData) => {
  const body = {
    shipping_address: {
      first_name: data.first_name,
      last_name: data.last_name,
      address1: data.address1,
      address2: data.address2,
      zip: data.zip || undefined,
      email: data.email || undefined,
      country_currency_code: data.country?.currencyCode,
      omniful_city: data.city?.value ? {
        id: data.city.value,
        name: data.city.label
      } : {},
      omniful_state: data.state?.value ? {
        id: data.state.value,
        name: data.state.label,
        code: data.state.code
      } : {},
      omniful_country: data.country?.value ? {
        id: data.country.value,
        name: data.country.label,
        code: data.country.code
      } : {}
    } as iShippingAddress,
    edit_entity_type: EDIT_MODAL_TYPE.SHIPPING_DETAILS.value
  };

  if (data.mobile.contactNumber && typeof data.mobile.countryCode === 'object') {
    body.shipping_address = {
      ...body.shipping_address,
      mobile: data.mobile.contactNumber,
      country_calling_code: data.mobile.countryCode.calling_code,
      country_code: data.mobile.countryCode.country_code
    };
  }

  return body;
}; 