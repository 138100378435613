import Modal from '@src/@core/components/ui/modal';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface iSalesChannelSyncImpactWarningModalProps {
  isOpen: boolean
  handleClose: () => void
  handleContinue: () => void
  hasGiftItem: boolean
}

const SalesChannelSyncImpactWarningModal: FC<iSalesChannelSyncImpactWarningModalProps> = ({
  isOpen,
  handleClose,
  handleContinue,
  hasGiftItem
}) => {
  const { t } = useTranslation();
  
  const warningContent = (
    <>
      <div className='bg-warning-light p-16px rounded-8px'>
        <div className='d-flex gap-8px align-items-start'>
          <span className='txt-body-sb'>{t('Before proceeding, please note:')}</span>
        </div>
        <ul className='mt-8px ps-16px mb-0'>
          <li className='txt-sub-rg'>
            {t('Editing this order will disable order sync for updates from the sales channel.')}
          </li>
          <li className='txt-sub-rg'>
            {t('Order cancellations will still sync as usual.')}
          </li>
          {hasGiftItem && <li className='txt-sub-rg'>
            {t('This order includes gift item(s) from Gifting Automation Rules, which cannot be edited. If the rules are no longer met after the edit, these items will be removed.')}
          </li>}
        </ul>
      </div>
    </>
  );

  return (
    // @ts-expect-error fix me
    <Modal
      isOpen={isOpen}
      title="Are you sure you want to edit this Sales Channel order?"
      content={warningContent}
      primaryButtonText="Cancel"
      secondaryButtonText="Proceed"
      closeModalHandler={handleClose}
      primaryButtonHandler={handleClose}
      secondaryButtonHandler={handleContinue}
      hasComponentSideContentTranslation={false}
    />
  );
};

export default SalesChannelSyncImpactWarningModal; 