import { FC } from 'react';
import { ORDER_EDIT_UPDATED_FIELDS } from '../constants';
import { getTransitionTag } from '../utils';
// @ts-expect-error fix me
import no_sku_image from '@src/assets/images/omniful/no_sku_image.svg';
import { useTranslation } from 'react-i18next';

interface iCardHeaderProps {
  icon: string
  title: string
  value: string
  status?: string
}

/**
 * Header component for detail cards
 * Shows icon, title and transition status tag
 * Used in both DetailsCard and SkuDetailsCard
 * @component
 */
const CardHeader: FC<iCardHeaderProps> = ({ icon, title, value, status }) => {
  return (
    <div className='card-header flex-center-between gap-16px p-16px'>
      <div className='flex-center-start gap-8px'>
        <img src={icon} alt={title} />
        <div className='txt-body-md text-dark'>{title}</div>
      </div>
      {value !== ORDER_EDIT_UPDATED_FIELDS.ITEMS.value && getTransitionTag(status)}
    </div>
  );
};

interface iSkuCardHeaderProps {
  img?: string
  name: string
  code: string
  transitionsType: string
}

/**
 * Header component for SKU items within SKU details card
 * Shows SKU image, name, code and transition tag
 * Handles fallback for missing images
 * @component
 */
const SkuCardHeader: FC<iSkuCardHeaderProps> = ({ img, name, code, transitionsType }) => {
  const { t } = useTranslation();
  
  const handleImageError = (currentTarget: HTMLImageElement) => {
    currentTarget.onerror = null;
    currentTarget.src = no_sku_image;
  };

  return (
    <div className='flex-start-between p-12px border-bottom border-light-4'>
      <div className='d-flex gap-8px text-break'>
        <div className='flex-center-center max-height-40px max-width-40px border border-dark-1 rounded-6px overflow-hidden'>
          <img 
            onError={({ currentTarget }) => handleImageError(currentTarget)} 
            src={img || no_sku_image} 
            className="h-full w-full object-fit-fill"  
            height={40} 
            width={40} 
            alt={name}
            loading="lazy"
          />
        </div>
        <div className='d-flex flex-column gap-4px'>
          <div className='txt-sub-md text-dark'>{name}</div>
          <div className='d-flex gap-2px text-dark-6'>
            <span className='txt-sub-rg text-nowrap'>{t('SKU Code')}</span>
            <span>:</span>
            <span className='txt-sub-md'>{code}</span>
          </div>
        </div>
      </div>
      {getTransitionTag(transitionsType)}
    </div>
  );
};

export { CardHeader, SkuCardHeader };
