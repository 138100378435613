import noImage from '@src/assets/images/omniful/missing_sales_channel.svg'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const SalesChannelLogo = ({ imageUrl, channelName, imageAlt, displayChannelName }) => {
  return (
    <div className={classNames('w-100 h-100', {'flex-center-start' : displayChannelName, 'flex-center-center': !displayChannelName})}>
      {
        imageUrl ?
          <>
            <img
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = noImage
              }}
              src={imageUrl || noImage}
              alt={imageAlt || channelName}
              className="object-fit-contain"
              width={60}
              height={30}
              title={channelName}
              loading="lazy"
            />
            {displayChannelName && <span className="ms-1">{(channelName && imageUrl) ? `(${channelName})` : '-'}</span>}
          </>
          : '-'
      }
    </div>
  )
}

SalesChannelLogo.propTypes = {
  imageUrl: PropTypes.string,
  channelName: PropTypes.string,
  imageAlt: PropTypes.string,
  displayChannelName: PropTypes.bool
}

SalesChannelLogo.defaultProps = {
  imageUrl: '',
  channelName: '',
  imageAlt: 'no img',
  displayChannelName: false
}

export default SalesChannelLogo
