// imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import { TENANT_TYPE } from '@src/App.constants'
import { API_ENDPOINTS } from '@src/api.urls'
import { GetDropdownOptions } from '@src/components/commonFunctionNexus'
import { axiosInstance } from '@src/network/AxiosInstance'
import { bindToChannelEvent } from '@src/network/RealTime'
import { paramsSerializer } from '@src/utility/Utils'
import { LABELS_ADDITIONAL_OPTIONS, SIMPLE } from './catalog.constants'

// utils Functions
const urlPrefixProvider = (sellerId) => `/api/v1/products/sellers/${parseInt(sellerId)}`

export const importSkusBulk = createAsyncThunk(
  'catalog/importBulkSkus',
  async (params, store) => {
    const allStore = store.getState()
    const sellerId = params.sellerId || (allStore.auth.userData.tenant.type !== 'Self' ? allStore.catalog.selectedSeller.value : allStore.auth.globalSeller.seller_id)
    const url = `${urlPrefixProvider(sellerId)}/skus/import`
    const res = await axiosInstance.post(url, params?.body)
    return res.data
  }
)

export const createBundleSku = createAsyncThunk(
  'catalog/bundle/create',
  async ({ body}, store) => {
    const sellerId = body.sellerId || store.getState()?.catalog?.selectedSeller?.value
    const type = body.creation_type || ''
    delete body.sellerId
    delete body.creation_type

    const url = `${urlPrefixProvider(sellerId)}/skus`
    const res = await axiosInstance.post(url, body)
    if (res?.data?.is_success) {
      CustomToast(`${type} created successfully`, { my_type: 'success' })
    }
    return res.data
  }
)

// Get Apis

export const getSkuMaster = createAsyncThunk(
  'catalog/skuMaster',
  async ({ ...params }, store) => {
    const sellerId = params?.seller_id || store.getState()?.catalog?.selectedSeller?.value
    delete params?.seller_id

    const url = `${urlPrefixProvider(sellerId)}/skus?${paramsSerializer(params)}`

    const res = await axiosInstance.get(url)
    return { data: res.data }
  }
)

export const getAllArchiveSkus = createAsyncThunk(
  'catalog/archive-SKUs',
  async ({ ...params }, store) => {
    const sellerId = params?.seller_id || store.getState()?.catalog?.selectedSeller?.value
    delete params?.seller_id

    const url = `${urlPrefixProvider(sellerId)}/skus?${paramsSerializer(params)}`
    
    const res = await axiosInstance.get(url)
    return { data: res.data }
  }
)

export const getBundleSkus = createAsyncThunk(
  'catalog/bundleSkus-kit',
  async ({ ...params }, store) => {
    const sellerId = params?.seller_id || store.getState()?.catalog?.selectedSeller?.value
    delete params?.seller_id

    const url = `${urlPrefixProvider(sellerId)}/skus?${paramsSerializer(params)}`

    const res = await axiosInstance.get(url)
    return { data: res.data }
  }
)

export const getAllArchiveKit = createAsyncThunk(
  'catalog/archive-bundleSkus-kit',
  async ({ ...params }, store) => {
    const sellerId = params?.seller_id || store.getState()?.catalog?.selectedSeller?.value
    delete params?.seller_id

    const url = `${urlPrefixProvider(sellerId)}/skus?${paramsSerializer(params)}`
    const res = await axiosInstance.get(url)
    return { data: res.data }
  }
)

export const getAssembledSkus = createAsyncThunk(
  'catalog/assembled-kits',
  async ({ ...params }, store) => {
    const sellerId = params?.seller_id || store.getState()?.catalog?.selectedSeller?.value
    delete params?.seller_id

    const url = `${urlPrefixProvider(sellerId)}/skus?${paramsSerializer(params)}`
    const res = await axiosInstance.get(url)
    return { data: res.data }
  }
)

export const updateBundleSkus = createAsyncThunk(
  'catalog/update-sku',
  async ({ seller_id, sku_id, body, update_type }) => {
    const sellerId = seller_id
    const type = body.creation_type || ''
    delete body.creation_type

    const url = `${urlPrefixProvider(sellerId)}/skus/${sku_id}`
    const res = await axiosInstance.put(url, body, {params: update_type || '' })
    return { data: res, type: type || '' }
  }
)

export const getSkus = createAsyncThunk(
  'catalog/skus',
  async (params, store) => {
    const sellerId = store.getState()?.catalog?.selectedSeller?.value
    const res = await axiosInstance.get(
      `${urlPrefixProvider(sellerId)}/skus?${paramsSerializer(params)}`
    )
    return { data: res }
  }
)

export const getProduct = createAsyncThunk(
  'catalog/product',
  async (params, store) => {
    const sellerId = store.getState()?.catalog?.selectedSeller?.value
    const res = await axiosInstance.get(
      `${urlPrefixProvider(sellerId)}/products/${params.product_id}`
    )
    return res.data.data
  }
)

export const getSku = createAsyncThunk('catalog/sku', async ({params, query}, store) => {
  const sellerId = params?.sellerId || store.getState()?.catalog?.selectedSeller?.value
  const url = `${urlPrefixProvider(sellerId)}/skus/${params.sku_id}?${paramsSerializer(query)}`
  const res = await axiosInstance.get(url)
  return res.data
})

export const getBundleChildSku = createAsyncThunk('catalog/bundle-sku', async ({params, query}, store) => {
  const sellerId = params?.sellerId || store.getState()?.catalog?.selectedSeller?.value
  const url = `${urlPrefixProvider(sellerId)}/skus/${params.sku_id}?${paramsSerializer(query)}`
  const res = await axiosInstance.get(url)
  return res.data
})

export const getSkuSalesChannel = createAsyncThunk(
  'catalog/skuSalesChannel',
  async (params, store) => {
    const sellerId = store.getState()?.catalog?.selectedSeller?.value
    // seller_sales_channels/99/sales_channel_skus/6453cd4d6e413aa4c7ba7f05
    const res = await axiosInstance.get(
      `${urlPrefixProvider(sellerId)}/sales_channel_skus/${params.sku_id}`
    )
    return res.data
  }
)

export const getSellerSalesChannelSkus = createAsyncThunk(
  'catalog/sellerSalesChannelSkus',
  async (params) => {
    const seller_id = +params.seller
    const { sales_channel_id: seller_sales_channel_id, ...restParams } = params
    const res = await axiosInstance.get(
      `${urlPrefixProvider(seller_id)}/sales_channel_skus`,
      { params: { ...restParams, seller_sales_channel_id } }
    )
    return res.data
  }
)

export const getSalesChannelListingData = createAsyncThunk(
  'catalog/salesChannels',
  async (_, store) => {
    const allStore = store.getState()
    const sellerId = allStore.auth.userData.tenant.type !== TENANT_TYPE.SELF_USAGE ? allStore.catalog.selectedSeller?.value : allStore.auth.globalSeller?.seller_id
    const res = await axiosInstance.get(
      `/api/v1/sales_channels/sellers/${sellerId}/sales_channels`
    )
    return res
  }
)

export const getSellersAsync = async (search, loadedOptions, { page, is_active = true}) => {
  const queryParams = {is_active, sort: 'name_asc', ...(search ? { search_column: 'name', search_query: search } : { page }) }
  const config = {
    url: API_ENDPOINTS.COMMON.GET_ALL_SELLERS,
    isLoadedOptions: true,
    page
  }
  const response = await GetDropdownOptions(queryParams, config)
  return response
}

export const getCurrencyData = async (search, loadedOptions, { page = 1 }) => {
  const queryParams = { currency: search,  page }

  const config = {
    url: API_ENDPOINTS.COMMON.GET_ALL_COUNTRIES,
    isLoadedOptions: true,
    page
  }
  const response = await GetDropdownOptions(queryParams, config)
  const modifiedResponse = response.options.map((ele) => {
    return {label: ele.currency.code, value: ele.currency.code }
  })
  const newModifiedResponse = []
  
  modifiedResponse.forEach((ele) => {
    if (!newModifiedResponse.some((item) => {
      return item.value === ele.value
    })) {
      newModifiedResponse.push(ele)
    }
  })

  const updatedOptions = newModifiedResponse.filter((ele) => {
    return (!loadedOptions.some((item) => {
      return ele.value === item.value
    }))
  })
  return {...response, options: updatedOptions}
}

export const getSellers = createAsyncThunk('/sellers', async () => {
  const res = await axiosInstance.get('/api/v1/tenant/sellers', {
    params: { sort: 'name_asc', is_active: true }
  })
  return res.data
})

export const getWeightedBarcodeDetails = createAsyncThunk('/weighted_barcode_config', async () => {
  const res = await axiosInstance.get('/api/v1/products/configurations/weighted_barcode/details')
  return res.data
})

export const getSkusLabels = async (search, loadedOptions, { page, sellerId }) => {
  try {
    const response = await axiosInstance.get(
      '/api/v1/products/labels',
      { params: { search_column: 'name', search_query: search, page } }
    )
    const data = response?.data?.data ?? []

    if (search) data.unshift({id: search, name: `${LABELS_ADDITIONAL_OPTIONS.ADD_LABEL.label} "${search}"`, tag: 'add_label'})

    const modifiedData = data?.map((ele) => ({
      value: ele.id,
      label: ele.name,
      tag: ele.tag
    }))

    return {
      options: modifiedData,
      hasMore: page < response?.data?.meta?.last_page,
      additional: {
        page: page + 1,
        sellerId
      }
    }
  } catch (error) {
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page,
        sellerId
      }
    }
  }
}

//catalog comparison

//1. Sales Channel For Single Seller
export const getSalesChannelForSingleSeller = createAsyncThunk(
  '/sales-channels-for-single-seller',
  async (params) => {
    const { seller_id, query } = params
    if (!query) {
      const response = await axiosInstance.get(`/api/v1/sales_channels/sellers/${seller_id}/sales_channels`)
      return { data: response?.data?.data }
    }
    if (query) {
      const response = await axiosInstance.get(`/api/v1/sales_channels/sellers/${seller_id}/sales_channels`, { params: { search_column: 'sc_name', search_query: query } })
      return { data: response?.data?.data }
    }
  }
)

//2. compare catalog
export const compareCatalogs = createAsyncThunk('/compare-catalogs', async (data) => {
  const { seller_id, body, rsync } = data
  let res
  if (rsync) {
    res = await axiosInstance.post(`/api/v1/products/sellers/${seller_id}/catalog/comparison?rsync=true`, body)
  } else {
    res = await axiosInstance.post(`/api/v1/products/sellers/${seller_id}/catalog/comparison`, body)
  }
  return res?.data
})

//3. sku_issue tables
export const getSkuIssueData = createAsyncThunk('/sku-issue-data', async (body) => {
  const seller_id = body?.sellerId
  const { params } = body
  const res = await axiosInstance.get(`/api/v1/products/sellers/${seller_id}/catalog/comparison`, { params })
  return res?.data
})


//4. msater catalog sku_count
export const getMasterCatalogTotalSkuCount = createAsyncThunk('/master-total-sku-count', async (seller_id) => {
  const res = await axiosInstance.get(`/api/v1/products/sellers/${seller_id}/skus/count?sku=true`)
  return res?.data?.data
})

export const getShowPreviousComparisonTable = createAsyncThunk('/previous-table-data', async (params) => {
  const { seller_id, seller_sales_channel_id, sku_issue } = params
  const res = await axiosInstance.get(`/api/v1/products/sellers/${seller_id}/catalog/comparison`, { params: { seller_sales_channel_id, sku_issue } })
  return res?.data
})

//5. seller's seller sales channel sku count
export const getSellerCatalogTotalSkuCount = createAsyncThunk('/seller-total-sku-count', async (params) => {
  const { seller_id, seller_sales_channel_id } = params
  const res = await axiosInstance.get(`/api/v1/products/sellers/${seller_id}/skus/count`, { params: { seller_sales_channel_id, sales_channel_sku: true } })
  return res?.data?.data
})

//6. previous comparisons
export const getPreviousComparisonsData = createAsyncThunk('/previous-comparisons-data', async ({ seller_id, page, per_page }) => {
  let res
  if (page && per_page) {
    res = await axiosInstance.get(`/api/v1/products/sellers/${seller_id}/catalog/comparison/stats`, { params: { page, per_page } })
  } else {
    res = await axiosInstance.get(`/api/v1/products/sellers/${seller_id}/catalog/comparison/stats`)
  }
  return res?.data
})

//7.download sku doesn't exist
export const getSheetDownload = createAsyncThunk('/get/issue/sheet', async (body) => {
  const { seller_id, params } = body
  const res = await axiosInstance.get(`/api/v1/products/sellers/${seller_id}/catalog/comparison/export`, { params })
  return res?.data
})

//8. Expandable row Data
export const getExpandableDataForRow = createAsyncThunk('/expandable-data-row', async ({ seller_id, sales_channel_sku_id }) => {
  const res = await axiosInstance.get(`api/v1/products/sellers/${seller_id}/sales_channel_skus`, { params: { sales_channel_sku_id } })
  return res?.data
})

//9. Sync to Master catalog
export const syncToMasterCatalog = createAsyncThunk('/sync-to-master/catalog', async ({ seller_id, seller_sales_channel_id }) => {
  const res = await axiosInstance.post(`/api/v1/products/sellers/${seller_id}/catalog/comparison/sync_to_master`, null, {params: { seller_sales_channel_id}})
  return res.data
}
)

// barcode configuration
export const getAffectedBarcodeList = createAsyncThunk('/weighted-item-barcode/affect-barcode', async (body) => {
  const {seller_id, ...params} = body
  const res = await axiosInstance.get(`/api/v1/products/sellers/${seller_id}/skus`, { params })
  return res.data
})
export const createWeightedBarcode = createAsyncThunk('/weighted-item-barcode/Create', async (body) => {
  const res = await axiosInstance.post('/api/v1/products/configurations', body)
  return res.data
})
export const getAllWeightedBarcode = createAsyncThunk('/weighted-item-barcode/GetAll', async (params) => {
  const res = await axiosInstance.get('/api/v1/products/configurations', { params })
  return res.data
})
export const updatedWeightedBarcode = createAsyncThunk('/weighted-item-barcode/Update', async ({ config_id, body }) => {
  const res = await axiosInstance.put(`/api/v1/products/configurations/${config_id}`, body)
  return res.data
})
// SKU
// sku listing details
export const getSkuListingDetails = createAsyncThunk('/sku-listing-details', async (body) => {
  const seller_id = body?.seller_id
  const { params } = body
  const res = await axiosInstance.get(`/api/v1/products/sellers/${seller_id}/sales_channel_skus?${paramsSerializer(params)}`)
  return res?.data
})


export const exportMasterSku = createAsyncThunk(
  'catalog/exportMasterSKU',
  async (params, store) => {
    const sellerId = store.getState()?.catalog?.selectedSeller?.value
    const res = await axiosInstance.get(
      `${urlPrefixProvider(sellerId)}/skus/export`,
      { params }
    )
    return res.data.data
  }
)

export const exportSalesChannelSku = createAsyncThunk(
  'catalog/exportShippingSKUs',
  async (params, store) => {
    const sellerId = store.getState()?.catalog?.selectedSeller?.value
    const res = await axiosInstance.get(
      `${urlPrefixProvider(sellerId)}/sales_channel_skus/export`,
      { params }
    )
    return res.data.data
  }
)

export const archiveSku = createAsyncThunk(
  'catalog/archive-sku',
  async ({id, type}, store) => {
    const sellerId = store.getState()?.catalog?.selectedSeller?.value
    const res = await axiosInstance.delete(
      `${urlPrefixProvider(sellerId)}/skus/${id}/archive`
    )
    return {data: res.data.data, type}
  }
)

export const canSkuArchive = createAsyncThunk(
  'catalog/sku-can-archive',
  async ({id}, store) => {
    const sellerId = store.getState()?.catalog?.selectedSeller?.value
    const res = await axiosInstance.get(
      `${urlPrefixProvider(sellerId)}/skus/${id}/can_archive_sku`
    )
    return res.data.data
  }
)

//case_pack or simple sku async
export const getCasePackOrSimpleSkusAsync = async (search, loadedOptions, { page, sellerId }) => {
  try {
    const params = {type:SIMPLE, package_type:['ea', 'case_pack'], page, query: search || undefined}
    const response = await axiosInstance.get(`${urlPrefixProvider(sellerId)}/skus?${paramsSerializer({...params})}`)
    const data = response?.data

    return {
      options: data?.data?.filter(item => !item.is_gtin_picking_enabled)?.map((ele) => ({
        value: ele.id,
        label: ele.name,
        package_type:ele.package_type,
        sku_code:ele.seller_sku_code,
        weight:ele.weight?.display_weight
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        sellerId
      }
    }
  } catch (error) {
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const unarchiveSku = createAsyncThunk(
  'catalog/sku-can-unarchive',
  async ({id, type}, store) => {
    const sellerId = store.getState()?.catalog?.selectedSeller?.value
    const res = await axiosInstance.post(
      `${urlPrefixProvider(sellerId)}/skus/${id}/unarchive`
    )
    return {data: res.data.data, type}
  }
)

const initialState = {
  loading: {},
  error: {},
  success: {},
  successResponse: {},
  catalogSkuMasterData: { loading: false, error: false, data: null, is_success: false },
  sellerSalesChannelSkus: {
    data: null,
    loading: false,
    success: false,
    error: false
  },
  sellers: [],
  selectedSeller: {},
  selectedSellerBarcode: {},
  product: {},
  sku: {},
  skuSalesChannel: {loading: false, data: null, error: false, is_success: false},
  skus: [],
  skuBulkUploadedFile: null,
  salesChannelListingData: [],
  importSkusBulkResponse: null,
  salesChannelForSingleSeller: null,
  sellerSalesChannelLoading: true,
  comparedCatalogData: null,
  issueDataLoading: false,
  comparedCatalogSucces: null,
  catalogComparisonLoading: false,
  catalogComparisonMasterSyncLoading: false,
  masterCatalogtotalSkuCountData: null,
  sellerCatalogtotalSkuCountData: null,
  sellerSkuCountLoadingState: false,
  previousComparisonsData: null,
  weightedBarcodeDetails: null,
  expandableRowData: null,
  previousToTableData: null,
  dataFromCompare: false,
  weightedBarcodeConfigDetails: null,
  affectedBarcodeList: { loading: false, is_success: false, data: null },
  allWeightedBarcode: { data: null, loading: {}, error: {}, success: {} },
  skuListingDetailsData: null,
  skuLoading: false,
  exportMasterSkus: { loading: false, is_success: false, data: null },
  exportSalesChannelSkus: { loading: false, is_success: false, data: null },
  bundles: { loading: false, error: false, data: null, is_success: false },
  bundleSkus: [],
  assembledKits: { loading: false, error: false, data: null, is_success: false },
  createBundleSkuResponse: { loading: false, is_success: false, data: null },
  bundleChildSku: {},
  updateBundleSku: { loading: false, is_success: false, data: null },
  isFirstRowHighlighted: {sku: false, kit: false, assembled: false},
  embeddedBarcodeConfigDetails: {},
  skuArchiveRes: { loading: false, is_success: false, data: null, error: false },
  canSkuArchiveRes: { loading: false, is_success: false, data: null, error: false },
  getAllArchiveSkusRes: { loading: false, error: false, data: null, is_success: false },
  getAllArchiveKitRes: { loading: false, error: false, data: null, is_success: false },
  skuUnarchiveRes: { loading: false, is_success: false, data: null, error: false },
  isCatalogAlreadyCompared: false,
  skuBulkArchiveFile: null,

}

const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    setBundleSkus: (state, action) => {
      state.bundleSkus = action.payload
    },
    updateQtyOfBundleSkuAtIndex: (state, {payload: {idx, value}}) => {
      state.bundleSkus[idx].quantity = value
    },
    removeBundleSkuFromIndex: (state, {payload: idx}) => {
      state.bundleSkus.splice(idx, 1)
    },
    setSkuBulkUploadedFile: (state, action) => {
      state.skuBulkUploadedFile = { ...action.payload }
    },
    setSkuBulkArchiveFile: (state, action) => {
      state.skuBulkArchiveFile = { ...action.payload }
    },
    clearEmbeddedBarcodeConfigDetails: (state) => {
      state.embeddedBarcodeConfigDetails = {}
    },
    setEmbeddedBarcodeConfigDetails: (state, action) => {
      state.embeddedBarcodeConfigDetails = action.payload
    },
    cleanupSkuMasterData: (state) => {
      state.catalogSkuMasterData = { loading: false, error: false, data: null, is_success: false }
    },

    cleanupSellerSalesChannelSkus: (state) => {
      state.sellerSalesChannelSkus = {
        data: null,
        loading: false,
        success: false,
        error: false
      }
    },

    cleanupSingleSku: (state) => {
      state.sku = {}
    },
    cleanupBundleChildSku: (state) => {
      state.bundleChildSku = {}
    },
    selectedSellerDispatch: (state, action) => {
      state.selectedSeller = action.payload
    },
    selectedSellerDispatchBarcode: (state, action) => {
      state.selectedSellerBarcode = action.payload
    },
    clearSelectedSellerBarcode: (state) => {
      state.selectedSellerBarcode = {}
    },
    cleanupSalesChannelListingData: (state) => {
      state.salesChannelListingData = {}
    },
    cleanupImportSkusBulkResponse: (state) => {
      state.importSkusBulkResponse = null
    },
    cleanupSellerCatalogtotalSkuCountData: (state) => {
      state.sellerCatalogtotalSkuCountData = null
    },
    cleanupComparedCatalogSuccess: (state) => {
      state.comparedCatalogSucces = null
    },
    cleanupComparedCatalogData: (state) => {
      state.comparedCatalogData = null
    },
    cleanupExpandableRowData: (state) => {
      state.expandableRowData = null
    },
    cleanupPreviousToTableData: (state) => {
      state.previousToTableData = null
    },
    cleanUpDataFromCompareData: (state, action) => {
      state.dataFromCompare = action.payload
    },
    setBarcodeConfigDetails: (state, action) => {
      state.weightedBarcodeConfigDetails = action.payload
    },
    clearAffectedBarcodeList: (state) => {
      state.affectedBarcodeList = { loading: false, is_success: false, data: null }
      state.successResponse = {}
    },
    cleanupComparisonLoading: (state) => {
      state.catalogComparisonLoading = false
    },
    cleanupCatalogComparisonMasterSyncLoading: (state) => {
      state.catalogComparisonMasterSyncLoading = false
    },
    cleanupGetBundleSkus: (state) => {
      state.bundles = { loading: false, error: false, data: null, is_success: false }
    },
    cleanupGetAssembledKits: (state) => {
      state.assembledKits = { loading: false, error: false, data: null, is_success: false }
    },
    cleanupCreateBundleSkuResponse: (state) => {
      state.createBundleSkuResponse = { loading: false, is_success: false, data: null }
    },
    cleanupUpdateBundleSku: (state) => {
      state.updateBundleSku = {loading: false, is_success: false, data: null}
    },
    cleanupSetSkuBulkUploadedFile: (state) => {
      state.skuBulkUploadedFile = null
    },
    handleIsRowHighlighted: (state, { payload }) => {
      state.isFirstRowHighlighted = {...state.isFirstRowHighlighted, ...payload}
    },
    cleanupArchiveRes: (state) => {
      state.skuArchiveRes = { loading: false, is_success: false, data: null, error: false }
    },
    cleanupCanSkuArchive: (state) => {
      state.canSkuArchiveRes = {loading: false, data: null, is_success: false, error: false}
    },
    cleanupUnarchiveRes: (state) => {
      state.skuUnarchiveRes = { loading: false, is_success: false, data: null, error: false }
    },
    cleanupIsCatalogAlreadyCompared: (state) => {
      state.isCatalogAlreadyCompared = false
    },
    cleanUpSellerResponse : (state) => {
      state.sellers = []
    },
    cleanupSuccess: (state) => {
      state.success = {}
    },
   

  },

  extraReducers: (builder) => {
    builder
      .addCase(getSkuMaster.pending, (state) => {
        state.catalogSkuMasterData = { data: null, loading: true, error: false, is_success: false }
      })
      .addCase(getSkuMaster.rejected, (state) => {
        state.catalogSkuMasterData = { data: null, error: true, loading: false, is_success: false }
      })
      .addCase(getSkuMaster.fulfilled, (state, action) => {
        state.catalogSkuMasterData = { error: false, loading: false, is_success: true, data: action.payload.data}
      })

    builder
      .addCase(getBundleSkus.pending, (state) => {
        state.bundles = {loading: true, is_success: false, error: false, data: null }
      })
      .addCase(getBundleSkus.rejected, (state) => {
        state.bundles = { error: true, loading: false, is_success: false, data: null }
      })
      .addCase(getBundleSkus.fulfilled, (state, action) => {
        state.bundles = { is_success: true, loading: false, error: false, data: action.payload.data}
      })

    builder
      .addCase(getAssembledSkus.pending, (state) => {
        state.assembledKits = {loading: true, is_success: false, error: false, data: null }
      })
      .addCase(getAssembledSkus.rejected, (state) => {
        state.assembledKits = { error: true, loading: false, is_success: false, data: null }
      })
      .addCase(getAssembledSkus.fulfilled, (state, action) => {
        state.assembledKits = { is_success: true, loading: false, error: false, data: action.payload.data}
      })
      
    builder
      .addCase(getSkus.pending, (state) => {
        state.skus = { loading: true }
      })
      .addCase(getSkus.rejected, (state) => {
        state.skus = { loading: false, error: true }
      })
      .addCase(getSkus.fulfilled, (state, action) => {
        state.skus = action.payload.data
      })

    builder
      .addCase(getSku.pending, (state) => {
        state.loading = { ...state.loading, getSku: true }
        state.error = { ...state.error, getSku: false }
        state.skuLoading = true
      })
      .addCase(getSku.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getSku: false }
        state.error = { ...state.error, getSku: false }
        state.success = { ...state.success, getSku: true }
        state.sku = action.payload.data
        state.skuLoading = false
      })
      .addCase(getSku.rejected, (state) => {
        state.loading = { ...state.loading, getSku: false }
        state.error = { ...state.error, getSku: true }
        state.skuLoading = false
      })

    builder
      .addCase(updateBundleSkus.pending, (state) => {
        state.updateBundleSku = { loading: true, is_success: false, error: false}
      })
      .addCase(updateBundleSkus.fulfilled, (state, action) => {
        state.updateBundleSku = { ...action.payload.data, loading: false, is_success: true, error: false }
        if (action.payload.type) {
          CustomToast(`The ${action.payload.type} details were edited successfully.`, { my_type: 'success' })
        }
      })
      .addCase(updateBundleSkus.rejected, (state) => {
        state.updateBundleSku = { loading: false, is_success: false, error: true}
      })

    builder
      .addCase(getBundleChildSku.pending, (state) => {
        state.loading = { ...state.loading, bundleChildSku: true }
        state.error = { ...state.error, bundleChildSku: false }
        state.skuLoading = true
      })
      .addCase(getBundleChildSku.fulfilled, (state, action) => {
        state.loading = { ...state.loading, bundleChildSku: false }
        state.error = { ...state.error, bundleChildSku: false }
        state.success = { ...state.success, bundleChildSku: true }
        state.bundleChildSku = action.payload.data
        state.skuLoading = false
      })
      .addCase(getBundleChildSku.rejected, (state) => {
        state.loading = { ...state.loading, bundleChildSku: false }
        state.error = { ...state.error, bundleChildSku: true }
        state.skuLoading = false
      })

      .addCase(getSkuSalesChannel.pending, (state) => {
        state.skuSalesChannel = {loading: true, error: false, is_success: false, data: null}
      })
      .addCase(getSkuSalesChannel.fulfilled, (state, action) => {
        state.skuSalesChannel = {loading: false, error: false, is_success: true, data: action.payload.data}
      })
      .addCase(getSkuSalesChannel.rejected, (state) => {
        state.skuSalesChannel = {loading: false, error: true, is_success: false, data: null}
      })
    builder
      .addCase(getSellerSalesChannelSkus.pending, (state) => {
        state.sellerSalesChannelSkus = {loading: true, error: false, success: false, data: null}
      })
      .addCase(getSellerSalesChannelSkus.fulfilled, (state, action) => {
        state.sellerSalesChannelSkus = {data: action.payload, loading: false, error: false, success: true}
      })
      .addCase(getSellerSalesChannelSkus.rejected, (state) => {
        state.sellerSalesChannelSkus = {loading: false, error: true, success: false, data: null}
      })

    builder
      .addCase(getSellers.fulfilled, (state, action) => {
        state.sellers = action.payload
        state.loading = { ...state.loading, getSeller: false }
      })
      .addCase(getSellers.pending, (state) => {
        state.loading = { ...state.loading, getSeller: true }
      })
      .addCase(getSellers.rejected, (state) => {
        state.loading = { ...state.loading, getSeller: false }
      })

    builder.addCase(getProduct.fulfilled, (state, action) => {
      state.product = action.payload
    })
    builder.addCase(getSalesChannelListingData.fulfilled, (state, action) => {
      state.salesChannelListingData = action.payload.data
    })

    builder
      .addCase(createBundleSku.pending, (state) => {
        state.createBundleSkuResponse = { loading: true, data: null }
      })
      .addCase(createBundleSku.rejected, (state) => {
        state.createBundleSkuResponse = { loading: false, error: {message: 'Something went wrong. Please try again'} }
      })
      .addCase(createBundleSku.fulfilled, (state, action) => {
        state.createBundleSkuResponse = {...action.payload}
      })

    builder
      .addCase(importSkusBulk.pending, (state) => {
        state.importSkusBulkResponse = {loading: true}
      })
      .addCase(importSkusBulk.fulfilled, (state, action) => {
        state.importSkusBulkResponse = {loading: false, ...action.payload}
        CustomToast('Uploading SKUs...', { my_type: 'info', duration: 1000 })
        const eventName = action.payload.data.event
        if (eventName) {
          bindToChannelEvent(eventName)
        }
      })
      .addCase(importSkusBulk.rejected, (state) => {
        state.importSkusBulkResponse = {loading: false}
      })

    builder
      .addCase(getSalesChannelForSingleSeller.pending, (state) => {
        state.loading = { ...state.loading, getSalesChannelForSingleSeller: true }
        state.error = { ...state.error, getSalesChannelForSingleSeller: false }
        state.sellerSalesChannelLoading = true
      })
      .addCase(getSalesChannelForSingleSeller.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getSalesChannelForSingleSeller: false }
        state.error = { ...state.error, getSalesChannelForSingleSeller: false }
        state.salesChannelForSingleSeller = action.payload.data
        state.sellerSalesChannelLoading = false
      })
      .addCase(getSalesChannelForSingleSeller.rejected, (state) => {
        state.error = { ...state.error, getSalesChannelForSingleSeller: true }
        state.loading = { ...state.loading, getSalesChannelForSingleSeller: false }
        state.sellerSalesChannelLoading = false
      })

    builder
      .addCase(exportMasterSku.pending, (state) => {
        state.exportMasterSkus = {...state.exportCatalogSkus, loading: true, error: false}
      })
      .addCase(exportMasterSku.fulfilled, (state, action) => {
        state.exportMasterSkus = { ...action.payload, loading: false, is_success: true, error: false}
        const eventName = action.payload.event
        if (eventName) {
          bindToChannelEvent(eventName)
        }
      })
      .addCase(exportMasterSku.rejected, (state) => {
        state.exportMasterSkus = {...state.exportCatalogSkus, loading: false, is_success: false, error: true}
      })

    builder
      .addCase(exportSalesChannelSku.pending, (state) => {
        state.exportSalesChannelSkus = {...state.exportCatalogSkus, loading: true, error: false}
      })
      .addCase(exportSalesChannelSku.fulfilled, (state, action) => {
        state.exportSalesChannelSkus = { ...action.payload, loading: false, is_success: true, error: false }
        const eventName = action.payload.event
        if (eventName) {
          bindToChannelEvent(eventName)
        }
      })
      .addCase(exportSalesChannelSku.rejected, (state) => {
        state.exportSalesChannelSkus = {...state.exportCatalogSkus, loading: false, is_success: false, error: true}
      })
    
    builder
      .addCase(compareCatalogs.pending, (state) => {
        state.loading = { ...state.loading, compareCatalogs: true }
        state.error = { ...state.error, compareCatalogs: false }
        state.success = { ...state.error, compareCatalogs: false }
        state.catalogComparisonLoading = true
      })
      .addCase(compareCatalogs.fulfilled, (state, action) => {
        state.loading = { ...state.loading, compareCatalogs: false }
        state.error = { ...state.error, compareCatalogs: false }
        state.success = { ...state.error, compareCatalogs: true }
        state.comparedCatalogSucces = action.payload.data.already_compared
        state.isCatalogAlreadyCompared = action.payload.data.already_compared
        state.comparedCatalogData = action.payload
        const eventName = action.payload.data.event
        if (eventName) {
          bindToChannelEvent(eventName)
        }
      })
      .addCase(compareCatalogs.rejected, (state) => {
        state.loading = { ...state.loading, compareCatalogs: false }
        state.error = { ...state.loading, compareCatalogs: true }
        state.success = { ...state.error, compareCatalogs: false }
        state.catalogComparisonLoading = false
      })

    builder
      .addCase(getSkuIssueData.pending, (state) => {
        state.loading = { ...state.loading, getSkuIssueData: true }
        state.error = { ...state.error, getSkuIssueData: false }
        state.success = { ...state.success, getSkuIssueData: false }
        state.issueDataLoading = true
      })
      .addCase(getSkuIssueData.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getSkuIssueData: false }
        state.comparedCatalogData = action.payload
        state.success = { ...state.success, getSkuIssueData: true }
        state.error = { ...state.error, getSkuIssueData: false }
        state.issueDataLoading = false
      })
      .addCase(getSkuIssueData.rejected, (state) => {
        state.loading = { ...state.loading, getSkuIssueData: false }
        state.error = { ...state.error, getSkuIssueData: true }
        state.success = { ...state.success, getSkuIssueData: false }
        state.issueDataLoading = false
        state.comparedCatalogData = null
      })

    builder
      .addCase(getMasterCatalogTotalSkuCount.pending, (state) => {
        state.loading = { ...state.loading, getMasterCatalogTotalSkuCount: true }
        state.error = { ...state.error, getMasterCatalogTotalSkuCount: false }
      })
      .addCase(getMasterCatalogTotalSkuCount.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getMasterCatalogTotalSkuCount: false }
        state.error = { ...state.error, getMasterCatalogTotalSkuCount: false }
        state.success = { ...state.success, getMasterCatalogTotalSkuCount: true }
        state.masterCatalogtotalSkuCountData = action.payload
      })
      .addCase(getMasterCatalogTotalSkuCount.rejected, (state) => {
        state.loading = { ...state.loading, getMasterCatalogTotalSkuCount: false }
        state.error = { ...state.error, getMasterCatalogTotalSkuCount: true }
      })

    builder
      .addCase(getSellerCatalogTotalSkuCount.pending, (state) => {
        state.loading = { ...state.loading, getSellerCatalogTotalSkuCount: true }
        state.error = { ...state.error, getSellerCatalogTotalSkuCount: false }
        state.sellerSkuCountLoadingState = true
      })
      .addCase(getSellerCatalogTotalSkuCount.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getSellerCatalogTotalSkuCount: false }
        state.error = { ...state.error, getSellerCatalogTotalSkuCount: false }
        state.success = { ...state.success, getSellerCatalogTotalSkuCount: true }
        state.sellerCatalogtotalSkuCountData = action.payload
        state.sellerSkuCountLoadingState = false
      })
      .addCase(getSellerCatalogTotalSkuCount.rejected, (state) => {
        state.loading = { ...state.loading, getSellerCatalogTotalSkuCount: false }
        state.error = { ...state.error, getSellerCatalogTotalSkuCount: true }
        state.sellerSkuCountLoadingState = false
      })

    builder
      .addCase(getPreviousComparisonsData.pending, (state) => {
        state.loading = { ...state.loading, getPreviousComparisonsData: true }
        state.error = { ...state.error, getPreviousComparisonsData: false }
      })
      .addCase(getPreviousComparisonsData.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getPreviousComparisonsData: false }
        state.error = { ...state.error, getPreviousComparisonsData: false }
        state.success = { ...state.success, getPreviousComparisonsData: true }
        state.previousComparisonsData = action.payload
      })
      .addCase(getPreviousComparisonsData.rejected, (state) => {
        state.loading = { ...state.loading, getPreviousComparisonsData: false }
        state.error = { ...state.error, getPreviousComparisonsData: true }
      })

    builder
      .addCase(getExpandableDataForRow.pending, (state) => {
        state.loading = { ...state.loading, getExpandableDataForRow: true }
        state.error = { ...state.error, getExpandableDataForRow: false }
      })
      .addCase(getExpandableDataForRow.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getExpandableDataForRow: false }
        state.error = { ...state.error, getExpandableDataForRow: false }
        state.success = { ...state.success, getExpandableDataForRow: true }
        state.expandableRowData = action.payload
      })
      .addCase(getExpandableDataForRow.rejected, (state) => {
        state.loading = { ...state.loading, getExpandableDataForRow: false }
        state.error = { ...state.error, getExpandableDataForRow: true }
      })

    builder
      .addCase(getShowPreviousComparisonTable.pending, (state) => {
        state.loading = { ...state.loading, getShowPreviousComparisonTable: true }
        state.error = { ...state.error, getShowPreviousComparisonTable: false }
      })
      .addCase(getShowPreviousComparisonTable.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getShowPreviousComparisonTable: false }
        state.error = { ...state.error, getShowPreviousComparisonTable: false }
        state.success = { ...state.error, getShowPreviousComparisonTable: true }
        state.comparedCatalogData = action.payload
        state.dataFromCompare = true
        state.previousToTableData = true
      })
      .addCase(getShowPreviousComparisonTable.rejected, (state) => {
        state.loading = { ...state.loading, getShowPreviousComparisonTable: false }
        state.error = { ...state.error, getShowPreviousComparisonTable: true }
      })

    builder.addCase(getWeightedBarcodeDetails.fulfilled, (state, action) => {
      state.weightedBarcodeDetails = action.payload.data
    })

    builder
      .addCase(getAffectedBarcodeList.pending, (state) => {
        state.affectedBarcodeList = { loading: true, success: false, data: null, error: false }
      })
      .addCase(getAffectedBarcodeList.fulfilled, (state, action) => {
        state.affectedBarcodeList = { loading: false, success: true, data: action.payload, error: false }
      })
      .addCase(getAffectedBarcodeList.rejected, (state) => {
        state.affectedBarcodeList = { loading: false, success: false, data: null, error: true }
      })

    builder
      .addCase(createWeightedBarcode.pending, (state) => {
        state.loading = { ...state.loading, CreateWeightedBarcode: true }
        state.error = { ...state.error, CreateWeightedBarcode: false }
      })
      .addCase(createWeightedBarcode.fulfilled, (state, action) => {
        state.loading = { ...state.loading, CreateWeightedBarcode: false }
        state.error = { ...state.error, CreateWeightedBarcode: false }
        state.success = { ...state.success, CreateWeightedBarcode: true }
        state.successResponse = { ...state.successResponse, CreateWeightedBarcode: action.payload.data }
      })
      .addCase(createWeightedBarcode.rejected, (state) => {
        state.loading = { ...state.loading, CreateWeightedBarcode: false }
        state.error = { ...state.error, CreateWeightedBarcode: true }
      })

    builder
      .addCase(getAllWeightedBarcode.pending, (state) => {
        state.allWeightedBarcode.loading = { ...state.allWeightedBarcode.loading, getAllWeightedBarcode: true }
        state.allWeightedBarcode.error = { ...state.allWeightedBarcode.error, getAllWeightedBarcode: false }
        state.allWeightedBarcode.success = { ...state.allWeightedBarcode.success, getAllWeightedBarcode: false }
      })
      .addCase(getAllWeightedBarcode.fulfilled, (state, action) => {
        state.allWeightedBarcode.loading = { ...state.allWeightedBarcode.loading, getAllWeightedBarcode: false }
        state.allWeightedBarcode.error = { ...state.allWeightedBarcode.error, getAllWeightedBarcode: false }
        state.allWeightedBarcode.success = { ...state.allWeightedBarcode.success, getAllWeightedBarcode: true }
        state.allWeightedBarcode.data = action.payload
      })
      .addCase(getAllWeightedBarcode.rejected, (state) => {
        state.allWeightedBarcode.loading = { ...state.allWeightedBarcode.loading, getAllWeightedBarcode: false }
        state.allWeightedBarcode.error = { ...state.allWeightedBarcode.error, getAllWeightedBarcode: true }
        state.allWeightedBarcode.success = { ...state.allWeightedBarcode.success, getAllWeightedBarcode: false }
      })

    builder
      .addCase(updatedWeightedBarcode.pending, (state) => {
        state.loading = { ...state.loading, updatedWeightedBarcode: true }
        state.error = { ...state.error, updatedWeightedBarcode: false }
      })
      .addCase(updatedWeightedBarcode.fulfilled, (state, action) => {
        state.loading = { ...state.loading, updatedWeightedBarcode: false }
        state.error = { ...state.error, updatedWeightedBarcode: false }
        state.success = { ...state.success, updatedWeightedBarcode: true }
        state.successResponse = { ...state.successResponse, updatedWeightedBarcode: action.payload.data }
      })
      .addCase(updatedWeightedBarcode.rejected, (state) => {
        state.loading = { ...state.loading, updatedWeightedBarcode: false }
        state.error = { ...state.error, updatedWeightedBarcode: true }
      })

    builder
      .addCase(getSkuListingDetails.pending, (state) => {
        state.loading = { ...state.loading, getSkuListingDetails: true }
        state.error = { ...state.error, getSkuListingDetails: false }
        state.success = { ...state.success, getSkuListingDetails: false }
      })
      .addCase(getSkuListingDetails.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getSkuListingDetails: false }
        state.error = { ...state.error, getSkuListingDetails: false }
        state.success = { ...state.success, getSkuListingDetails: true }
        state.skuListingDetailsData = action.payload
      })
      .addCase(getSkuListingDetails.rejected, (state) => {
        state.loading = { ...state.loading, getSkuListingDetails: false }
        state.error = { ...state.error, getSkuListingDetails: true }
        state.success = { ...state.success, getSkuListingDetails: false }
      })

    builder
      .addCase(getSheetDownload.pending, (state) => {
        state.loading = { ...state.loading, getSheetDownload: true }
        state.error = { ...state.error, getSheetDownload: false }
      })
      .addCase(getSheetDownload.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getSheetDownload: false }
        state.error = { ...state.error, getSheetDownload: false }
        state.success = { ...state.success, getSheetDownload: true }
        const eventName = action.payload.data.event
        if (eventName) {
          bindToChannelEvent(eventName)
        }
      })
      .addCase(getSheetDownload.rejected, (state) => {
        state.loading = { ...state.loading, getSheetDownload: false }
        state.error = { ...state.error, getSheetDownload: true }
      })

    builder
      .addCase(syncToMasterCatalog.pending, (state) => {
        state.loading = { ...state.loading, syncToMasterCatalog: true }
        state.error = { ...state.error, syncToMasterCatalog: false }
        state.catalogComparisonMasterSyncLoading = true
      })
      .addCase(syncToMasterCatalog.fulfilled, (state, action) => {
        state.loading = { ...state.loading, syncToMasterCatalog: false }
        state.error = { ...state.error, syncToMasterCatalog: false }
        state.success = { ...state.success, syncToMasterCatalog: true }
        const eventName = action.payload.data.event
        if (eventName) {
          bindToChannelEvent(eventName)
        }
      })
      .addCase(syncToMasterCatalog.rejected, (state) => {
        state.loading = { ...state.loading, syncToMasterCatalog: false }
        state.error = { ...state.error, syncToMasterCatalog: true }
      })
    builder
      .addCase(archiveSku.pending, (state) => {
        state.skuArchiveRes = {loading: true, data: null, is_success: false, error: false}
      })
      .addCase(archiveSku.fulfilled, (state, action) => {
        state.skuArchiveRes = { loading: false, data: action.payload.data?.data, is_success: true, error: false }
        CustomToast(`${action.payload.type} archived Successfully`, { my_type: 'success' })
      })
      .addCase(archiveSku.rejected, (state) => {
        state.skuArchiveRes = {loading: false, data: null, is_success: false, error: true}
      })
    builder
      .addCase(unarchiveSku.pending, (state) => {
        state.skuUnarchiveRes = {loading: true, data: null, is_success: false, error: false}
      })
      .addCase(unarchiveSku.fulfilled, (state, action) => {
        state.skuUnarchiveRes = { loading: false, data: action.payload.data?.data, is_success: true, error: false }
        CustomToast(`${action.payload.type} unarchived Successfully`, { my_type: 'success' })
      })
      .addCase(unarchiveSku.rejected, (state) => {
        state.skuUnarchiveRes = {loading: false, data: null, is_success: false, error: true}
      })
    builder
      .addCase(canSkuArchive.pending, (state) => {
        state.canSkuArchiveRes = {loading: true, data: null, is_success: false, error: false}
      })
      .addCase(canSkuArchive.fulfilled, (state, action) => {
        state.canSkuArchiveRes = {loading: false, data: action.payload, is_success: true, error: false}
      })
      .addCase(canSkuArchive.rejected, (state) => {
        state.canSkuArchiveRes = {loading: false, data: null, is_success: false, error: true}
      })
    builder
      .addCase(getAllArchiveSkus.pending, (state) => {
        state.getAllArchiveSkusRes = { data: null, loading: true, error: false, is_success: false }
      })
      .addCase(getAllArchiveSkus.rejected, (state) => {
        state.getAllArchiveSkusRes = { data: null, error: true, loading: false, is_success: false }
      })
      .addCase(getAllArchiveSkus.fulfilled, (state, action) => {
        state.getAllArchiveSkusRes = { error: false, loading: false, is_success: true, data: action.payload.data}
      })
      .addCase(getAllArchiveKit.pending, (state) => {
        state.getAllArchiveKitRes = { data: null, loading: true, error: false, is_success: false }
      })
      .addCase(getAllArchiveKit.rejected, (state) => {
        state.getAllArchiveKitRes = { data: null, error: true, loading: false, is_success: false }
      })
      .addCase(getAllArchiveKit.fulfilled, (state, action) => {
        state.getAllArchiveKitRes = { error: false, loading: false, is_success: true, data: action.payload.data}
      })      
  }
})

export const {
  selectedSellerDispatch,
  selectedSellerDispatchBarcode,
  cleanupSkuMasterData,
  cleanupSellerSalesChannelSkus,
  cleanupSingleSku,
  cleanupSalesChannelListingData,
  setSkuBulkUploadedFile,
  cleanupImportSkusBulkResponse,
  cleanupSellerCatalogtotalSkuCountData,
  cleanupComparedCatalogSuccess,
  cleanupComparedCatalogData,
  cleanupExpandableRowData,
  cleanupPreviousToTableData,
  cleanUpDataFromCompareData,
  setBarcodeConfigDetails,
  clearAffectedBarcodeList,
  clearSelectedSellerBarcode,
  cleanupComparisonLoading,
  cleanupCatalogComparisonMasterSyncLoading,
  setBundleSkus,
  updateQtyOfBundleSkuAtIndex,
  removeBundleSkuFromIndex,
  cleanupGetBundleSkus,
  cleanupGetAssembledKits,
  cleanupCreateBundleSkuResponse,
  cleanupUpdateBundleSku,
  cleanupSetSkuBulkUploadedFile,
  cleanupBundleChildSku,
  handleIsRowHighlighted,
  clearEmbeddedBarcodeConfigDetails,
  setEmbeddedBarcodeConfigDetails,
  cleanupArchiveRes,
  cleanupCanSkuArchive,
  cleanupUnarchiveRes,
  cleanupIsCatalogAlreadyCompared,
  cleanUpSellerResponse,
  cleanupSuccess,
  setSkuBulkArchiveFile,
 

} = catalogSlice.actions

export default catalogSlice.reducer