import React, { useState } from 'react';
import { ChevronDown } from 'react-feather';
import { useTranslation } from 'react-i18next';
import GlobalSearchIcon from '../../../assets/images/icons/globalSearch.jsx';
import NestIcon from '../../../assets/images/icons/Nest';
import Highlight from '../utils/highlight';

type ActionItem = {
  item: {
    path: string;
    name: string;
    description?: string;
  };
  path: string;
};

type SearchItem = {
  path: string;
  title: string;
  description?: string;
  actions?: ActionItem[];
};

interface SearchResultsProps {
  results: Record<string, SearchItem[]>;
  selectedIndex: number;
  isNavigatingWithKeyboard: boolean;
  query: string;
  // eslint-disable-next-line no-unused-vars
  getGroupIndex: (category: string, item: unknown) => number;
  // eslint-disable-next-line no-unused-vars
  handleNavigate: (path: string) => void;
  selectedItemRef: React.RefObject<HTMLButtonElement>;
}

const MAX_ACTIONS_TO_SHOW = 3;

export const SearchResults: React.FC<SearchResultsProps> = ({
  results,
  selectedIndex,
  isNavigatingWithKeyboard,
  query,
  getGroupIndex,
  handleNavigate,
  selectedItemRef
}) => {
  // Track expanded state for each item
  const [expandedItems, setExpandedItems] = useState<Record<string, boolean>>({});

  const toggleExpand = (itemPath: string) => {
    setExpandedItems(prev => ({
      ...prev,
      [itemPath]: !prev[itemPath]
    }));
  };

  const { t } = useTranslation();

  return (
    <div>
      <div className="tw-px-4 tw-py-1 tw-text-h3 tw-font-semibold tw-text-dark tw-text-start">
        {t('Search Results')}
      </div>
      {Object.entries(results).map(([category, items]) => (
        <div key={category} className="tw-last:mb-0">
          <div className="tw-text-start">
            {items.map((item) => {
              const typedItem = item as SearchItem;
              
              const mainItemIndex = getGroupIndex(category, { 
                type: 'main', 
                path: typedItem.path, 
                title: typedItem.title, 
                description: typedItem.description, 
                actions: typedItem.actions 
              });
              const isMainSelected = selectedIndex === mainItemIndex;
              const isExpanded = expandedItems[typedItem.path];
              const hasMoreActions = typedItem.actions && typedItem.actions.length > MAX_ACTIONS_TO_SHOW;
              const visibleActions = isExpanded ? typedItem.actions : typedItem.actions?.slice(0, MAX_ACTIONS_TO_SHOW);
              
              return (
                <div key={typedItem.path} className="tw-flex tw-flex-col tw-gap-1">
                  <button
                    ref={isMainSelected ? selectedItemRef : null}
                    onClick={() => handleNavigate(typedItem.path)}
                    className={`tw-flex tw-w-full tw-items-center tw-rounded-md tw-gap-4 tw-px-[12px] tw-py-[8px] hover:tw-bg-primary-lighter tw-transition-colors tw-duration-150 tw-border-0 tw-bg-transparent ${
                      isNavigatingWithKeyboard && isMainSelected ? 'tw-bg-gray-50 !tw-ring-1 !tw-ring-primary !tw-ring-offset-0' : ''
                    }`}
                  >
                    <GlobalSearchIcon className="tw-h-6 tw-w-6 tw-text-primary" />
                    <div className="tw-flex-1 tw-text-start">
                      <div className="tw-font-regular tw-text-body tw-text-dark tw-text-start">
                        <Highlight text={typedItem.title} query={query} />
                      </div>
                      {typedItem.description && (
                        <div className="tw-font-regular tw-text-asst tw-text-dark-5 tw-text-start">
                          <Highlight text={typedItem.description} query={query} />
                        </div>
                      )}
                    </div>
                  </button>

                  {/* Action Items */}
                  {visibleActions && visibleActions.length > 0 && (
                    <div className="tw-flex tw-flex-col tw-gap-1">
                      {visibleActions.map((action: ActionItem) => {
                        const actionItemIndex = getGroupIndex(category, { type: 'action', ...action.item });
                        const isActionSelected = selectedIndex === actionItemIndex;
                        
                        return (
                          <button
                            key={action.item.path}
                            ref={isActionSelected ? selectedItemRef : null}
                            onClick={() => handleNavigate(action.item.path)}
                            className={`tw-flex tw-w-full tw-rounded-md tw-items-start tw-gap-4 tw-pl-[32px] tw-pr-[12px] tw-py-[8px] hover:tw-bg-primary-lighter tw-transition-colors tw-duration-150 tw-border-0 tw-bg-transparent ${
                              isNavigatingWithKeyboard && isActionSelected ? 'tw-bg-gray-50 !tw-ring-1 !tw-ring-primary !tw-ring-offset-0' : ''
                            }`}
                          >
                            <div className="tw-flex tw-items-center tw-justify-center tw-h-[42px] tw-rounded-lg tw-shrink-0">
                              <NestIcon className="tw-h-6 tw-w-6 tw-text-primary" />
                            </div>
                            <div className="tw-flex-1">
                              <div className="tw-font-regular tw-text-body tw-text-dark tw-text-start">
                                <Highlight text={action.item.name} query={query} />
                              </div>
                              {action.item.description && (
                                <div className="tw-font-regular tw-text-asst tw-text-dark-5 tw-text-start">
                                  <Highlight text={action.item.description} query={query} />
                                </div>
                              )}
                            </div>
                          </button>
                        );
                      })}
                      
                      {/* Show more button */}
                      {hasMoreActions && (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleExpand(typedItem.path);
                          }}
                          className="tw-flex tw-w-fit tw-items-center tw-ml-[32px] tw-my-[8px] tw-mr-[12px] tw-pr-[8px] tw-py-[2px] tw-gap-1 tw-text-body tw-text-dark-6 hover:tw-text-gray-700 tw-transition-colors tw-ps-3 tw-bg-light-4 tw-border-0 tw-rounded-8"
                        >
                          <ChevronDown 
                            className={`tw-h-4 tw-w-4 tw-transition-transform tw-duration-200 ${
                              isExpanded ? 'tw-rotate-180' : ''
                            } rtl:tw-scale-x-[-1]`} 
                          />
                          <span className="tw-text-asst">
                            {isExpanded ? 'Show less' : `Show ${typedItem.actions!.length - MAX_ACTIONS_TO_SHOW} more actions`}
                          </span>
                        </button>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
}; 