import classNames from 'classnames';
import { FC, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';
import { iDetail } from '../types';

interface iPreviousDetailsProps {
  details: iDetail[]
}

/**
 * Collapsible component showing previous values of changed fields
 * Provides expand/collapse functionality for viewing historical data
 * @component
 */
const PreviousDetails: FC<iPreviousDetailsProps> = ({ details }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='border border-dark-1 bg-light-1 rounded-8px'>
      <div
        className={classNames('text-dark flex-center-between cursor-pointer txt-sub-md p-10px', {
          'border-bottom border-dark-1': isOpen,
        })}
        onClick={toggleCollapse}
      >
        <div className='text-dark-6'>{t('Previous Details')}</div>
        <div className='flex-center-center gap-4px'>
          <span className='text-primary'>{isOpen ? 'View Less' : 'View More'}</span>
          <span>{isOpen ? <ChevronUp color='var(--bs-primary)' size={20} /> : <ChevronDown color='var(--bs-primary)' size={20} />}</span>
        </div>
      </div>
      <Collapse isOpen={isOpen}>
        <div className='d-flex flex-column p-10px'>
          <div>
            {details.map((detail, index) => (
              <div key={index} className='previous-detail-item'>
                <div className='d-flex flex-column gap-4px'>
                  <div className='text-dark-5 txt-sub-rg'>{t(detail.label)}</div>
                  <div className='text-dark-6'>
                    <span className='txt-sub-md'>{detail.previousValue || '-'}</span>
                  </div>
                </div>
                {index < details.length - 1 && <hr className='my-12px bg-dark-1' />}
              </div>
            ))}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default PreviousDetails;
