import { getDynamicPathParams } from '@src/utility/Utils';
import React, { useEffect, useRef, useState } from 'react';
import { Command, X } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import GlobalSearchIcon from '../../assets/images/icons/globalSearch.jsx';
import '../../styles/tailwind.css';
import { SearchHistory } from './components/SearchHistory';
import { SearchResults } from './components/SearchResults';
import { useKeyboardNavigation } from './hooks/useKeyboardNavigation';
import { useSearch } from './hooks/useSearch';
import { useSearchHistory } from './hooks/useSearchHistory';

const GlobalSearch: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  const navigate = useNavigate();
  const searchRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const selectedItemRef = useRef<HTMLButtonElement>(null);
  const resultsContainerRef = useRef<HTMLDivElement>(null);
  const {
    query,
    results,
    flattenedResults,
    selectedIndex,
    isNavigatingWithKeyboard,
    setQuery,
    setSelectedIndex,
    setIsNavigatingWithKeyboard,
    handleSearch,
    getGroupIndex,
    setResults
  } = useSearch();

  const {
    searchHistory,
    addToHistory,
    removeFromHistory,
    clearHistory
  } = useSearchHistory();

  // Handle Dynamic Paths
  const handleDynamicPaths = (path: string) => {
    const dynamicParams = getDynamicPathParams();
    return Object.entries(dynamicParams).reduce((processedPath, [param, value]) => 
      value ? processedPath.replace(new RegExp(param, 'g'), value) : processedPath
    , path);
  };

  // Handle navigation
  const handleNavigate = (path: string) => {
    addToHistory(query);
    const processedPath = handleDynamicPaths(path);
    if (processedPath.startsWith('/tms/')) {
      window.location.href = processedPath;
    } else {
      navigate(processedPath);
    }
    
    setIsOpen(false);
    setResults({});
    setQuery('');
  };

  const handleHistoryClick = (historyItem: string) => {
    handleSearch(historyItem);
  };

  const handleRemoveHistory = (e: React.MouseEvent, historyItem: string) => {
    e.stopPropagation();
    removeFromHistory(historyItem);
  };

  const { handleKeyDown } = useKeyboardNavigation({
    isOpen,
    setIsOpen,
    query,
    setQuery,
    setResults: () => ({}),
    inputRef,
    flattenedResults,
    selectedIndex,
    setSelectedIndex,
    setIsNavigatingWithKeyboard,
    handleNavigate
  });

  // Handle click outside to close search
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const clearButton = document.querySelector('button.clear-search-button');
      const elementsToCheck = [
        searchRef.current,
        inputRef.current,
        clearButton
      ];
      if (
        event.target && 
        !elementsToCheck.some(el => el?.contains(event.target as Node))
      ) {
        setIsOpen(false);
        setResults({});
        setQuery('');
      }
    };
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen]);

  // For the scroll into view effect
  useEffect(() => {
    const elements = {
      container: resultsContainerRef.current,
      item: selectedItemRef.current
    };
    if (elements.container && elements.item) {
      const rects = {
        container: elements.container.getBoundingClientRect(),
        item: elements.item.getBoundingClientRect()
      };
      if (rects.item.bottom > rects.container.bottom) {
        elements.container.scrollTop += rects.item.bottom - rects.container.bottom;
      } else if (rects.item.top < rects.container.top) {
        elements.container.scrollTop -= rects.container.top - rects.item.top;
      }
    }
  }, [selectedIndex]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);
  
  return (
    <div className="tw-relative">
      {isOpen && (
        <div className="tw-fixed tw-inset-0 tw-z-[1900] tw-bg-secondary/10 tw-backdrop-blur-[1px]"></div>
      )}
      <div className={`tw-relative tw-flex tw-items-center tw-gap-[12px] tw-w-full tw-h-[40px] tw-px-3 tw-border tw-rounded-[8px] tw-bg-light-1 tw-z-[1901] tw-transition-all tw-duration-200 ${
        isOpen 
          ? 'tw-border-secondary tw-ring-1 tw-ring-secondary'
          : 'tw-border-dark-2 !tw-border-b-gray-200 hover:tw-border-primary hover:tw-ring-1 hover:tw-ring-primary'
      }`}>
        <GlobalSearchIcon className="tw-h-6 tw-w-6 tw-text-secondary tw-z-[1901]" />
        <input
          ref={inputRef}
          type="text"
          value={query}
          onChange={(e) => handleSearch(e.target.value)}
          onKeyDown={handleKeyDown}
          onClick={() => {
            setIsOpen(true);
          }}
          placeholder={isOpen ? 'Search features or actions' : 'Search features'}
          className={`tw-flex-1 tw-border-0 tw-bg-transparent tw-px-2 tw-text-[15px] focus:tw-outline-none focus:tw-ring-0 tw-z-[1901] ${
            isOpen 
              ? 'tw-text-secondary tw-placeholder-dark-1' 
              : 'tw-text-secondary tw-placeholder-dark-4'
          }`}
        />
        <div className="tw-flex tw-items-center tw-gap-1">
          {query && (
            <button
              onClick={(e) => {
                e.preventDefault();
                setQuery('');
                setResults({});
                handleSearch('');
                inputRef.current?.focus();
              }}
              className="tw-p-1 tw-text-gray-400 tw-transition-colors tw-border-0 tw-bg-transparent clear-search-button"
            >
              <X className="tw-h-4 tw-w-4" />
            </button>
          )}
          {!isOpen && (
            <kbd className="tw-bg-white tw-inline-flex tw-items-center tw-gap-1 tw-justify-center tw-text-[11px] tw-text-secondary tw-px-1.5 tw-h-[18px] tw-rounded">
              {isMac ? (
                <>
                  <Command className="tw-h-3 tw-w-3" />
                </>
              ) : (
                'Ctrl'
              )}
              /
            </kbd>
          )}
        </div>
      </div>

      {isOpen && (
        <div 
          ref={searchRef} 
          className="tw-absolute tw-left-0 tw-right-0 tw-top-[calc(100%+10px)] tw-z-[1900] tw-bg-white tw-rounded-xl tw-border tw-border-gray-200 tw-shadow-[0px_0px_8px_4px_rgba(0,0,0,0.10)]"
        >
          {/* Search Results or History */}
          <div ref={resultsContainerRef} className="tw-py-[12px] tw-max-h-[400px] tw-overflow-y-auto tw-px-1">
            {(!query && searchHistory.length > 0) ? (
              <SearchHistory
                searchHistory={searchHistory}
                handleHistoryClick={handleHistoryClick}
                handleRemoveHistory={handleRemoveHistory}
                handleClearHistory={clearHistory}
              />
            ) : Object.keys(results).length > 0 ? (
              <SearchResults
                results={results}
                selectedIndex={selectedIndex}
                isNavigatingWithKeyboard={isNavigatingWithKeyboard}
                query={query}
                getGroupIndex={getGroupIndex}
                handleNavigate={handleNavigate}
                selectedItemRef={selectedItemRef}
              />
            ) : (
              <div className="tw-px-[12px] tw-py-[24px] tw-text-center">
                <p className="tw-text-body tw-text-dark-5 tw-mb-0">
                  {query ? (
                    <>No results found for &ldquo;<span className="tw-text-dark">{query}</span>&rdquo;</>
                  ) : (
                    'Type to start searching...'
                  )}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default GlobalSearch;