import { FC, useMemo } from 'react';
import { ORDER_LOG_TRANSITION_TYPE } from '../constants';
import { iOrderLog } from '../types';
import { getItemTransitionDetails, getSkuDetails } from '../utils';
import { CardHeader, SkuCardHeader } from './card-header';
import DetailsList from './details-list';

/**
 * Card component for displaying SKU-specific changes
 * Shows SKU image, details and associated changes
 * Handles fallback for missing images
 * @component
 */
interface iSkuDetailsCardProps {
  icon: string
  label: string
  value: string
  preloadData: iOrderLog | null
}

const SkuDetailsCard: FC<iSkuDetailsCardProps> = ({ icon, label: title, value, preloadData }) => {
  const itemTransitions = preloadData?.metadata.order_transition?.item_transitions;
  const hasItemTransitions = Boolean(itemTransitions?.length);

  const transitions = useMemo(() => {
    if (!hasItemTransitions) return [];

    const validTransitions = itemTransitions?.map((transition) => {
      const itemData = transition.item_transition.updated;
      if (!itemData) return null;

      const isItemUpdatedTransition = transition.transition_type === ORDER_LOG_TRANSITION_TYPE.ITEM_UPDATED;
      const previousItemData = isItemUpdatedTransition ? transition.item_transition.previous : null;

      return {
        transitionsType: transition.transition_type || '',
        skuDetails: getSkuDetails(itemData),
        details: getItemTransitionDetails({
          itemData,
          previousItemData,
          isItemUpdatedTransition
        })
      };
    }).filter((item): item is NonNullable<typeof item> => item !== null);

    return validTransitions || [];
  }, [itemTransitions, hasItemTransitions]);

  if (!hasItemTransitions || transitions.length === 0) return null;

  return (
    <div className='details-card border border-light-4 rounded-10px'>
      <CardHeader 
        icon={icon}
        title={title}
        value={value}
      />

      <div className='d-flex flex-column gap-16px p-16px rounded-6px'>
        {transitions.map((transition, index) => (
          <div key={index} className='border border-light-4 rounded-6px'>
            <SkuCardHeader 
              {...transition.skuDetails}
              transitionsType={transition.transitionsType}
            />
            <DetailsList 
              details={transition.details} 
              showPreviousDetails={transition.transitionsType === ORDER_LOG_TRANSITION_TYPE.ITEM_UPDATED} 
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SkuDetailsCard;
