import DeleteIcon from '@src/assets/images/icons/deleteIcon';
import React from 'react';
import { Clock } from 'react-feather';
interface SearchHistoryProps {
  searchHistory: string[]
  // eslint-disable-next-line no-unused-vars
  handleHistoryClick: (historyItem: string) => void
  // eslint-disable-next-line no-unused-vars
  handleRemoveHistory: (e: React.MouseEvent, historyItem: string) => void
  handleClearHistory: () => void
}

export const SearchHistory: React.FC<SearchHistoryProps> = ({
  searchHistory,
  handleHistoryClick,
  handleRemoveHistory,
  handleClearHistory
}) => {
  return (
    <div>
      <div className="tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-1">
        <span className="tw-text-[16px] tw-font-semibold tw-text-dark">Recent Searches</span>
        <button
          onClick={handleClearHistory}
          className="tw-text-body tw-text-dark-5 hover:tw-text-gray-700 tw-transition-colors tw-border-0 tw-bg-transparent"
        >
          Clear all
        </button>
      </div>
      <div>
        {searchHistory.map((historyItem) => (
          <button
            key={historyItem}
            onClick={() => handleHistoryClick(historyItem)}
            className="tw-flex tw-w-full tw-items-center tw-justify-between tw-rounded-md tw-px-[12px] tw-text-h3 tw-text-dark hover:tw-bg-primary-lighter tw-transition-colors tw-duration-150 tw-border-0 tw-bg-transparent"
          >
            <div className="tw-flex tw-items-center tw-gap-[10px]">
              <div className="tw-flex tw-items-center tw-justify-center tw-h-[42px] tw-rounded-lg">
                <Clock className="tw-h-5 tw-w-5 tw-text-primary-light" />
              </div>
              <span className="tw-font-regular tw-text-body">{historyItem}</span>
            </div>
            <button
              onClick={(e) => handleRemoveHistory(e, historyItem)}
              className="tw-p-1 tw-text-dark-3 hover:tw-text-primary tw-transition-colors tw-border-0 tw-bg-transparent"
            >
              <DeleteIcon className="tw-h-5 tw-w-5 tw-text-dark hover:tw-text-danger" />
            </button>
          </button>
        ))}
      </div>
    </div>
  );
}; 