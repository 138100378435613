import DropDownText from '@src/@core/components/ui/custom-search-dropDown'
import '../styles.scss'

const TableSearch = ({
  searchcolumnsList,
  handleQueryParams,
  search_column_query
}) => {
  return (
    <DropDownText
      columnsList={searchcolumnsList}
      handleQueryParams={handleQueryParams}
      search_column_query={search_column_query}
    />
  )
}
export default TableSearch
