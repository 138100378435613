import Fuse from 'fuse.js';
import { useEffect, useState } from 'react';
import { searchData } from '../../../assets/data/features-search';

type ActionItem = {
  name: string;
  path: string;
  description?: string;
  synonyms?: string[];
};

type SearchItem = {
  title: string;
  path: string;
  description?: string;
  synonyms?: string[];
  score?: number;
  actions?: Array<{ item: ActionItem; path: string }>;
};

type FlattenedItem = {
  type: 'main' | 'action';
  category: string;
  title?: string;
  path: string;
  score?: number;
  parentTitle?: string;
  parentPath?: string;
  name?: string;
};

// Fuse.js options for searching
const fuseOptions = {
  keys: [
    { name: 'title', weight: 2 },
    { name: 'description', weight: 1.5 },
    { name: 'synonyms', weight: 1 },
    { name: 'actions.name', weight: 2 },
    { name: 'actions.description', weight: 1.5 },
    { name: 'actions.synonyms', weight: 1 }
  ],
  shouldSort: true,
  includeScore: true,
  threshold: 0.1,
  ignoreLocation: true,
  useExtendedSearch: true,
  minMatchCharLength: 2,
  sortFn: (a: { score: number }, b: { score: number }) => {
    const aExact = a.score === 0;
    const bExact = b.score === 0;
    if (aExact && !bExact) return -1;
    if (!aExact && bExact) return 1;
    return a.score - b.score;
  }
};

export const useSearch = () => {
  const [query, setQuery] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [results, setResults] = useState<Record<string, any>>({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [flattenedResults, setFlattenedResults] = useState<any[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isNavigatingWithKeyboard, setIsNavigatingWithKeyboard] = useState(false);

  // Initialize Fuse instance
  const fuse = new Fuse(searchData, fuseOptions);

  // Flatten results for keyboard navigation
  useEffect(() => {
    const flattened = Object.entries(results).reduce<FlattenedItem[]>((acc, [category, items]) => {
      const flatItems = items.reduce((itemAcc: FlattenedItem[], item: SearchItem) => {
        // Add main item
        itemAcc.push({ 
          type: 'main',
          category,
          title: item.title,
          path: item.path,
          score: item.score
        });

        // Add only visible action items
        if (item.actions) {
          // Only take first 3 actions unless expanded
          const visibleActions = item.actions.slice(0, 3);
          visibleActions.forEach(action => {
            itemAcc.push({
              type: 'action',
              category,
              parentTitle: item.title,
              parentPath: item.path,
              name: action.item.name,
              path: action.item.path
            });
          });
        }
        return itemAcc;
      }, []);
      return [...acc, ...flatItems];
    }, []);
    
    setFlattenedResults(flattened);
    if (!isNavigatingWithKeyboard) {
      setSelectedIndex(-1);
    }
  }, [results]);

  const handleSearch = (value: string) => {
    setQuery(value);
    setIsNavigatingWithKeyboard(false);
    setSelectedIndex(-1);
    
    if (value.length > 0) {
      // Split query into words for flexible matching
      const queryWords = value.toLowerCase().split(/\s+/).filter(word => word.length > 1);
      const searchResults = fuse.search(value);
      
      // Group results by category, maintaining sort order
      const groupedResults = searchResults.reduce((acc, { item, score }) => {
        const category = item.category;
        if (!acc[category]) {
          acc[category] = [];
        }

        // Check if any action matches the search criteria
        const fuseActions = new Fuse(item.actions || [], {
          keys: [
            { name: 'name', weight: 2 },
            { name: 'description', weight: 1.5 },
            { name: 'synonyms', weight: 1 }
          ],
          threshold: 0.3,
          ignoreLocation: true,
          useExtendedSearch: true,
          minMatchCharLength: 2
        });

        const filteredActions = fuseActions.search(value);

        // Include item if it matches directly or through its actions
        const mainItemMatches = ((score || 0) < 0.3) || item.synonyms?.some(syn => 
          queryWords.every(word => syn.toLowerCase().includes(word))
        );

        if (mainItemMatches || (filteredActions && filteredActions.length > 0)) {
          acc[category].push({ 
            ...item, 
            score: mainItemMatches ? score : 0.5, // Prioritize main item matches
            actions: filteredActions || [] 
          });
        }
        
        return acc;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }, {} as Record<string, any[]>);

      // Sort items within each category by score
      Object.keys(groupedResults).forEach(category => {
        groupedResults[category].sort((a, b) => a.score - b.score);
      });

      setResults(groupedResults);
    } else {
      setResults({});
    }
  };

  // Get current selected item index
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getGroupIndex = (category: string, item: any) => {
    return flattenedResults.findIndex((r) => {
      if (r.category === category) {
        if (item.type === 'main') {
          return r.path === item.path;
        } else {
          return r.name === item.name;
        }
      } else return false;
    }
    );
  };

  return {
    query,
    results,
    flattenedResults,
    selectedIndex,
    isNavigatingWithKeyboard,
    setQuery,
    setSelectedIndex,
    setIsNavigatingWithKeyboard,
    handleSearch,
    getGroupIndex,
    setResults
  };
};  