import Button from '@src/@core/components/ui/button';
import InputField from '@src/@core/components/ui/input-field';
import Select from '@src/@core/components/ui/select';
import SideSheet from '@src/@core/components/ui/sideSheet';
import SidesheetFooter from '@src/@core/components/ui/sidesheet-footer';
import { KEYBOARD_KEYS } from '@src/App.constants';
import { FC, KeyboardEvent, useMemo } from 'react';
// @ts-expect-error fix me
import warningImage from '@src/assets/images/catalog/bundlesku/warning-icon.svg';
import { RootState } from '@src/redux/store';
import { ADDITIONAL_CHARGE_TYPES, EDIT_ENTITY_TYPE, FINAL_ORDER_TOTAL_LABELS } from '@src/views/sales/sales.constant';
import { iAdditionalCharge, iInvoice } from '@src/views/sales/sales.types';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { editCustomerAndShippingDetails } from '../../store/store';
import CodCharges from './components/cod-charges';
import { CONFIRM_CHANGES_FORM_FIELD_NAMES, PAYMENT_METHOD_OPTIONS, TRANSACTION_HANDLING_TYPES } from './constants';
import './styles.scss';
import { iAdditionalChargeBody, iConfirmChangesFormValues, iTransactionBody } from './types';
import { checkCodChargesApplicable, getFinalOrderTotal, getFinalOrderTotalForPendingTransaction, getTransactionSideSheetTitle, shouldShowPaymentDetails } from './utils';
interface iTransactionsSideSheetProps {
  isOpen: boolean
  handleClose: () => void
  // eslint-disable-next-line no-unused-vars
  handleContinue?: ({ transactions, additionalCharges }: { transactions: iTransactionBody[]; additionalCharges: iAdditionalChargeBody[] }) => void
  previousInvoice?: iInvoice // previousInvoice & handleContinue is undefined for pending transactions
  newInvoice: {
    total: number
    finalOrderTotal?: number
    currency: string,
    orderTotalType?: string
  }
  isSalesChannelOrder: boolean,
  orderId?: string, // orderId is always defined for pending transactions
  transactionHandlingType: typeof TRANSACTION_HANDLING_TYPES[keyof typeof TRANSACTION_HANDLING_TYPES]
}

/**
 * TransactionsSideSheet handles two types of transactions:
 * 1. Pending Transactions: For processing pending refunds or dues
 *    - No previous invoice
 *    - api call with in the component
 *    - newInvoice.orderTotalType will specify if it's a refund_due or due_amount
 *    - newInvoice.finalOrderTotal will contain the actual refund/due amount
 *    - In this case, we calculate the final order total using getFinalOrderTotalForPendingTransaction
 *    - COD charges never be applicable

 * 2. Edit Order Transactions: For modifying refunds or dues in edit order flow
 *    - Has previous invoice information
 *    - Uses previous invoice and new invoice to determine if it's a refund or due amount
 *    - Shows comparison between previous and new order totals
 *    - api call with callback function
 *    - COD charges can be applicable
 */

/**
 * COD charges handling is present for all cases, currently it is only allowed for those orders which is transact from prepaid to postpaid (newTotal > previousTotal).
 */

const TransactionsSideSheet: FC<iTransactionsSideSheetProps> = ({
  isOpen,
  handleClose,
  handleContinue,
  previousInvoice,
  newInvoice,
  isSalesChannelOrder,
  orderId,
  transactionHandlingType
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const loading = useSelector((state: RootState) => state.sales.loading) as { editCustomerAndShippingDetails: boolean };

  const previousCurrency = previousInvoice?.currency;
  const newCurrency = newInvoice?.currency;
  const previousCodChargesAmount = previousInvoice?.additional_charges.find((charge: iAdditionalCharge) => charge.type === ADDITIONAL_CHARGE_TYPES.COD_FEE)?.value;
  const isPendingTransaction = transactionHandlingType === TRANSACTION_HANDLING_TYPES.PENDING_TRANSACTION;
  const isEditOrderTransaction = transactionHandlingType === TRANSACTION_HANDLING_TYPES.EDIT_ORDER_TRANSACTION;

  const formDefaultValues = {
    [CONFIRM_CHANGES_FORM_FIELD_NAMES.IS_COD_CHARGES_EDIT_MODE]: false,
    [CONFIRM_CHANGES_FORM_FIELD_NAMES.COD_CHARGES_AMOUNT]: previousCodChargesAmount?.toString() || '',
    [CONFIRM_CHANGES_FORM_FIELD_NAMES.PAYMENT_METHOD]: null,
    [CONFIRM_CHANGES_FORM_FIELD_NAMES.PAYMENT_REFERENCE_NUMBER]: '',
  };

  const confirmChangesForm = useForm<iConfirmChangesFormValues>({
    defaultValues: formDefaultValues,
    mode: 'onChange',
  });

  const {
    watch,
    reset,
    handleSubmit,
    control,
    register,
    formState: { errors },
    setValue,
  } = confirmChangesForm;

  const handleCloseSidesheet = () => {
    handleClose();
    reset(formDefaultValues);
  };

  // finalOrderTotal never be undefined 
  const finalOrderTotal = useMemo(() => {

    const codChargesAmount = +(watch(CONFIRM_CHANGES_FORM_FIELD_NAMES.COD_CHARGES_AMOUNT) || 0);
    
    if (isEditOrderTransaction) {
      const newTotalWithCharges = newInvoice.total + codChargesAmount;
      return getFinalOrderTotal({
        newTotal: newTotalWithCharges,
        previousInvoice: previousInvoice!
      });
    } else if (isPendingTransaction) {
      return getFinalOrderTotalForPendingTransaction({
        orderTotalType: newInvoice.orderTotalType!,
        finalOrderTotal: newInvoice.finalOrderTotal!,
        codChargesAmount: codChargesAmount
      });
    }
  }, [watch(CONFIRM_CHANGES_FORM_FIELD_NAMES.COD_CHARGES_AMOUNT), newInvoice.total, previousInvoice?.total, previousInvoice?.total_due, previousInvoice?.payment_method, isEditOrderTransaction, isPendingTransaction]);

  // COD charges are applicable only when the payment method is prepaid, the new total is greater than the previous total, and the transaction type is "Edit Order Transactions".
  const isCodApplicable = checkCodChargesApplicable({
    paymentMethod: previousInvoice?.payment_method || '',
    finalOrderTotal: finalOrderTotal!
  });

  const handleFormSubmit = (data: iConfirmChangesFormValues) => {
    const transactions: iTransactionBody[] = [];

    if (data[CONFIRM_CHANGES_FORM_FIELD_NAMES.PAYMENT_METHOD]?.value) {
      transactions.push({
        payment_mode: data[CONFIRM_CHANGES_FORM_FIELD_NAMES.PAYMENT_METHOD]!.value,
        reference_number: data[CONFIRM_CHANGES_FORM_FIELD_NAMES.PAYMENT_REFERENCE_NUMBER] || undefined,
        amount: finalOrderTotal!.value,
        type: finalOrderTotal!.transactionType,
        currency: newCurrency,
      });
    }

    // tax_inclusive, tax_percentage, is_applied are for future scope for now they will be hardcoded values
    const additionalCharges = isCodApplicable && data[CONFIRM_CHANGES_FORM_FIELD_NAMES.COD_CHARGES_AMOUNT] !== '' ? [
      {
        type: ADDITIONAL_CHARGE_TYPES.COD_FEE,
        cost: +data[CONFIRM_CHANGES_FORM_FIELD_NAMES.COD_CHARGES_AMOUNT],
        tax_inclusive: false,
        tax_percentage: 0,
        is_applied: true,
      },
    ] : [];

    // Pending Transactions handling
    if (transactionHandlingType === TRANSACTION_HANDLING_TYPES.PENDING_TRANSACTION) {
      const body = {
        invoice: {
          currency: newCurrency,
          additional_charges: additionalCharges.length > 0 ? additionalCharges : undefined
        },
        transactions,
        edit_entity_type: EDIT_ENTITY_TYPE.INVOICE_DETAILS,
      };
      // @ts-expect-error fix me
      // The handling of API success needs to be done from outside of this component.
      dispatch(editCustomerAndShippingDetails({ order_id: orderId, body }));
    } else if (transactionHandlingType === TRANSACTION_HANDLING_TYPES.EDIT_ORDER_TRANSACTION) {
      handleContinue!({ transactions, additionalCharges });
    }

  };

  const handleKeyDown = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.key === KEYBOARD_KEYS.ENTER) {
      e.preventDefault();
    }
  };

  const showPaymentDetails = shouldShowPaymentDetails(finalOrderTotal!.value);
  const isRefund = finalOrderTotal!.label === FINAL_ORDER_TOTAL_LABELS.REFUND_DUE.label;
  const sidesheetTitle = getTransactionSideSheetTitle({ isEditOrderTransaction, isPendingTransaction, orderTotalType: newInvoice.orderTotalType! });

  return (
    // @ts-expect-error fix me
    <SideSheet isOpen={isOpen} toggle={handleCloseSidesheet} title={t(sidesheetTitle)} size='sm' modalClassName='modal-slide-in transactions-sidesheet'>
      <form className='transactions-form d-flex flex-column gap-24px h-100 p-24px' onKeyDown={(e) => handleKeyDown(e)} onSubmit={handleSubmit(handleFormSubmit)}>
        {isSalesChannelOrder ? (
          <div className='d-flex gap-12px p-16px bg-warning-light rounded-8px'>
            <img src={warningImage} alt='warning' width={16} height={16} />
            <div className='d-flex flex-column gap-8px text-dark'>
              <span className='txt-sub-sb'>{t('Please Note')}</span>
              <span className='txt-sub-rg'>{t('Confirming these changes will finalise the updates and disable order sync from the sales channel, except for cancellations.')}</span>
            </div>
          </div>
        ) : null}

        <div className='d-flex flex-column gap-16px p-24px border rounded-12px'>
          {previousInvoice ? <div className='flex-center-between txt-body-md'>
            <span className='d-flex gap-4px text-dark-5'>
              <span>{t('Previous Order Total')}</span>
              <span className='txt-body-rg'>({previousInvoice.payment_method})</span>
            </span>
            <span className='text-dark'>
              {previousInvoice.total.toFixed(2)} {previousCurrency}
            </span>
          </div> : null}

          <div className='flex-center-between txt-body-md'>
            <span className='text-dark-5'>{t(isPendingTransaction ? 'Order Total' : 'New Order Total')}</span>
            <span className='text-dark'>
              {newInvoice.total.toFixed(2)} {newCurrency}
            </span>
          </div>

          {isCodApplicable && <CodCharges
            confirmChangesForm={confirmChangesForm}
            currency={newCurrency}
            newOrderTotal={newInvoice.total}
            maxLimit={isRefund ? finalOrderTotal!.value : undefined}
          />}
          <hr className='m-0' />
          <div className='flex-center-between txt-h3-md text-dark'>
            <span>{t(finalOrderTotal!.label)}</span>
            <span>
              {finalOrderTotal!.value.toFixed(2)} {newCurrency}
            </span>
          </div>
        </div>

        {showPaymentDetails && (
          <div className='d-flex flex-column gap-22px'>
            <span className='txt-body-md text-dark'>
              {t(isRefund ? 'Please enter the details if the refund has been made.' : 'Please enter the details if the due amount has been paid.')}
            </span>

            <div className='d-flex flex-column gap-22px'>
              <Controller
                name={CONFIRM_CHANGES_FORM_FIELD_NAMES.PAYMENT_METHOD}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    /* @ts-expect-error fix */
                    errors={errors}
                    options={Object.values(PAYMENT_METHOD_OPTIONS)}
                    isAsync={false}
                    label='Payment Method'
                    isClearable={true}
                    onChange={(option) => {
                      field.onChange(option);
                      setValue(CONFIRM_CHANGES_FORM_FIELD_NAMES.PAYMENT_REFERENCE_NUMBER, '');
                    }}
                  />
                )}
              />

              <InputField
                {...register(CONFIRM_CHANGES_FORM_FIELD_NAMES.PAYMENT_REFERENCE_NUMBER)}
                // @ts-expect-error fix me
                name={CONFIRM_CHANGES_FORM_FIELD_NAMES.PAYMENT_REFERENCE_NUMBER}
                disabled={!watch(CONFIRM_CHANGES_FORM_FIELD_NAMES.PAYMENT_METHOD)}
                errors={errors}
                label={t('Payment Reference Number')}
                infoText={t('Reference number: Transaction ID, Cheque number, or Reference code')}
              />
            </div>
          </div>
        )}

        <SidesheetFooter className='gap-10px'>
          <Button type='button' ofStyle='outlined' onClick={handleCloseSidesheet}>
            {t('Cancel')}
          </Button>
          <Button type='submit' loading={loading.editCustomerAndShippingDetails} disabled={watch(CONFIRM_CHANGES_FORM_FIELD_NAMES.IS_COD_CHARGES_EDIT_MODE)}>{t('Confirm')}</Button>
        </SidesheetFooter>
      </form>
    </SideSheet>
  );
};

export default TransactionsSideSheet;
