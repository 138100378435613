import InputField from '@src/@core/components/ui/input-field';
import PhoneNumberDropdown from '@src/@core/components/ui/phone-number';
import { REGEX_PATTERN } from '@src/App.constants';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES } from '../editCustomerShippingModal.constant';
import { iFormData, iMobileDetails } from '../types';

interface Props {
  editCustomerShippingForm:UseFormReturn<iFormData>
  isCustomerOrRecipient: boolean
}

export const CustomerDetailsForm: FC<Props> = ({
  editCustomerShippingForm,
  isCustomerOrRecipient
}) => {
  const { t } = useTranslation();
  const { register, control, watch, setError, clearErrors, formState: { errors } } = editCustomerShippingForm;
  return (
    <div className='px-24px d-flex flex-column gap-24px'>
      <InputField
        {...register(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.FIRST_NAME as keyof iFormData)}
        // @ts-expect-error Fix Me
        name={EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.FIRST_NAME}
        errors={errors}
        label='First Name'
        isClearable
      />
      <InputField
        {...register(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.LAST_NAME as keyof iFormData)}
        // @ts-expect-error Fix Me
        name={EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.LAST_NAME}
        errors={errors}
        isClearable
        label='Last Name'
      />
      {isCustomerOrRecipient && (
        // @ts-expect-error Fix Me
        <PhoneNumberDropdown
          name={EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.MOBILE}
          control={control}
          errors={errors}
          register={register}
          isClearable
          label='Contact Number'
          contactNumber={(watch(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.MOBILE as keyof iFormData) as iMobileDetails)?.contactNumber}
          countryCode={(watch(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.MOBILE as keyof iFormData) as iMobileDetails)?.countryCode}
          setError={setError}
          clearErrors={clearErrors}
        />
      )}
      <InputField
        {...register(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.EMAIL as keyof iFormData, {
          pattern: {
            value: REGEX_PATTERN.EMAIL_VALIDATION,
            message: t('Enter a valid email address')
          }
        })}
        // @ts-expect-error Fix Me
        name={EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.EMAIL}
        errors={errors}
        isClearable
        label='Email ID'
      />
    </div>
  );
}; 