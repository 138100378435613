import { API_ENDPOINTS } from '@src/api.urls'
import { axiosInstance } from '@src/network/AxiosInstance'
import { getApiUrl } from '@src/utility/Utils'
import { VIRTUAL_HUB } from './StockTransferOrder/sto.constant'
import { ORDER_FREE_TAGS } from './sales.constant'

export const getOrdersApi = {
  apiKey: ['get-orders'],
  url: API_ENDPOINTS.ORDERS.GET_ORDERS
}

export const getInvoiceDataApi = {
  apiKey: ['get-order-invoice-data'],
  url: API_ENDPOINTS.ORDERS.GET_INVOICE_DATA
}

export const createShipment = {
  apiKey: ['create-shipment'],
  url: API_ENDPOINTS.ORDERS.CREATE_SHIPMENT
}
  
export const downloadOrderReport = {
  apiKey: ['oms-order-report-download'],
  url: API_ENDPOINTS.ORDERS.DOWNLOAD_REPORT
}

export const orderItemsWithBatchesConfigApi = {
  apiKey: ['order-items-with-batches-config'],
  url: API_ENDPOINTS.ORDERS.GET_ORDER_DETAILS
}

export const reassignHubApi = {
  apiKey: ['reassign_hub'],
  url: API_ENDPOINTS.ORDERS.REASSIGN_HUB
}

export const reorderDataApi = {
  apiKey: ['get-data-for-re-order'],
  url: API_ENDPOINTS.ORDERS.GET_ORDER_DETAILS
}

export const singleManifestDetails = {
  apiKey: ['get-single-manifest-details'],
  url: API_ENDPOINTS.ORDERS.GET_SINGLE_MANIFEST_DETAILS
}

export const bulkRemoveOrdersInManifest = {
  apiKey: ['bulk-remove-manifest-orders'],
  url: API_ENDPOINTS.ORDERS.GET_SINGLE_MANIFEST_DETAILS
}

export const getManifestPrintDataApi = {
  apiKey: ['get-manifest-print-data'],
  url: API_ENDPOINTS.ORDERS.MANIFEST_PRINT_DATA
}

export const getConveyorBeltIntegrationsApi = {
  apiKey: ['get-conveyor-belt-integrations'],
  url: API_ENDPOINTS.COMMON.CONVEYOR_BELTS_INTEGRATIONS
}

export const LOAD_PRINTERS = {
  apiUrl: (additional) => {
    return `/api/v1/pms/hubs/${additional.hubId}/computers/${additional.printerId}/printers`
  },
  select: (data) => data.data?.printers?.map(ele => ({
    label: ele.name,
    value: ele.id,
  })) || [],
  hasMore: () => false
}

export const LOAD_DEVICES = {
  apiUrl: (additional) => {
    return `/api/v1/pms/hubs/${additional.hubId}/computers`
  },
  select: (data) => {
    return data?.data?.computers?.map(ele => ({
      label: ele.name,
      value: ele.id
    })) || []
  },
  hasMore: () => false
}

export const fetchShippingAddresses = async (search, _loadedOptions, { optionModifier, getParams, params: defaultParams = {}, ...additional }) => {
  try {
    // Retrieve query parameters using the provided `getParams` function or default to an empty object
    const additionalParams = getParams instanceof Function ? getParams(search) : defaultParams;
    
    const params = {
      search_column: 'name',
      search_query: search,
      ...defaultParams,
      ...additionalParams
    }
    
    // Fetch data from the API
    const { data } = await axiosInstance.get('/api/v1/oms/hubs', { params });

    const { meta, data: shippingAddresses } = data || {};
    const { current_page: currentPage, last_page: lastPage } = meta || {};
    
    // Transform the response data
    const options = (shippingAddresses || []).map((shippingAddress) => ({
      value: shippingAddress.id,
      label: shippingAddress.name,
      ...(optionModifier?.(shippingAddress) || {}),
    }));

    // Return the formatted response
    return {
      options,
      hasMore: currentPage < lastPage,
      additional: { optionModifier, getParams, params: {...params, page: currentPage + 1}, ...additional },
    };
  } catch (error) {
    console.warn('Error fetching shipping addresses:', error);

    // Return a default fallback response in case of an error
    return {
      options: [],
      hasMore: false,
    };
  }
};

export const loadOmsHubsConfig = {
  apiUrl: API_ENDPOINTS.ORDERS.GET_ALL_OMS_HUBS,
  select: (data, { additional }) => {
    const { hasCreateHubPermission, virtualHubType, params } = additional;

    const options = data?.data.map(ele => ({
      value: { id: ele.id, type: ele.type, code: ele.hub_code },
      label: ele.name,
      address: ele.address
    })) || []
  
    const isHasMore = params.page < data.meta?.last_page;
  
    // Add virtual hub option if conditions are met
    if (!isHasMore && hasCreateHubPermission) {
      const virtualHubOptions = {
        [VIRTUAL_HUB.SOURCE.value]: { value: 'add_new_virtual_source', label: '+ Add New Source', type: 'virtual' },
        [VIRTUAL_HUB.DESTINATION.value]: { value: 'add_new_virtual_destination', label: '+ Add New Destination', type: 'virtual' }
      };
      if (virtualHubType in virtualHubOptions) {
        options.push(virtualHubOptions[virtualHubType]);
      }
    }
    
    return options
  },
  hasMore: (data) => {
    return !!(data.meta && data.meta.current_page < data.meta.last_page)
  },
}

export const loadPickersListConfig = {
  apiUrl: (additional) => getApiUrl(API_ENDPOINTS.COMMON.GET_PICKERS_LIST, { hubId: additional.hubId }),
  select: (data, { search }) => {
    const options = data.data?.filter((ele) => {
      if (search) {
        const regex = new RegExp(search, 'i');
        return regex.test(ele.name);
      }
      return true;
    }).map((ele) => ({
      value: ele.id,
      label: ele.name
    }))
    return options
  },
  hasMore: () => false
}

export const loadBoxesConfig = {
  apiUrl: (additional) => getApiUrl(API_ENDPOINTS.INVENTORY.GET_BOXES, { hubId: additional.hubId }),
  select: (data) => data?.data?.map(ele => ({
    value: ele.id,
    label: ele.name
  })) || []
}

export const loadNearbyPointsConfig = {
  apiFn: axiosInstance.post,
  apiUrl: API_ENDPOINTS.SHIPPING.GET_NEARBY_POINTS,
  select: (data) => data?.data?.nearby_points?.map(ele => ({
    value: ele.point_id,
    label: `${ele.point_name} (${ele.point_type})`,
    address: ele.address,
    distance: ele.distance,
    open_now: ele.open_now,
    point_type: ele.point_type
  })) || [],
  hasMore: () => false
};

export const loadCityMappingsConfig = {
  apiUrl: (additional) => getApiUrl(API_ENDPOINTS.SHIPPING.GET_SHIPPING_PARTNER_CITIES, { shippingPartnerId: additional.shippingPartnerId }),
  additionalHandler: (search, prevAdditional) => ({
    ...prevAdditional,
    params: {
      name_filter: search? search : undefined,
      page: (prevAdditional.params?.page || 0) + 1,
      tenant_mapped: prevAdditional.tenantMapped,
      omniful_country_id: prevAdditional.omnifulCountryId || undefined,
      locale: prevAdditional.locale || undefined,
    }
  }),
  select: (response, { additional }) => {
    const { handleNoDataMsg } = additional
    
    const options = response.data?.data?.map((ele) => ({
      value: ele.city_mapping_id,
      label: ele.shipping_partner_city_name
    })) || [];

    if(!options.length) {
      handleNoDataMsg(response.data.message)
    }

    return options 
  }
};

export const  loadWmsReasonConfig = {
  apiUrl: () => API_ENDPOINTS.INVENTORY.GET_QC_FAIL_REASONS,
  select: (data) => data.data?.map((ele) => ({
    value: ele.id,
    label: ele.title,
  })) || [],
  hasMore: () => false
}

export const loadCustomLabelsConfig = {
  apiUrl: API_ENDPOINTS.ORDERS.GET_CUSTOM_LABELS,
  additionalHandler: (search, prevAdditional) => ({
    ...prevAdditional,
    params: {
      query: search? search : undefined,
      page: (prevAdditional.params?.page || 0) + 1,
    }
  }),
  select: (data, { search, additional }) => {
    const customLabels = data?.data ?? []
    
    if (search && additional.isCreationAllowed) {
      customLabels.unshift({
        id: search, 
        name: `${ORDER_FREE_TAGS.ADD_LABELS.label} "${search}"`, 
        tag: 'new_tag'
      })
    }

    return customLabels.map((ele) => ({
      value: ele.id,
      label: ele.name,
      tag: ele.tag
    })) || []
  }
}

export const loadSalesChannelConfig = {
  apiUrl: API_ENDPOINTS.SALES_CHANNEL.GET_ALL_SALES_CHANNEL,
  select: (response, { additional }) => {
    return response?.data?.map((ele) => ({
      value: ele.tag,
      label: ele.name,
      logo: ele.logo_url,
      ...(additional.includeAllData ? ele : {})
    })) || []
  }
}