import { RootState } from '@src/redux/store';
import { useSelector } from 'react-redux';

interface UserPlan {
  is_wms_enabled: boolean;
  is_pos_enabled: boolean;
  is_oms_enabled: boolean;
}

interface UserData {
  user_plan?: UserPlan;
  selectedGlobalHub?: string | null;
}

interface MetaData {
  is_wms_enabled?: boolean;
  is_pos_enabled?: boolean;
  is_external_hub?: boolean;
}

interface SingleHub {
  meta_data?: MetaData;
}

const useTenantInfo = () => {
  const auth = useSelector((state: RootState) => state.auth) as { 
    userData: UserData | null;
    selectedGlobalHub?: SingleHub | null;
  };
  
  const isWmsEnabledOnUserPlan = auth.userData?.user_plan?.is_wms_enabled;
  const isPosEnabledOnUserPlan = auth.userData?.user_plan?.is_pos_enabled;
  const isOmsEnabledOnUserPlan = auth.userData?.user_plan?.is_oms_enabled;
 
  const selectedGlobalHub = auth.selectedGlobalHub;
  const isWmsEnabledOnHub = selectedGlobalHub?.meta_data?.is_wms_enabled;
  const isPosEnabledOnHub = selectedGlobalHub?.meta_data?.is_pos_enabled;
  const isExternalHub = selectedGlobalHub?.meta_data?.is_external_hub;

  return {
    isWmsEnabledOnUserPlan,
    isPosEnabledOnUserPlan,
    isOmsEnabledOnUserPlan,
    isWmsEnabledOnHub,
    isPosEnabledOnHub,
    isExternalHub,
  };
};

export default useTenantInfo;