import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import FloatingDropDown from '@src/@core/components/ui/floating-dropdown';
import InputField from '@src/@core/components/ui/input-field';
import PhoneNumberDropdown from '@src/@core/components/ui/phone-number';
import { loadCitiesConfig, loadCountryConfig, loadStatesCitiesConfig, loadStatesConfig } from '@src/app.apis';
import { REGEX_PATTERN } from '@src/App.constants';
import { getOptionsFetcher } from '@src/utility/Utils';
import { EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES } from '../editCustomerShippingModal.constant';
import { iFormData, iMobileDetails } from '../types';

interface Props {
  editCustomerShippingForm:UseFormReturn<iFormData>
}

export const ShippingDetailsForm: FC<Props> = ({
  editCustomerShippingForm
}) => {

  const { register, control, watch, setValue, setError, clearErrors, formState: { errors } } = editCustomerShippingForm;
  
  // load options
  // @ts-expect-error Fix Me
  const loadStatesOptions = getOptionsFetcher(loadStatesConfig);
  // @ts-expect-error Fix Me
  const loadCountryOptions = getOptionsFetcher(loadCountryConfig);
  // @ts-expect-error Fix Me
  const loadCitiesOptions = getOptionsFetcher(loadCitiesConfig);
  // @ts-expect-error Fix Me
  const loadStatesCitiesOptions = getOptionsFetcher(loadStatesCitiesConfig);

  return (
    <div className='px-24px d-flex flex-column gap-24px mt-24px'>
      <InputField
        {...register(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.ADDRESS1 as keyof iFormData)}
        // @ts-expect-error Fix Me
        name={EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.ADDRESS1}
        errors={errors}
        isClearable
        label='Address line 1'
      />
      <InputField
        {...register(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.ADDRESS2 as keyof iFormData)}
        // @ts-expect-error Fix Me
        name={EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.ADDRESS2}
        errors={errors}
        isClearable
        label='Address line 2'
      />
      
      <div className='row'>
        <div className='col-6'>
          {/* @ts-expect-error Fix Me */}
          <FloatingDropDown
            name={EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.COUNTRY}
            isAsync
            control={control}
            defaultValue={watch(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.COUNTRY as keyof iFormData)}
            value={watch(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.COUNTRY as keyof iFormData)}
            loadOptions={loadCountryOptions}
            errors={errors}
            isClearable
            title='Country'
            onChangeFunc={() => {
              if (watch(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.STATE as keyof iFormData) || watch(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.CITY as keyof iFormData)) {
                setValue(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.STATE as keyof iFormData, null);
                setValue(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.CITY as keyof iFormData, null);
              }
            }}
          />
        </div>
        <div className='col-6'>
          {/* @ts-expect-error Fix Me */}
          <FloatingDropDown
            name={EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.STATE}
            isAsync
            control={control}
            defaultValue={watch(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.STATE as keyof iFormData)}
            value={watch(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.STATE as keyof iFormData)}
            isDisabled={!watch(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.COUNTRY as keyof iFormData)}
            loadOptions={loadStatesOptions}
            errors={errors}
            isClearable
            title='State/Region'
            cacheUniqs={[watch(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.COUNTRY as keyof iFormData)]}
            additional={{
              country: watch(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.COUNTRY as keyof iFormData)
            }}
            onChangeFunc={() => {
              if (watch(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.CITY as keyof iFormData)) {
                setValue(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.CITY as keyof iFormData, null);
              }
            }}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-6'>
          {/* @ts-expect-error Fix Me */}
          <FloatingDropDown
            name={EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.CITY}
            isAsync
            control={control}
            defaultValue={watch(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.CITY as keyof iFormData)}
            value={watch(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.CITY as keyof iFormData)}
            loadOptions={watch(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.STATE as keyof iFormData) ? loadStatesCitiesOptions : loadCitiesOptions}
            errors={errors}
            isClearable
            isDisabled={!watch(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.COUNTRY as keyof iFormData)}
            title='City'
            cacheUniqs={watch(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.STATE as keyof iFormData) ? [watch(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.STATE as keyof iFormData)] : [watch(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.COUNTRY as keyof iFormData)]}
            additional={{
              state: watch(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.STATE as keyof iFormData),
              params: {
                country_id: watch(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.STATE as keyof iFormData) ? null : (watch(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.COUNTRY as keyof iFormData) as { value: string | number })?.value
              }
            }}
          />
        </div>
        <div className='col-6'>
          <InputField
            {...register(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.ZIP as keyof iFormData, {
              pattern: {
                value: REGEX_PATTERN.ALPHANUMERIC_VALIDATION,
                message: 'only alphanumeric characters allowed'
              }
            })}
            // @ts-expect-error Fix Me
            name={EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.ZIP}
            errors={errors}
            isClearable
            label='Zip Code'
          />
        </div>
      </div>
      {/* @ts-expect-error Fix Me */}
      <PhoneNumberDropdown
        name={EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.MOBILE}
        control={control}
        errors={errors}
        register={register}
        isClearable
        label='Contact Number'
        contactNumber={(watch(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.MOBILE as keyof iFormData) as iMobileDetails)?.contactNumber}
        countryCode={(watch(EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.MOBILE as keyof iFormData) as iMobileDetails)?.countryCode}
        setError={setError}
        clearErrors={clearErrors}
      />
    </div>
  );
};