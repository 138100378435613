import { HUBS_CONFIGURATION, NOT_APPLICABLE, TENANT_TYPE } from '@src/App.constants';
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping';
import ability from '@src/configs/acl/ability';
import { HUB_ASSESTS } from '@src/views/hubs/constants.hubs';
import { getRootState, isObjEmpty } from './Utils';

export const isOnlyPosEnabledOnUserPlan = () => {
  const auth = getRootState().auth
  const userPlan = auth.userData?.user_plan || {}
  
  const filterPlans = (() => {
    const enabledPlans = Object.values(userPlan).filter(Boolean)
    return enabledPlans.length === 1 && userPlan.is_pos_enabled
  })()

  return filterPlans
}

export const getBulkShipViewPermission = () => {
  const auth = getRootState().auth
  const selectedGlobalHub = auth.selectedGlobalHub || {}
  const isExternalHub = selectedGlobalHub.meta_data?.is_external_hub
  const isWmsEnabledOnHub = selectedGlobalHub.meta_data?.is_wms_enabled
  const hasBulkShipViewPermission = ability.can(abilityMap.bulk_ship.view.action, abilityMap.bulk_ship.view.resource)

  return !isExternalHub && hasBulkShipViewPermission && isWmsEnabledOnHub
}

export const getTaxInvoicesViewPermission = () => {
  const hasInvoiceViewPermission = ability.can(abilityMap.invoices.view.action, abilityMap.invoices.view.resource)
  return hasInvoiceViewPermission && !isOnlyPosEnabledOnUserPlan()
}

export const getKitsAndSalesChannelListingViewPermission = () => {
  const hasProductViewPermission = ability.can(abilityMap.product.view.action, abilityMap.product.view.resource)
  return hasProductViewPermission && !isOnlyPosEnabledOnUserPlan()
}

export const getManageInvoicesViewPermission = () => {
  const hasInvoiceConfigurePermission = ability.can(abilityMap.invoices.configure.action, abilityMap.invoices.configure.resource)
  return hasInvoiceConfigurePermission && !isOnlyPosEnabledOnUserPlan()
}

export const getPosViewPermission = () => {
  const selectedGlobalHub = getRootState().auth.selectedGlobalHub
  const isPosEnabledOnHub = selectedGlobalHub?.meta_data.is_pos_enabled
  const hasPosViewPermission = ability.can(abilityMap.custom.pos_view.action, abilityMap.custom.pos_view.resource)

  return hasPosViewPermission && isPosEnabledOnHub
}

export const getDeliveryZoneViewPermission = () => {
  const revampedHubs = getRootState().revampedHubs
  const singleHub = revampedHubs.singleHub.data || {}
  if (isObjEmpty(singleHub)) return NOT_APPLICABLE
  const hasHubLocationViewPermission = ability.can(abilityMap.delivery_zone.view.action, abilityMap.delivery_zone.view.resource)

  return hasHubLocationViewPermission && !isOnlyPosEnabledOnUserPlan()
}

export const getViewHubAssetsPermission = (entity) => {
  const rootState = getRootState()
  const auth = rootState.auth
  const revampedHubs = rootState.revampedHubs
  const permissions = auth.permissions || []
  const isWmsEnabledOnUserPlan = auth.userData?.user_plan?.is_wms_enabled
  const hasBinViewPerm = permissions.find(perm => perm.action === 'view_bin' && perm.subject === 'hub_location')
  const singleHub = revampedHubs.singleHub.data || {}
  if (isObjEmpty(singleHub)) return NOT_APPLICABLE
  const isExternalHub = singleHub.is_external_hub
  const isBinEnabled = singleHub.configurations?.find(item => item.configuration_type === HUBS_CONFIGURATION.BIN_CONFIG.value)?.configuration_values?.enabled
  const user = auth.userData
  const isRolePacker = user?.roles?.some(role => role.name === 'packer')
  const isWmsEnabledOnHub = singleHub?.meta_data?.is_wms_enabled
  const isHubAssetsEnabled = isWmsEnabledOnUserPlan && isWmsEnabledOnHub && !isExternalHub

  switch (entity) {
  case HUB_ASSESTS.BIN:
  case HUB_ASSESTS.PALLET:
    return !!(isHubAssetsEnabled && hasBinViewPerm && isBinEnabled);
  case HUB_ASSESTS.CART:
    return !!(isHubAssetsEnabled && hasBinViewPerm);
  case HUB_ASSESTS.BOX:
    return !!(isHubAssetsEnabled && (hasBinViewPerm || isRolePacker));
  default:
    return false;
  }
}

export const getHubLocationViewPermission = () => {
  const rootState = getRootState()
  const permissions = rootState.auth.permissions || []
  const revampedHubs = rootState.revampedHubs
  const singleHub = revampedHubs.singleHub.data || {}
  if (isObjEmpty(singleHub)) return NOT_APPLICABLE
  const isWmsEnabledOnUserPlan = rootState.auth.userData?.user_plan?.is_wms_enabled
  const isWmsEnabledOnHub = singleHub?.meta_data?.is_wms_enabled
  const isExternalHub = singleHub.is_external_hub
  const hasHubLocationViewPermission = permissions.find(perm => perm.action === 'view' && perm.subject === 'hub_location')

  return !!(isWmsEnabledOnUserPlan && !isExternalHub && hasHubLocationViewPermission && isWmsEnabledOnHub)
}

export const getStockOwnerShipViewPermission = () => {
  const auth = getRootState().auth
  const permissions = auth.permissions || []
  const hubId = auth.selectedGlobalHubId
  const selectedHubConfiguration = auth.selectedHubConfiguration
  const isLocationInventoryEnabled = selectedHubConfiguration?.find(el => el.configuration_type === HUBS_CONFIGURATION.LOCATION_INVENTORY.value)?.configuration_values?.enabled
  const isExternalHub = auth.userHubs?.find(hub => hub.id === hubId)?.is_external_hub
  const hasStockOwnerShipViewPermission = permissions.find(perm => perm.action === abilityMap.stock_ownership_transfer.view.action && perm.subject === abilityMap.stock_ownership_transfer.view.resource)

  return !!(!isExternalHub && isLocationInventoryEnabled && hasStockOwnerShipViewPermission)
}

export const getSellersViewPermission = () => {
  const auth = getRootState().auth
  const permissions = auth.permissions || []
  const hasSellersViewPermission = permissions.find(perm => perm.action === abilityMap.custom.seller_view.action && perm.subject === abilityMap.custom.seller_view.resource)
  const tenantType = auth.userData?.tenant.type

  return !!(tenantType === TENANT_TYPE.FULFILLMENT_CENTRE && hasSellersViewPermission)
}

export const getExpiredInventoryViewPermission = () => {
  const isExternalHub = getRootState().auth.selectedGlobalHub?.is_external_hub;
  const hasExpiredInventoryViewPermission = ability.can(abilityMap.inventory.expired_inventory.action, abilityMap.inventory.expired_inventory.resource)

  return !isExternalHub && hasExpiredInventoryViewPermission
}