// ** React Imports
import { Fragment } from 'react'
// import NavbarHub from "./NavbarHub"
// import brandLogo from '@src/assets/images/omniful/omniful-logo.svg'
import omnifulLogoWithText from '@src/assets/images/omniful/omniful_logo_with_text.svg'

// ** Custom Components
import { Link } from 'react-router-dom'
import NavbarUser from './NavbarUser'
// import { useTranslation } from "react-i18next"
import { useSelector } from 'react-redux'

// import NavbarBookmarks from './NavbarBookmarks'

const ThemeNavbar = (props) => {
  // ** Props
  const { skin, setSkin } = props
  // const {t} = useTranslation()
  const tenant = useSelector(store => store.auth.userData?.tenant)

  return (
    <Fragment >
      {/* <NavbarHub /> */}
      
      <Link to="/" className="brand-logo d-flex align-items-center">
        {/* <img src={tenant?.logo || omnifulLogoWithText} className="ms-4px" height= "38px" width= "38px" /> */}
        {/* <h1 className="brand-text text-primary m-0 ms-1">{tenant?.logo ? tenant.name : t("Omniful")}</h1> */}
        <div className="height-38px width-150 tw-flex tw-items-center">
          {tenant && <img
            src={tenant?.logo || omnifulLogoWithText}
            className="object-fit-contain"
            height="100%"
            width="100%"
            alt="Logo"
          />}
        </div>
      </Link>
      <NavbarUser skin={skin} setSkin={setSkin} />
    </Fragment>
  )
}

export default ThemeNavbar
