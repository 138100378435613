import FilterTabs from '@src/components/filterTabs'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import SplittedChildOrdersTab from './splittedChildOrdersTab'
import SplittedOrderItemsTab from './splittedOrderItemsTab'

const componentMap = {
  order_items: SplittedOrderItemsTab,
  splitted_orders: SplittedChildOrdersTab
}

const SplittedOrderDetailsTabsPage = ({isSplitOrderCreated, order_id}) => {

  const singleSplitOrderDetails = useSelector(store => store.sales.singleSplitOrderDetails)

  const SPLITTED_ORDER_DETAILS_TABS_LIST = [
    { id: 'order_items', name: 'Order Items', tabCount: singleSplitOrderDetails?.order_items.length },
    { id: 'splitted_orders', name: 'Split Orders', tabCount: singleSplitOrderDetails?.splitted_orders.length }
  ]
  const CREATE_SPLIT_ORDER_DETAILS_TABS_LIST = [{ id: 'order_items', name: 'Order Items', tabCount: singleSplitOrderDetails?.order_items.length }]

  const [activeTab, setActiveTab] = useState(isSplitOrderCreated ? SPLITTED_ORDER_DETAILS_TABS_LIST[0].id : CREATE_SPLIT_ORDER_DETAILS_TABS_LIST[0].id)

  const handleOnTabChange = tab => {
    setActiveTab(tab.id)
  }

  const ActiveComponent = componentMap[activeTab]
  
  return (
    <div>
      <FilterTabs tabDataList={isSplitOrderCreated ? SPLITTED_ORDER_DETAILS_TABS_LIST  : CREATE_SPLIT_ORDER_DETAILS_TABS_LIST} selectedTabId={activeTab} onTabChange={handleOnTabChange} />
      <div className="mt-24px">
        <ActiveComponent isSplitOrderCreated={isSplitOrderCreated} order_id={order_id} />
      </div>
    </div>
  )
}

export default SplittedOrderDetailsTabsPage