import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import {
  MAX_FILE_SIZE_ALLOWED_IN_BYTES,
  ONE_MB_IN_BYTES,
  PUSHER_RESPONSE_STATUS,
  SORTING_ORDER,
  URL_TO_SCALE_WINDOW_SIZE,
  URLS_TO_EXCLUDE_FROM_GLOBAL_HUB_SCOPE,
  URLS_TO_INCULDE_FROM_GLOBAL_HUB_SCOPE
} from '@src/App.constants'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
// import new_order_notification_audio from '@src/assets/sound/new_order_notification.mp3'
import noSkuImage from '@src/assets/images/omniful/no_sku_image.svg'
import ability from '@src/configs/acl/ability'
import { queryClient } from '@src/configs/react-query/react-query-provider'
import { axiosInstance } from '@src/network/AxiosInstance'
import { disconnectFromPusher } from '@src/network/RealTime'
import { handlePupupBlocked, handlePusherResponse } from '@src/redux/authentication'
import { store } from '@src/redux/store'
import { getOrdersApi } from '@src/views/sales/sales.apis'
import { saveAs } from 'file-saver'
import { useEffect, useRef } from 'react'
import { toast } from 'react-hot-toast'
import { DefaultRoute } from '../router/routes'
import { retry } from './retry'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('auth')
export const getUserData = () => JSON.parse(localStorage.getItem('auth'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'default') return DefaultRoute
  if (userRole === 'client') return '/access-control'
  return '/login'
}

/**
 ** Returns the date in DD-MM-YYYY Format
 * @param {Date} date date to format
 */
export const getDmyDateFormat = (date) => {
  let day = date.getDate()
  day = day > 9 ? day : `0${day}`

  const year = date.getFullYear()

  let month = (date.getMonth() + 1)
  month = month > 9 ? month : `0${month}`

  return `${day}-${month}-${year}`
}

export const convertToAMPM = (time24) => {
  // Split the time into hours and minutes
  const [hrs, minutes] = time24.split(':').map(Number)
  let hours = hrs

  // Determine AM or PM
  let period = 'AM'
  if (hours >= 12) {
    period = 'PM'
    if (hours > 12) {
      hours -= 12
    }
  }

  // Format hours and minutes with zero-padding
  const formattedHours = String(hours).padStart(2, '0')
  const formattedMinutes = String(minutes).padStart(2, '0')

  // Combine and format as "hh : mm AM/PM"
  return `${formattedHours}:${formattedMinutes} ${period}`
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: 'var(--bs-primary)', // for selected option bg-color
    primary25: 'var(--bs-primary-lighter)', // for option hover bg-color
    primary50: 'var(--bs-primary-lighter)',
    neutral10: 'var(--bs-primary)' // for tags bg-color
    // primary50: '#EEF0FE' 
    // neutral20: '#ededed', // for input border-color
    // neutral30: '#ededed' // for input hover border-color
  }
})

export const selectCustomStyle = {
  singleValue: provided => ({
    ...provided,
    color: 'black',
    fontWeight: '400'
  }),
  control: provided => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    width: '120px',
    fontSize: '14px',
    textAlign: 'start'
  }),
  option: provided => ({
    ...provided,
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: '11px',
    textOverflow: 'ellipsis',
    textAlign: 'start'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    position: 'absolute',
    top: state.hasValue || state.selectProps.inputValue ? '-50%' : '15%',
    transition: 'top 0.1s, font-size 0.1s',
    fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
    backgroundColor: 'white'
  }),
  menu: provided => ({
    ...provided,
    height: 'auto',
    width: '100%'
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: '#222222',
    '&:hover': {
      color: 'black'
    }
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none'
  })
}

export const colorPalette = {
  text_primary: '#222222',
  text_secondary: '#666666',
  text_tertiary: '#999999',
  omniful_primary: '#5468FA',
  primary_border: '#d8d6de',
  primary_focus: '#CCD2FD',
  red: '#c21808',
  white: '#ffffff',
  whiteSmoke: '#fefefe',
  light_gray: '#dedede'
}

// get todays date in 'dd-mmy-yyyy' format
export const getTodaysDate = () => {
  const today = new Date()
  const day = String(today.getDate()).padStart(2, '0')
  const month = String(today.getMonth() + 1).padStart(2, '0')
  const year = today.getFullYear()
  const todayFormatted = `${year}-${month}-${day}`
  return todayFormatted
}

export const formatDateToLongMonthFormat = () => {
  const currentDate = new Date()
  const options = { day: 'numeric', month: 'long', year: 'numeric' }
  return new Intl.DateTimeFormat('en-US', options).format(currentDate)
}

// convert date from 'dd-mm-yyyy' format to unix timestamp format
export const convertToUnixTime = dateString => {
  // Parse the date string into a JavaScript Date object
  const dateParts = dateString.split('-')
  const year = parseInt(dateParts[2], 10)
  const month = parseInt(dateParts[1], 10) - 1 // month is 0-indexed
  const day = parseInt(dateParts[0], 10)
  const dateObject = new Date(year, month, day)
  // Convert the Date object to Unix time
  const unixTime = Math.floor(dateObject.getTime() / 1000)
  return unixTime
}

// Debouncing implemented using a closure concept
export const debounceAction = (() => {
  let timeoutId
  return (callback, delay) => {
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      timeoutId = setTimeout(() => {
        callback(...args)
      }, delay)
    }
  }
})()

export const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export const hexToRGBA = (hex, alpha) => {
  // Remove the '#' character if it exists
  if (hex) {
    hex = hex.replace('#', '')

    // Extract the individual color components
    const red = parseInt(hex.substring(0, 2), 16)
    const green = parseInt(hex.substring(2, 4), 16)
    const blue = parseInt(hex.substring(4, 6), 16)

    // Return the RGBA value
    return `rgba(${red}, ${green}, ${blue}, ${alpha})`
  }
}

// react select custom color styles
export const customSelectColorStyles = {
  control: (provided) => ({
    ...provided,
    width: '100%',
    borderColor: '#d8d6de',
    minHeight: '45px',
    // maxHeight: '36px',
    boxShadow: 'none',
    '&:hover': {
      borderColor: 'var(--bs-primary)',
      cursor: 'pointer'
    }
  }),
  valueContainer: (provided) => ({
    ...provided,
    overflow: 'visible',
    width: '100%',
    padding: '8px 8px'
  }),
  option: (provided, { data }) => ({
    ...provided,
    color: data?.color,
    cursor: 'pointer'
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      color: data.color,
      backgroundColor: hexToRGBA(data.color, 0.2),
      borderRadius: '10px'
    }
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
      borderRadius: '10px'
    }
  }),
  placeholder: (provided, state) => ({
    ...provided,
    position: 'absolute',
    top: state.selectProps.inputValue
      ? '-72%'
      : state.hasValue
        ? '-60%'
        : '20%',
    transition: 'top 0.1s, font-size 0.1s',
    fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
    backgroundColor: state.isDisabled ? 'transparent' : 'white'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    paddingRight: 0
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: 'lightgray',
    paddingLeft: 0,
    marginInlineEnd: '10px',
    width: '20px',
    '&:hover': {
      color: 'gray'
    }
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
  menuPortal: (base) => ({ ...base, zIndex: 999999 })
}

const getFileName = (s3Url) => {
  try {
    const url = new URL(s3Url);
    const pathSegments = url.pathname.split('/');
    return pathSegments.pop() || null;  // Return `null` if there's no file name
  } catch (error) {
    console.error('Invalid URL:', error.message);
    return null;
  }
}

export const downloadFile = async (fileUrl) => {
  try {
    const res = await fetch(fileUrl)
    if (res.status >= 400) {
      throw new Error('Not allowed to download this file.');
    } else if (!res.ok) {
      throw new Error(`Failed to download file: ${res.statusText}`);
    }
    const blob = await res.blob() // Get the response and return it as a blob
    const fileName = getFileName(fileUrl)
    saveAs(blob, fileName) // Save the file using FileSaver.js

  } catch (error) {
    console.error('Download error:', error); // Log the error for debugging
  }
}

export const pusherCallback = async (response) => {
  const { event_name, event_type, is_last_response, data, actions } = response
  const messageSeparator = data?.message?.content.split('|') || []
  const messageContent = messageSeparator[0]
  const messageDesc = messageSeparator.length > 1 ? messageSeparator[messageSeparator.length - 1] : ''
  const messageType = data?.message?.type
  const toastOptions = {
    my_type: messageType === PUSHER_RESPONSE_STATUS.FAILURE ? 'error' : messageType,
    audioRequired: messageType === PUSHER_RESPONSE_STATUS.FAILURE
  }
  // deciding and executing the ui interaction depending on type
  switch (event_type) {
  case 'TOAST':
    store.dispatch(handlePusherResponse({ [event_name]: messageType }))
    CustomToast(messageContent, toastOptions, messageDesc ? messageDesc : '')
    break
  case 'DOWNLOAD_LINK':
    store.dispatch(handlePusherResponse({ [event_name]: messageType }))
    await downloadFile(data.link)
    if (data.message) CustomToast(messageContent, toastOptions, messageDesc ? messageDesc : '')
    break
  case 'CUSTOM':
    actions.forEach(async (el) => {
      const sequence = el.sequence
      const selectedAction = actions[sequence - 1]
      const actionType = selectedAction.action_type
      const url = selectedAction.data.url
      const content = selectedAction.data.message.content
      const contentType = selectedAction.data.message.content_type
      const progressPercentage = selectedAction.data.message?.meta?.percentage
      const toastOptions = {
        my_type: contentType === PUSHER_RESPONSE_STATUS.FAILURE ? 'error' : contentType,
        id: selectedAction.data.message?.meta?.uuid || `${event_name}_${event_type}`,
        audioRequired: contentType === PUSHER_RESPONSE_STATUS.FAILURE,
        showProgressBar: contentType === PUSHER_RESPONSE_STATUS.IN_PROGRESS,
        progressPercentage: contentType === PUSHER_RESPONSE_STATUS.IN_PROGRESS ? progressPercentage : 0
      }
      switch (actionType) {
      case 'TOAST':
        store.dispatch(handlePusherResponse({ [event_name]: contentType }))
        CustomToast(content, toastOptions, messageDesc ? messageDesc : '')
        break
      case 'DOWNLOAD':
        store.dispatch(handlePusherResponse({ [event_name]: contentType }))
        await downloadFile(url)
        CustomToast(content, toastOptions, messageDesc ? messageDesc : '')
        break
      case 'INFO': 
        store.dispatch(handlePusherResponse({ [event_name]: contentType }))
        CustomToast(content, toastOptions , messageDesc ? messageDesc : '')
        break
      case 'DATA': 
        store.dispatch(handlePusherResponse({ [event_name]: selectedAction }))
        break
      default:
      }
    })
    break
  default:
    // do nothing
  }

  // closing connection if this is the last response
  if (is_last_response) {
    disconnectFromPusher()
  }
}

export const customStylesAsyncpaginate = {
  control: (provided) => ({
    ...provided,
    width: '100%',
    borderColor: '#d8d6de',
    minHeight: '45px',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#5468FA',
      cursor: 'pointer'
    }
  }),
  valueContainer: (provided) => ({
    ...provided,
    overflow: 'visible',
    width: '100%'
  }),
  option: provided => ({
    ...provided,
    cursor: 'pointer'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    position: 'absolute',
    top: state.hasValue || state.selectProps.inputValue ? '-65%' : '15%',
    transition: 'top 0.1s, font-size 0.1s',
    fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
    backgroundColor: state.isDisabled ? 'transparent' : 'white'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    paddingRight: 0
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: 'lightgray',
    paddingLeft: 0,
    marginInlineEnd: '10px',
    width: '20px',
    '&:hover': {
      color: 'gray'
    }
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  menu: (provided) => ({ ...provided, zIndex: 9999, color: '#222222' }),
  menuPortal: (base) => ({ ...base, zIndex: 999999 })
}

export const printMultipleAwb = (awbUrls) => {
  awbUrls = awbUrls.split(',')
  let popupWindow
  try {
    for (const url of awbUrls) {
      popupWindow = window.open(url)
    }
    popupWindow.focus()
  } catch (e) {
    console.log('pupupBlocked', 'testing', e)
    store.dispatch(handlePupupBlocked({ isPopupBlocked: true }))

  }

}

const hideGlobalHubForTabs = ({ location }) => {
  
  const isInculde = Object.values(URLS_TO_INCULDE_FROM_GLOBAL_HUB_SCOPE).find((url) => location.pathname.includes(url))
  if (!isInculde) return false
  const params = new URLSearchParams(location.search)
  const currentTab = params.get('currentTab')
  const isContainAll = currentTab === 'all'
  return isContainAll

}

export const shouldNotDisplayGlobalHubDropdown = ({location}) => {
  const isHome = location.pathname === '/'
  const shouldHide = (!!URLS_TO_EXCLUDE_FROM_GLOBAL_HUB_SCOPE.find((url) => location.pathname.includes(url)) || hideGlobalHubForTabs({location}))
  if (!ability.can(abilityMap.hub.view.action, abilityMap.hub.view.resource)) return true
  return isHome || shouldHide
}

export const shouldScaleWindowSize = ({location}) => {
  const shouldScale = (!!URL_TO_SCALE_WINDOW_SIZE.find((url) => location.pathname.includes(url)))
  return shouldScale
}

export const basicSelectStyles = {
  control: provided => {
    return {
      ...provided,
      width: '100%',
      borderColor: '#d8d6de',
      minHeight: '45px',
      borderRadius: '8px',
      color: '#666',
      boxShadow: 'none',
      fontSize: '12px',
      '&:hover': {
        borderColor: '#5468FA'
      },
      cursor: 'pointer'
    }
  },
  valueContainer: provided => ({
    ...provided,
    overflow: 'visible',
    width: '100%'
  }),
  option: provided => ({
    ...provided,
    cursor: 'pointer',
    color: '#222'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    position: 'absolute',
    color: '#666',
    top: state.hasValue || state.selectProps.inputValue ? '-50%' : '15%',
    transition: 'top 0.1s, font-size 0.1s',
    fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
    backgroundColor: state.isDisabled ? 'transparent' : 'white'
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingRight: 0
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingLeft: 0,
    marginInlineEnd: '5px',
    width: '25px',
    color: '#666',
    '&:hover': {
      color: '#666'
    }
  }),
  indicatorContainer: provided => ({
    ...provided,
    padding: '2px',
    border: '1px solid red !important',
    backgroundColor: 'red'
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none'
  })
}
// currency options 
export const currencyOption = [
  { label: 'SAR', value: 'SAR', name: 'Saudi Riyal' }, {
    label: 'USD', value: 'USD', name: 'US Dollar'
  }
]

export const currencyOptionHub = [
  { label: 'SAR (Saudi Riyal)', value: 'SAR', name: 'Saudi Riyal' }, {
    label: 'USD (US Dollar)', value: 'USD', name: 'US Dollar'
  }
]

export const lottieLazyLoader = (lottieImportAnimation, loop = true) => {
  return function LottieComponent() {
    const animationRef = useRef(null)
    const animationInstance = useRef(null)

    useEffect(() => {
      const loadAnimation = async () => {
        try {
          const lottieAnimationModule = await retry(lottieImportAnimation)
          const lottieAnimation = { ...lottieAnimationModule }
          const lottie = await retry(() => import('lottie-web'))
          animationInstance.current = lottie.loadAnimation({
            container: animationRef.current,
            animationData: lottieAnimation,
            loop,
            autoplay: true
          })
        } catch (error) {
          console.error('Failed to load animation in lazy retries and refresh:', error)
        }
      }

      loadAnimation()

      return () => {
        if (animationInstance.current) {
          animationInstance.current.destroy()
        }
      }
    }, [])

    return <div ref={animationRef} />
  }
}

export const getApiUrl = (url, params) => {
  for (const key in params) {
    url = url.replace(`:${key}`, params[key])
  }
  return url
}


// This function serializes an object into a query string by joining its key-value pairs with '&' separators.
export const paramsSerializer = (params) => {
  const parts = []
  for (const key in params) {
    if (Array.isArray(params[key])) {
      for (const value of params[key]) {
        if (value || typeof value === 'boolean') {
          parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        }
      }
    } else {
      if (params[key] || typeof params[key] === 'boolean') {
        parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      }
    }
  }
  const searchParams = parts.join('&')
  return searchParams
}

export const QueryParamsSerializerWithBrackets = (params) => {
  const queryString = []

  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const value = params[key]

      if (value !== null && value !== undefined) {
        if (Array.isArray(value) && value.length > 0) {
          for (const item of value) {
            queryString.push(`${encodeURIComponent(key)}[]=${encodeURIComponent(item)}`)
          }
        } else if (!Array.isArray(value)) {
          queryString.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        }
      }
    }
  }
  return queryString.join('&')
}

export const isFileTypeAndSizeValid = (selectedFile, fileTypes, maxFileSizeAllowed) => {
  if (!maxFileSizeAllowed) maxFileSizeAllowed = MAX_FILE_SIZE_ALLOWED_IN_BYTES
  if (selectedFile && selectedFile.size > maxFileSizeAllowed) {
    CustomToast(`Please upload a file of less then ${maxFileSizeAllowed / ONE_MB_IN_BYTES} Mb.`, { my_type: 'error', audioRequired: false })
    return false
  }
  if (selectedFile && !fileTypes.includes(selectedFile.type)) {
    CustomToast('Please upload a file in a supported format.', { my_type: 'error', audioRequired: false })
    return false
  }
  return true
}

export const formattedLongDate = (date) => {
  const newDate = new Date(date)
  // , hour: 'numeric', minute: 'numeric', hour12: true
  const dateOptions = { month: 'long', day: 'numeric', year: 'numeric' }
  const formattedDate = newDate?.toLocaleString('en-US', dateOptions)
  return formattedDate
}

export const receiveNotificationsFromServiceWorker = () => {

  let newOrderToastId = ''
  let messageCount = 0
  let timeOutId = ''
  let audio
  let refreshTabTimeOutId = null
  let latestMessageConfig = {}
  let soundIntervalId = null
  const defaultSoundDuration = 120000

  const destroyCustomToast = () => {
    if (timeOutId) clearTimeout(timeOutId)
    if (soundIntervalId) clearTimeout(soundIntervalId)
    toast.dismiss(newOrderToastId)
    messageCount = 0
    newOrderToastId = ''
  }

  navigator.serviceWorker.addEventListener('message', event => {

    const selectedGlobalHubId = JSON.parse(localStorage.getItem('selectedGlobalHubId'))

    const { is_meta_exist, messages, meta } = event.data.payload.data.notification
    const notification_name = is_meta_exist && meta.notification_name
    const hub_id = is_meta_exist && meta.hub_id

    const isNotificationVisible = ability.can(abilityMap.order.view.action, abilityMap.order.view.resource) && (selectedGlobalHubId === hub_id)

    const currentURL = window.location.href
    const url = new URL(currentURL)
    const route = url.pathname
    const queryParams = Object.fromEntries(url.searchParams.entries())
    const isTriggerRefresh = route.endsWith('/sales/live-orders') && (queryParams.currentTab === 'new_orders' || queryParams.currentTab === undefined)

    console.log({ isNotificationVisible, isTriggerRefresh, notification_name })

    if (soundIntervalId) clearInterval(soundIntervalId)
    switch (notification_name) {
    case 'new-order-notification':
      if (isNotificationVisible) {
        messageCount = messageCount + 1
        messages.forEach((message) => {
          latestMessageConfig = message.config
          const latestMessageSoundDuration = latestMessageConfig.sound_duration ? (latestMessageConfig.sound_duration * 1000) : defaultSoundDuration
          newOrderToastId = CustomToast(messageCount > 1 ? `${messageCount} New orders received` : 'New order received', {
            id: newOrderToastId ? newOrderToastId : undefined,
            my_type: message.message_type,
            duration: latestMessageConfig.play_continuously ? Infinity : latestMessageSoundDuration,
            handleCloseToast: destroyCustomToast
          })

          if (latestMessageConfig.play_sound) {
            if (audio && !audio.paused) {
              audio.pause()
            }
            audio = new Audio('/assets/sound/new_order_notification.mp3')
            audio.play().catch(error => {
              console.error('Failed to play audio:', error)
            })
          }
          if (isTriggerRefresh) {
            clearTimeout(refreshTabTimeOutId)
            refreshTabTimeOutId = setTimeout(() => {
              queryClient.invalidateQueries({ queryKey: [getOrdersApi.apiKey] })
            }, 1000)
          }
        })  
          
        if (latestMessageConfig.play_sound && isNotificationVisible) {
          let currentTime = 0
          soundIntervalId = setInterval(() => {
            audio.currentTime = 0
            audio.play().catch(error => {
              console.error('Failed to play audio:', error)
            })
            currentTime += 3
            if (!latestMessageConfig.play_continuously) {          
              if (currentTime >= latestMessageConfig.sound_duration) {
                clearInterval(soundIntervalId)
              }
            }
          }, 3000)
        }

        if (timeOutId) {
          clearTimeout(timeOutId)
        }
        if (!latestMessageConfig.play_continuously && latestMessageConfig.sound_duration) {
          timeOutId = setTimeout(() => {
            destroyCustomToast()
          }, latestMessageConfig.sound_duration * 1000)
        }
      }
      break
    default:
    }
  })
}

export const notificationPermissionChecker = async ({ user, beamManager }) => {
  try {
    const notificationPermissionStatus = await checkNotificationPermission();
    if (notificationPermissionStatus.isAllowed) {
      try {
        const response = await axiosInstance.get('/api/v1/oms/configurations/notification');
        const isEnabled = response.data?.data[0]?.configuration_values?.enabled;
        // configuration_values will be null when the user has not set up the settings at least once.
        await beamManager.registerServiceWorker({ tenant_id: user.tenant.id, isEnabled });
        receiveNotificationsFromServiceWorker();
      } catch (error) {
        console.error(error);
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export const checkNotificationPermission = () => {
  return new Promise((resolve, reject) => {
    if (!('Notification' in window)) {
      // Browser does not support notifications
      reject('Browser does not support notifications')
    }

    if (Notification.permission === 'granted') {
      // Notification permission has been granted
      resolve({ isAllowed: true, msg: 'Notification permission has been granted' })
    } else if (Notification.permission === 'denied') {
      // Notification permission has been denied
      resolve({ isAllowed: false, msg: 'Notification permission has been denied' })
    } else {
      // Notification permission has not been requested yet
      // const notification = new Notification("Notification permission request")
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          console.log('Notification permission has been granted')
          resolve({ isAllowed: true, msg: 'Notification permission has been granted' })
        } else if (permission === 'denied') {
          console.log('Notification permission has been denied')
          resolve({ isAllowed: false, msg: 'Notification permission has been denied' })
        } else {
          resolve({ isAllowed: false, msg: 'Notification permission has not been granted nor denied' })
        }
      })
    }
  })
}


export const commaSeparateIndian = (num) => {
  if (isNaN(num)) return null

  // Convert number to string and split into integer and decimal parts
  const [integerPart, decimalPart] = num.toString().split('.')

  // Comma separate the integer part according to Indian numbering system
  const commaSeparatedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  // Combine integer part and decimal part (if any)
  const formattedNumber = decimalPart ? `${commaSeparatedInteger}.${decimalPart}` : commaSeparatedInteger

  return formattedNumber
}

export const commaSeparateInternational = (num, requireIntegerFormat = false ) => {
  if (isNaN(num)) return null

  if(requireIntegerFormat) {
    // Round the number to the nearest integer
    const roundedNum = Math.round(num);

    // Comma separate the integer part according to international numbering system
    const formattedNumber = roundedNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return formattedNumber
  } else{
    // Convert number to string and split into integer and decimal parts
    const [integerPart, decimalPart] = num.toString().split('.')

    // Comma separate the integer part according to international numbering system
    const commaSeparatedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    // Combine integer part and decimal part (if any)
    const formattedNumber = decimalPart ? `${commaSeparatedInteger}.${decimalPart}` : commaSeparatedInteger

    return formattedNumber
  }
}

// Format the date with more control over the AM/PM
export const formatDateAmPm = (dateStr) => {
  const dateObj = new Date(dateStr)
  const options = { year: 'numeric', month: 'long', day: 'numeric' }
  const dateFormatter = new Intl.DateTimeFormat('en-US', options)
  const datePart = dateFormatter.format(dateObj)

  let hours = dateObj.getHours()
  const minutes = dateObj.getMinutes().toString().padStart(2, '0')
  const amPm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'

  return `${datePart}, ${hours}:${minutes} ${amPm}`
}

export const customPermissionsWithChecks = [
  {
    customPermission: { action: 'view', subject: 'custom_app' },
    checkPermissions: [{ ...abilityMap.external_wms.view }, { ...abilityMap.sales_channel_app.view }, { ...abilityMap.reports_api.edit }]
  },
  {
    customPermission: { action: 'view', subject: 'analytics' },
    checkPermissions: [{ ...abilityMap.analytics_hub_dashboard.view }, { ...abilityMap.analytics_seller_dashboard.view }]
  }
]

export const insertCustomPermission = ({userPermissions, customPermissionsWithChecks}) => {
  // Function to check if a permission exists in a list of permissions
  const permissionExists = (permission, permissionsList) => {
    return permissionsList.some(p => p.action === permission.action && p.subject === permission.subject)
  }

  // Iterate through each custom permission with its corresponding check permissions
  customPermissionsWithChecks.forEach(item => {
    const { customPermission, checkPermissions } = item
    const mappedCheckPermissions = checkPermissions.map(check => ({
      action: check.action,
      subject: check.resource
    }))

    // Check if any of the check permissions exist in user permissions
    if (mappedCheckPermissions.some(check => permissionExists(check, userPermissions))) {
      // If the custom permission does not already exist in user permissions, add it
      if (!permissionExists(customPermission, userPermissions)) {
        userPermissions.push(customPermission)
      }
    }
  })

  return userPermissions
}

export const getNameInitials = (name) => {
  // Trim whitespace and split the name into words
  if (!name) {
    return ''; // Return empty if name is empty
  }
  
  const words = name.trim().split(/\s+/);

  // Check if there is at least one word
  if (words.length === 0) {
    return ''; // Return empty if name is empty
  }

  // If there's only one word
  if (words.length === 1) {
    // Return the first two characters or just one character if the word is shorter
    return words[0].substring(0, 2).toUpperCase();
  }

  // If there are two or more words, return the first letter of the first and second word
  return (words[0][0] + words[1][0]).toUpperCase();
}

export const pluralizeUnit = (value, unit) => value > 0 ? `${value} ${value > 1 ? unit + 's' : unit}` : '';

export const validateDecimals = (val, decimals = 2) => {
  if (val === '') return true // Allow empty string
  let value = val
  if (typeof value === 'number')  value = val.toString()
  const regex = new RegExp(`^-?\\d*(\\.\\d{0,${decimals}})?$`)
  return regex.test(value) 
}

export const validatePositiveNumber = (val) => {
  if (val === '') return true // Allow empty string
  const regex = /^\d+(\.\d*)?$/
  return regex.test(val)
}

export const SELECT_ACTIONS = {
  SET_VALUE: 'set-value',
  INPUT_CHANGE: 'input-change',
  INPUT_BLUR: 'input-blur',
  MENU_CLOSE: 'menu-close'
}

// Utility function to get the nested value by key path with null checks
export const  getNestedValue = (obj, keyPath) => {
  return keyPath.split('.').reduce((acc, key) => {
    return acc && acc[key] !== undefined ? acc[key] : null;
  }, obj);
}

// Generic function to remove duplicates and sort data based on a nested key
export const removeDuplicateItemsBasedOnKey = (array, keyPath, sortOrder) => {
  const uniqueRecords = new Set();
  
  // Filter out duplicates based on the key path
  const uniqueArray = array.filter(item => {
    const value = getNestedValue(item, keyPath);
    
    // If value is null or duplicate, skip it
    if (value === null || uniqueRecords.has(value)) {
      return false;
    }
    uniqueRecords.add(value);
    return true;
  });
  
  // Sort the array based on a nested key value
  if (sortOrder) {
    uniqueArray.sort((a, b) => {
      const valueA = getNestedValue(a, keyPath);
      const valueB = getNestedValue(b, keyPath);
  
      if (sortOrder === SORTING_ORDER.ASCENDING) {  // Ascending order
        return valueA > valueB ? 1 : -1;
      } else if (sortOrder === SORTING_ORDER.DESCENDING) {  // Descending order
        return valueA < valueB ? 1 : -1;
      } else {
        return 0;
      }
    });
  }

  return uniqueArray;
}
export const getFiltersBasedOnOmit = (filters) => {
  return Object.values(filters).filter(filter => !filter.omit);
}

export const validateNumber = (e, revertValue) => {
  const value = e.target.value;
  if (!value) {
    return true;
  }
  if (validateDecimals(value) && validatePositiveNumber(value)) return true;
  e.target.value = revertValue();
  return false;
};
/**
 * Generates default additional parameters for API requests.
 *
 * @param {string} search - The search query string.
 * @param {Object} additional - Existing additional parameters.
 * @param {Object} options - Configuration options.
 * @param {Function} options.resultModifier - A function to modify the resulting additional parameters.
 * @returns {Object} - The updated additional parameters object.
 */
const getDefaultAdditional = (search, additional, { resultModifier = (result) => result }) => {
  const newAdditional = {
    ...additional,
    params: {
      search_query: search || undefined,
      search_column: search ? 'name' : undefined,
      ...(additional.params || {}),
      page: (additional.params?.page || 0) + 1,
    },
  }
  return resultModifier(newAdditional)
}

/**
 * Creates a fetcher utility for options with additional configurations.
 *
 * @param {Object} options - Configuration for the fetcher.
 * @param {Function} options.apiFn - Function to make the API request.
 * @param {string} [options.apiUrl=''] - API URL for requests.
 * @param {Function} [options.select=(data) => data] - Function to map or transform data.
 * @param {Function} [options.hasMore=(data) => data.meta?.current_page < data.meta?.last_page] - Function to determine if more data is available.
 * @param {Function} [options.additionalHandler=getDefaultAdditional] - Handler for generating additional parameters.
 * @param {Object} [options.additional={}] - Default additional parameters for requests.
 * @param {Function} options.getFetcherProps - Function to retrieve custom fetcher properties.
 * @returns {Object} - The fetcher utility with response handling.
 */
export const getOptionsFetcher = ({
  apiFn,
  apiUrl: apiUrlProp = '',
  select = (data) => data,
  hasMore: hasMoreFromProp = (data) => {
    return (data.meta && data.meta.current_page < data.meta.last_page) || false
  },
  additionalHandler = getDefaultAdditional,
  additional: defaultAdditional = {},
  getFetcherProps,
}) => {
  const fetcher = typeof apiFn === 'function' ? apiFn : axiosInstance.get

  const handleResponse = (responseData, additional, search) => {
    return {
      options: select(responseData, {additional, search}) || [],
      hasMore: hasMoreFromProp(responseData, additional),
    }
  }

  const loadOptions = async (search, loadedOptions, prevAdditional = defaultAdditional) => {
    const apiUrl = typeof apiUrlProp === 'function' ? apiUrlProp(prevAdditional) : apiUrlProp

    let additional
    try {
      additional = additionalHandler(search, prevAdditional, {})
      const fetcherParams = getFetcherProps ? getFetcherProps(search, additional) : [additional]

      const response = await fetcher(apiUrl, ...fetcherParams)

      const { options, hasMore } = handleResponse(response.data, additional, search)
      
      return {
        options,
        hasMore,
        additional,
      }
    } catch (error) {
      console.warn('Failed to fetch options:', error)
      return {
        options: [],
        hasMore: false,
        additional,
      }
    }
  }

  return loadOptions
}

export const onSkuImgError = (currentTarget) => {
  currentTarget.onerror = null
  currentTarget.src = noSkuImage
}

export const formattedLongDateWithTime = (date) => {
  const issuedDate = new Date(date);
  const dateOptions = { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
  const formattedDate = issuedDate?.toLocaleString('en-US', dateOptions);

  return formattedDate;
};

export const captureManualError = (error) => {
  import('@sentry/react')
    .then(module => module.captureException(error))
    .catch(err => console.error('Error capturing manual error:', err))
}

export const sentryInitAsync = () => {
  // import the SentryUtility class
  import('/src/utility/Sentry')
    .then(module => {
      module.default.init()
    })
    .catch(err => {
      console.warn('Could not initialize Sentry', err)
    })
}

export const getRootState = () => {
  return store.getState();
};

export const getDynamicPathParams = () => {
  const selectedGlobalHubId = store.getState().auth.selectedGlobalHubId;
  return {
    ':hubId': selectedGlobalHubId,
    // Add more dynamic parameters here as needed
  };
  
}