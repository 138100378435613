import Button from '@src/@core/components/ui/button'
import { Error500 } from '@src/assets/data/assets'
import useThemeSetup from '@src/utility/hooks/useThemeSetup'
import { captureManualError } from '@src/utility/Utils'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useRouteError } from 'react-router-dom'

const FallBack = (props) => {
  const error = useRouteError()
  captureManualError(error)

  const {handleRetryFunc, message} = props
  console.log({ props, error })
  const { t } = useTranslation()

  const domainData = useSelector(store => store.auth.domainData)
  const userData = useSelector(state => state.auth.userData)

  useThemeSetup(userData || domainData)
  const handleRetry = () => {
    if (handleRetryFunc) {
      handleRetryFunc()
    } else {
      window.location.reload()
    }
  }
  return (
    <div className="home-page-on-boot-failed flex-center-center flex-column">
      <div className="fatal-img">
        <Error500 primary='var(--bs-primary)' width={250} height={250}/>
      </div>
      <div className="main-heading mb-1 ">{t('Oops! Something went wrong.')}</div>
      <div className="ms-0 my-1 text-dark">
        {message || t('Please refresh the page or try again later.')}
      </div>
      <div className="mt-1">
        <Button onClick={handleRetry}>
          {t('Retry')}
        </Button>
      </div>
    </div>
  )
}

export default FallBack