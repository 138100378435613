import { nanoid } from '@reduxjs/toolkit';
import CheckBox from '../../new-checkbox';
import { SELECT_ALL_ROWS } from '../globalTable.constant';
const TableCheckbox = ({isPartial, ...rest}) => {
  const { name, checked, columnName } = rest
  
  const generateRandomId = () => {
    const uniqueIdentifier = nanoid();
    return `id-${uniqueIdentifier}`;
  }

  const determineCheckedState = () => {
    if (name === SELECT_ALL_ROWS.value) {
      return isPartial || checked
    }
    return checked
  }
  const determinePartialCheckedState = () => {
    return name === SELECT_ALL_ROWS.value ? isPartial : undefined
  }

  return (
    <CheckBox 
      {...rest} 
      id={columnName || generateRandomId()}
      checked={determineCheckedState()} 
      name={name}
      partialChecked={determinePartialCheckedState()}
    />
  )
}

export default TableCheckbox;