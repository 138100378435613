import Button from '@src/@core/components/ui/button'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import CustomDropdownOption from '@src/@core/components/ui/dropdown/customDropdownOption'
import FloatingDropDown from '@src/@core/components/ui/floating-dropdown'
import InputField from '@src/@core/components/ui/input-field'
import SingleDateFilter from '@src/@core/components/ui/single-date-filter'
import TimePicker from '@src/@core/components/ui/time-picker/TimePicker'
import { loadVillagesConfig } from '@src/app.apis'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import { FrameLogo } from '@src/assets/data/assets'
import warningIcon from '@src/assets/images/icons/Warning.svg'
import mapping from '@src/assets/images/svg/mapping.svg'
import { axiosInstance } from '@src/network/AxiosInstance'
import { getOptionsFetcher } from '@src/utility/Utils'
import { AbilityContext } from '@src/utility/context/Can'
import { useRTL } from '@src/utility/hooks/useRTL'
import { shippingPartnerMethods as getShippingPartnerMethods } from '@src/views/shipping/store'
import { useMutation } from '@tanstack/react-query'
import classNames from 'classnames'
import { format, parse } from 'date-fns'
import { useContext, useEffect, useState } from 'react'
import { Check, Edit2, Info, X } from 'react-feather'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { components } from 'react-select'
import { UncontrolledTooltip } from 'reactstrap'
import { CREATE_SHIPMENT_STATUS } from '../constant/orders.constants'
import { loadCityMappingsConfig, loadNearbyPointsConfig } from '../sales.apis'
import { SHIPPING_PARTNERS_TAG_NAMES } from '../sales.constant'
import PackageDetailsSideSheet from './PackageDetailsSideSheet'
import CityMappingConfirmationPopUp from './cityMappingConfirmationPopUp'
import './styles.scss'

const CustomDropdownOptionForNearbyPoints = (props) => {
  useTranslation()
  const {data: {label, color, distance, value, address}} = props
  const labelStyle = {color: color || 'text-dark', background: color ? `${color}1A` : 'transparent'}
  const { Option } = components
  return (
    <Option key={value} {...props}>
      <div className="flex-center-between" title={label}>
        <div className='d-flex flex-column gap-4px'>
          <div className={classNames('cursor-pointer text-truncate txt-sub-md', { 'rounded px-4px py-2px': color })} style={labelStyle}>{label}</div>
          <div className='txt-asst-rg'>
            {
              `${address.street && `${address.street},`}
              ${address.city && `${address.city},`}
              ${address.district && `${address.district},`}
              ${address.postal_code && `${address.postal_code},`}
              ${address.country}
          ` }</div>
        </div>
        <div className='cursor-pointer shadow-1 align-self-start'>{`${distance/1000} KM` }</div>
      </div>
    </Option>
  )
}

const ShippingPartner = (props) => {

  const { t } = useTranslation()
  const { register, errors, setValue, control, clearErrors, watch, createShipmentStatus, isCourierPartnerForShipping, newConfigForCourierPartner, shippingPartnerOptions, configForParcelShops, packageData, setPackageData, shippingDetailsData, isReverseShipment, shippingPartnersResponseForReturn} = props
  const { data: shippingPartnerMethods } = useSelector(state => state.shipping.shippingPartnerMethods)
  const getPrioritisedShippingPartnersResonse = useSelector((state) => state.sales.getPrioritisedShippingPartnersRes)
  const [rtl] = useRTL()

  const dispatch = useDispatch()
  const mappedShippingPartnerCities = watch('shipping_partner')?.shipping_partner.mapped_shipping_partner_cities
  const cityMappingDetails = watch('shipping_partner')?.shipping_partner.city_mapping_details
  
  const shouldFetchDimensions = watch('shipping_partner')?.shipping_partner.fetch_dimensions
  const showOptInsurance = watch('shipping_partner')?.shipping_partner.details?.show_opt_insurance
  const isFetch_multi_dimensions = watch('shipping_partner')?.shipping_partner.details?.fetch_multi_dimensions
  const show_delivery_slot = watch('shipping_partner')?.shipping_partner.details?.show_delivery_slot 
  const showNearbyPoint = watch('shipping_partner')?.shipping_partner.details?.delivery_details?.get_nearby_points?.forward 
  const timeSlots = shippingPartnerMethods?.[0]?.details?.slots

  const ability = useContext(AbilityContext)
  const loadNearbyPointsOptions = getOptionsFetcher(loadNearbyPointsConfig)
  const loadCityMappingsOptions = getOptionsFetcher(loadCityMappingsConfig)

  // only visible if both pickup and drop cities are present and shouldFetchVillages is true.
  const shouldFetchVillages = watch('shipping_partner')?.shipping_partner.fetch_villages && !(mappedShippingPartnerCities?.pickup_city && mappedShippingPartnerCities?.drop_city)

  const isCourierPartnerVisible = createShipmentStatus === CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_SELECTED && isCourierPartnerForShipping

  const isDimensionsVisible = createShipmentStatus === CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_SELECTED && shouldFetchDimensions
  const isVillagesDropdownVisible = createShipmentStatus === CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_SELECTED && shouldFetchVillages
  const isCityMappingRequired = watch('shipping_partner')?.shipping_partner.details?.is_city_mapping_required
  const dropCity = watch('shipping_partner')?.shipping_partner.city_mapping_details?.drop_city
  const dropCityId = watch('shipping_partner')?.shipping_partner.city_mapping_details?.drop_city_mapping_id
  const pickupCity = watch('shipping_partner')?.shipping_partner.city_mapping_details?.pickup_city
  const pickupCityId = watch('shipping_partner')?.shipping_partner.city_mapping_details?.pickup_city_mapping_id
  const relevantCity = isReverseShipment ? pickupCity : dropCity
  const relevantCityId = isReverseShipment ? pickupCityId : dropCityId
  const isMappedCityApproved = watch('shipping_partner')?.shipping_partner.city_mapping_details?.is_city_approved
  const hasCityMappingPermission = ability.can(abilityMap.tenant_city_mapping.add.action, abilityMap.tenant_city_mapping.add.resource)
  const numberOfBoxes = watch('number_of_boxes') || watch('carton_quantity')

  const isParcelShopDropdownVisible = watch('shipping_partner')?.shipping_partner.details?.fetch_parcel_shops
  const isShippingReferenceVisible = watch('shipping_partner')?.shipping_partner?.show_reference_field

  const serviceTypes = watch('shipping_partner')?.shipping_partner.details?.service_types
  const productType = isReverseShipment ? watch('shipping_partner')?.shipping_partner?.product_type?.reverse :  watch('shipping_partner')?.shipping_partner?.product_type?.forward
  const productTypeOptions = productType?.value
  const { isCityEditing, setIsCityEditing } = props
    
  const [isMappingOpen, setIsMappingOpen] = useState(false)
  const [isPackageDetailsOpen, setIsPackageDetailsOpen] = useState(false)
  const [isCustom, setIsCustom] = useState({length:false, breadth:false, height:false})
  const [noDataMappedCityMsg, setNoDataMappedCityMsg] = useState('')

  const OPT_INSURANCE_OPTIONS = [{ label: 'Yes', value: true }, { label: 'No', value: false }]

  const handleNodataMappedCityMsg = (msg) => {
    setNoDataMappedCityMsg(msg)
  }
  
  const loadVillagesOptions = getOptionsFetcher(loadVillagesConfig)
  const villageValidate = () => {
    if (!(watch('to_village')?.value)) {
      return 'To Village field is required'
    } else if (watch('from_village')?.value === watch('to_village')?.value) {
      return 'source and destination villages cannot be same.'
    } 
    return undefined
  }

  function getEntityValueByKey(actionValue, selectedShippingAccount, searchKey) {

    // Check if the actionValue is an array
    if (!Array.isArray(actionValue)) {
      return null;
    }

    // Loop through each entry in the actionValue array
    for (const entry of actionValue) {
      // Find the object with the Key "id" and check if the Value matches selectedShippingAccount.value
      const idObject = entry.find(item => item.Key === 'id');
  
      if (idObject && idObject.Value == selectedShippingAccount.value) {
        // Find the object with the Key "types"
        const typesObject = entry.find(item => item.Key === 'types');
        if (typesObject && typesObject.Value.length > 0) {
          // Access the first array in the "types" array (as per your structure)
          const typeDetails = typesObject.Value[0];
  
          // Find the object where the "key" entry matches the searchKey
          const matchingTypeObject = typeDetails.find(typeItem => typeItem.Key === 'key' && typeItem.Value === searchKey);
  
          // If found, return the corresponding "value"
          if (matchingTypeObject) {
            const valueObject = typeDetails.find(typeItem => typeItem.Key === 'value');
            const labelObject = typeDetails.find(typeItem => typeItem.Key === 'label');
            return valueObject && labelObject ? { value: valueObject.Value, label: labelObject.Value } : null;
          }
        }
      }
    }
  
    // Return null if no matching key or value is found
    return null;
  
  }


  const handleChangeShippingAccount = (shippingAccount) => {
    setValue('courier_partner', null)
    if (isReverseShipment) {
      setValue('mapped_city', shippingAccount.city_mapping_details?.pickup_city_mapping_id ? {label:shippingAccount.city_mapping_details.pickup_city, value: shippingAccount.city_mapping_details?.pickup_city_mapping_id} : null)
    } else {
      setValue('mapped_city', shippingAccount.city_mapping_details?.drop_city_mapping_id ? {label:shippingAccount.city_mapping_details.drop_city, value: shippingAccount.city_mapping_details?.drop_city_mapping_id} : null)
    }
    const serviceTypes = shippingAccount?.shipping_partner?.details?.service_types
    const actionValue = isReverseShipment ? shippingPartnersResponseForReturn?.data?.data?.action_value : getPrioritisedShippingPartnersResonse?.data?.data?.action_value
    const productType = shippingAccount?.shipping_partner?.product_type[isReverseShipment ? 'reverse' : 'forward']
    const productTypeOptions = productType?.value
    const detailsProductType = shippingAccount?.details?.[productType?.key_name]
    const defaultDetailsProductType = productTypeOptions?.find(item => item.value === detailsProductType)
    const defaultServiceType = serviceTypes?.find(item => item.value === shippingAccount?.details?.service_type)
    
    const returnDefaultServiceType = getEntityValueByKey(actionValue, shippingAccount, 'service_type')
    setValue('service_type', (isReverseShipment ? returnDefaultServiceType : defaultServiceType) || null)
    setValue('opt_insurance', shippingAccount?.details?.opt_insurance || null)
   
    if (productType?.key_name && productTypeOptions?.length) {
      const defaultProductType = actionValue?.product_type?.find(item => item.id === shippingAccount.id)
      const returnDefaultProductType = getEntityValueByKey(actionValue, shippingAccount, productType?.key_name)
      const defaultValue = (isReverseShipment ? returnDefaultProductType : defaultProductType?.value) || defaultDetailsProductType || null
      setValue(productType.key_name, defaultValue)
    }
    clearErrors(['remarks', 'height', 'breadth', 'length'])
    if (isCityEditing)setIsCityEditing(false)
    if (errors.mapped_city) clearErrors('mapped_city')
  }

  const distributeWeight = () => {

    const totalWeight = parseFloat(watch('weight'))
    const numberOfBoxes = parseFloat(watch('number_of_boxes') || watch('carton_quantity'))
        
    const baseWeight = totalWeight / numberOfBoxes
    const weights = Array(numberOfBoxes).fill(baseWeight.toFixed(2))
        
    return weights.map((weight, index) => ({
      id: index,
      weight: {
        value: parseFloat(weight),
        uom: 'kg'
      }
    }))
            
  }
    
    
  const handleUpdatePackageData = (e) => {
    const { name, value } = e.target
    let createdArray = packageData.length ? packageData : []
    createdArray = createdArray.map(item => ({
      ...item,
      dimensions: { ...item.dimensions, [name]: +value }
    }))
    
    setPackageData(createdArray)
  }

  const handleClearCustom = (e) => {
    const { name } = e.target
    let createdArray = packageData.length ? packageData : []
    if (name === 'width') {
      createdArray = createdArray.map(item => ({
        ...item,
        dimensions: { ...item.dimensions, [name]: '' }
      }))
      setValue('breadth', '')
    } else {
      createdArray = createdArray.map(item => ({
        ...item,
        dimensions: { ...item.dimensions, [name]: '' }
      }))
      setValue(name, '')
    }
    setPackageData(createdArray)
    setIsCustom(pre => ({...pre, [name]:false}))
  }
    
  // find default  slot 
  const timeToMinutes = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };
    
  const isTimeWithinRange = (currentMinutes, startMinutes, endMinutes) => {
    if (startMinutes <= endMinutes) {
      return currentMinutes >= startMinutes && currentMinutes <= endMinutes;
    } else {
      return currentMinutes >= startMinutes || currentMinutes <= endMinutes;
    }
  };
    
  const findSuitableSlot = (currentTime) => {
    const currentMinutes = timeToMinutes(currentTime);
    
    for (let slot of timeSlots) {
      let startMinutes = timeToMinutes(slot.start_time);
      let endMinutes = timeToMinutes(slot.end_time);
    
      if (isTimeWithinRange(currentMinutes, startMinutes, endMinutes)) {
        return slot; 
      }
    }
    
    return null; // No suitable slot found
  };

  function getTimeInTimeZone(timezone) {
    // Check if the timezone is provided; otherwise, use the browser's default timezone
    const options = {
      timeZone: timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    };
      
    // Format the time according to the timezone or fallback to the browser's time
    const timeString = new Intl.DateTimeFormat('en-US', options).format(new Date());
      
    return timeString;
  }
  
  useEffect(() => {
    if (shouldFetchDimensions || show_delivery_slot) {
      dispatch(getShippingPartnerMethods({shippingPartnerTag: watch('shipping_partner')?.id ? [watch('shipping_partner')?.id] : undefined}))
    }
  }, [shouldFetchDimensions, watch('shipping_partner'), show_delivery_slot])
  
  
  useEffect(() => {
    if (shippingPartnerMethods?.length) {
      const shippingPartner = shippingPartnerMethods[0]
      setValue('length', shippingPartner.details.length)
      setValue('breadth', shippingPartner.details.width)
      setValue('height', shippingPartner.details.height)
      setValue('opt_insurance',OPT_INSURANCE_OPTIONS.find(option => option.value === shippingPartner.details.opt_insurance) )
      if (show_delivery_slot && shippingDetailsData ) {
        setValue('delivery_start_time',shippingDetailsData.entity_data.slot?.start_time || shippingPartner.details.delivery_start_time)
        setValue('delivery_end_time', shippingDetailsData.entity_data.slot?.end_time || shippingPartner.details.delivery_end_time)
        const currentTime = getTimeInTimeZone(shippingDetailsData.entity_data.hub?.timezone)
        const suitableSlot = findSuitableSlot(currentTime);
        if (suitableSlot?.days || shippingDetailsData.entity_data.slot?.delivery_date) {

          const calculatedDate = new Date()
          calculatedDate.setDate(calculatedDate.getDate() + suitableSlot.days);
          const formatedDate = shippingDetailsData.entity_data.slot?.delivery_date ? format(parse(shippingDetailsData.entity_data.slot.delivery_date, 'ddMMyyyy', new Date()), 'dd-MM-yyyy') : format(calculatedDate, 'dd-MM-yyyy')
          setValue('delivery_date', { label: formatedDate, value: formatedDate })
        } else {
          setValue('delivery_date', '')
        }
      }
      let createdArray = packageData.length ? packageData : []
      createdArray = createdArray.map((item) => ({
        ...item,
        dimensions: {
          length: shippingPartner.details.length,
          breadth: shippingPartner.details.width,
          height: shippingPartner.details.height}
      }))
    
      setPackageData(() => createdArray)
    }
    return () => {
      setValue('length', '')
      setValue('breadth', '')
      setValue('height', '')
      setValue('delivery_start_time', '')
      setValue('delivery_end_time', '')
      setValue('delivery_date','')
    }
  }, [shippingPartnerMethods])
  useEffect(() => { 
    setPackageData(() => distributeWeight())
  }, [watch('number_of_box'), watch('weight')])

  const { mutate: addCityMapping, isPending } = useMutation({
    mutationKey: ['post-shipping-partner-city-mappings', watch('shipping_partner')?.shipping_partner.id, props?.omnifulCity],
    mutationFn: (body) => {
      return axiosInstance.post(`/api/v1/shipping/shipping_partners/${watch('shipping_partner')?.shipping_partner.id}/tenant_city_mappings`, body)
    },
    onSuccess: () => {
      setIsCityEditing(false)
      setValue('shipping_partner', {
        ...watch('shipping_partner'),
        shipping_partner: {
          ...watch('shipping_partner').shipping_partner,
          city_mapping_details: {
            ...watch('shipping_partner')?.shipping_partner.city_mapping_details,
            drop_city: !isReverseShipment ? watch('mapped_city')?.label : watch('shipping_partner')?.shipping_partner.city_mapping_details.drop_city,
            drop_city_mapping_id: !isReverseShipment ? watch('mapped_city')?.value : watch('shipping_partner')?.shipping_partner.city_mapping_details.drop_city_mapping_id,
            pickup_city: isReverseShipment ? watch('mapped_city')?.label : watch('shipping_partner')?.shipping_partner.city_mapping_details.pickup_city,
            pickup_city_mapping_id: isReverseShipment ? watch('mapped_city')?.value : watch('shipping_partner')?.shipping_partner.city_mapping_details.pickup_city_mapping_id
          }

        }

      })
    },
    enabled: watch('mapped_city')?.value && watch('shipping_partner')?.shipping_partner.id
  })

  const validateMaxReferenceLength = (val) => {
    if (val?.length > 20) {
      return 'Shipping Reference must be 20 characters or fewer.'
    } else {
      return undefined
    }
  }


  const { NoOptionsMessage } = components

  const NoOptionMessageComponent = (props) => { 
    return (
      <NoOptionsMessage {...props} >
        <div className='txt-sub-md'>{noDataMappedCityMsg}</div>
      </NoOptionsMessage>
    )
  }

  const renderMappedCity = () => <div className='w-100'>
    <div className='d-flex gap-12px w-100'>
      <div className='w-100'>
        <FloatingDropDown
          isAsync
          isRequired
          isClearable={watch('mapped_city')}
          name='mapped_city'
          value={watch('mapped_city')}
          title='Shipping Partner City'
          control={control}
          errors={errors}
          loadOptions={loadCityMappingsOptions}
          className='w-100'
          disabled={!ability.can(abilityMap.tenant_city_mapping.add.action, abilityMap.tenant_city_mapping.add.resource)}
          additional={{
            shippingPartnerId: watch('shipping_partner')?.shipping_partner.id,
            tenantMapped: false,
            omnifulCountryId: watch('country')?.value,
            handleNoDataMsg: handleNodataMappedCityMsg,
            locale: rtl?'ar':'en'
          }}
          cacheUniqs={[watch('shipping_partner')?.shipping_partner.id]}
          validationSchema={{
            pickup_city: { required: 'Pickup City is required' },
            mapped_city: {
              required: 'City is required'
            }
          }}
          width='100%'
          additionalComponents={{
            NoOptionsMessage: (props) => <NoOptionMessageComponent {...props} />
          }}
        />
      </div>
      {ability.can(abilityMap.tenant_city_mapping.add.action, abilityMap.tenant_city_mapping.add.resource) && <div className='d-flex gap-8px height-40px'>
        <button
          type='button'
          className='px-12px rounded-4px bg-success-light border-0'
          onClick={() => {
            if (!watch('mapped_city')) {
              CustomToast('Please select shipping partner city before proceeding.', { my_type: 'warning' })
              return
            }

            if (errors.mapped_city) {
              clearErrors('mapped_city')
            }

            if (!hasCityMappingPermission) {
              CustomToast('You are not authorized to map the city.', { my_type: 'warning' })
              return
            }
            setIsMappingOpen(true)
          }}
          disabled={isPending}
        ><Check className='text-success' size={18} /></button>
      </div>}
      {relevantCity && <div className='d-flex gap-8px height-40px'>
        <button
          type='button'
          className='px-12px rounded-4px bg-danger-light border-0'
          onClick={() => {
            if (errors.mapped_city) {
              clearErrors('mapped_city')
            }
            setIsCityEditing(false)
          }}
          disabled={isPending}
        ><X className='text-danger' size={18} /></button>
      </div>}
    </div>
  </div>
    
  const handlePackageDetailsSideSheet = () => {
    setIsPackageDetailsOpen(true)
  }
  return (
    <div className='shipping-partner-container'>
      <h6 className='txt-body-md mb-2'> {t('Shipping Partner Details')} </h6>
      <div className='shipping-partner-all-fields-wrapper'>
        {createShipmentStatus === CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_SELECTED && (
          <FloatingDropDown
            name='shipping_partner'
            control={control}
            isRequired
            errors={errors}
            value={watch('shipping_partner')}
            // defaultValue={shippingPartnerOptions[0]}
            options={shippingPartnerOptions}
            validationSchema={{ shipping_partner: { required: t('Shipping account is required') } }}
            title='Select Shipping Account'
            onChangeFunc={(shippingAccount) => {
              handleChangeShippingAccount(shippingAccount)
              setIsCustom({ length: false, breadth: false, height: false })
            }}
            additionalComponents={{ Option: CustomDropdownOption }}
          />
        )}
        {serviceTypes?.length > 0 && (
          <FloatingDropDown
            name='service_type'
            control={control}
            errors={errors}
            value={watch('service_type')}
            options={serviceTypes}
            title='Select Service Type'
            isClearable={true}
          />
        )}
        {productTypeOptions?.length > 0 && productType?.key_name && (
          <FloatingDropDown
            name={productType.key_name}
            control={control}
            errors={errors}
            value={watch(productType.key_name)}
            options={productTypeOptions}
            title={productType.display_name}
            isClearable={true}
          />
        )}
        {showOptInsurance && (
          <FloatingDropDown
            name='opt_insurance'
            control={control}
            errors={errors}
            value={watch('opt_insurance')}
            options={OPT_INSURANCE_OPTIONS}
            title='Insurance'
            isClearable={true}
          />
        )}
        {isCourierPartnerVisible && <FloatingDropDown {...newConfigForCourierPartner} cacheUniqs={[watch('mapped_city'), watch('shipping_partner')?.account_name]} />}

        {isParcelShopDropdownVisible && <FloatingDropDown {...configForParcelShops} />}

        {isShippingReferenceVisible && (
          <InputField
            errors={errors}
            {...register('shipping_reference', {
              pattern: {
                value: /^[0-9-]+$/,
                message: t('This field can only contain numerics and hyphens'),
              },
              validate: validateMaxReferenceLength,
            })}
            value={watch('shipping_reference')}
            label={t('Shipping Reference')}
          />
        )}
        {show_delivery_slot &&  shippingPartnerMethods?.[0] && (
          <div className='d-flex flex-column gap-2'>
            <Controller
              name='delivery_start_time'
              control={control}
              rules={{ required: 'This Field is Required' }}
              render={({ field }) => <TimePicker  {...field} label='Delivery Start Time'  error={errors.delivery_start_time?.message} placeHolder='Select a time' value={watch('delivery_start_time')} />}
            />
            <Controller
              name='delivery_end_time'
              control={control}
              rules={{
                required:'This Field is Required',
                validate: (value) => {
                  const startTime = watch('delivery_start_time');
                  const endTime = value;
                
                  // Check if delivery start time exists first
                  if (!startTime) {
                    return 'Select a delivery start time first';
                  }
                
                  // Compare times
                  if (parseInt(startTime.replace(':', '')) >= parseInt(endTime.replace(':', ''))) {
                    return 'Delivery end time must be after start time';
                  }
                
                  return true;
                },
              }}
              render={({ field }) => <TimePicker  {...field}  label='Delivery End Time' error={errors.delivery_end_time?.message}  placeHolder='Select a time'  value={watch('delivery_end_time')} />}
            />
            <Controller
              name='delivery_date'
              control={control}
              rules={{ required: 'This Field is Required' }}
              render={({ field }) => <SingleDateFilter label='Delivery  Date' placeholder='YYYY/MM/DD' error={errors.delivery_date?.message}   isClearlable isRequired {...field} />}
            />
          </div>
        )}
        {isDimensionsVisible && (
          <div className='dimensions-container d-flex gap-12px flex-column'>
            <div className='flex-start-start w-100 gap-12px'>
              {isFetch_multi_dimensions && numberOfBoxes > 1 && <div>
                <Info size={16} id={'package-tooltip'} />
                <UncontrolledTooltip placement='top' target={'package-tooltip'}>
                        These dimensions apply to all package dimensions.
                </UncontrolledTooltip>
              </div>}
              <div className='w-100'>
                {!isCustom.length ? (
                  <InputField
                    key='length'
                    name='length'
                    type='number'
                    errors={errors}
                    isRequired={watch('shipping_partner')?.shipping_partner?.details?.dimensions_required}
                    label='Length (in cm)'
                    {...register('length', {
                      required: watch('shipping_partner')?.shipping_partner?.details?.dimensions_required ? 'This field is required' : undefined,
                      max: {
                        value: 300,
                        message: 'Length can not be greater than 300',
                      },
                    })}
                    onChange={(e) => {
                      register('height').onChange(e)
                      if (isFetch_multi_dimensions) handleUpdatePackageData(e)
                    }}
                    width='100%'
                  />
                ) : (
                  <InputField label='Length (in cm)' name='length' onChange={handleClearCustom} isClearable defaultValue='Custom' width='100%' />
                )}
              </div>
              <div className='w-100'>
                {!isCustom.breadth ? (
                  <InputField
                    key='breadth'
                    name='breadth'
                    type='number'
                    errors={errors}
                    isRequired={watch('shipping_partner')?.shipping_partner?.details?.dimensions_required}
                    label='Width (in cm)'
                    {...register('breadth', {
                      required: watch('shipping_partner')?.shipping_partner?.details?.dimensions_required ? 'This field is required' : undefined,
                      max: {
                        value: 200,
                        message: 'Length can not be greater than 200',
                      },
                    })}
                    onChange={(e) => {
                      register('breadth').onChange(e)
                      if (isFetch_multi_dimensions) handleUpdatePackageData(e)
                    }}
                    width='100%'
                  />
                ) : (
                  <InputField label='Width (in cm)' name='breadth' onChange={handleClearCustom} isClearable defaultValue='Custom' width='100%' />
                )}
              </div>
              <div className='w-100'>
                {!isCustom.height ? (
                  <InputField
                    key='height'
                    name='height'
                    type='number'
                    errors={errors}
                    isRequired={watch('shipping_partner')?.shipping_partner?.details?.dimensions_required}
                    label='Height (in cm)'
                    {...register('height', {
                      required: watch('shipping_partner')?.shipping_partner?.details?.dimensions_required ? 'This field is required' : undefined,
                      max: {
                        value: 160,
                        message: 'Length can not be greater than 160',
                      },
                    })}
                    onChange={(e) => {
                      register('height').onChange(e)
                      if (isFetch_multi_dimensions) handleUpdatePackageData(e)
                    }}
                    width='100%'
                  />
                ) : (
                  <InputField label='Height (in cm)' name='height' onChange={handleClearCustom} isClearable defaultValue='Custom' width='100%' />
                )}
              </div>
            </div>
            <div className='d-flex justify-content-between align-items-center'>
              <div>
                {isFetch_multi_dimensions ? (
                  <span className='text-dark-6 txt-asst-rg d-flex gap-8px'>
                    <Info size={16} />
                    {t('Provide both weight and dimension. Bills based on higher value.')}
                  </span>
                ) : (
                  (() => {
                    if (
                      watch('shipping_partner')?.shipping_partner?.tag === SHIPPING_PARTNERS_TAG_NAMES.DHL_DOMESTIC ||
                        watch('shipping_partner')?.shipping_partner?.tag === SHIPPING_PARTNERS_TAG_NAMES.DHL_INTERNATIONAL
                    ) {
                      if (errors?.length?.message || errors?.breadth?.message || errors?.height?.message)
                        return (
                          <span className='text-danger txt-asst-rg d-flex gap-8px'>
                            <Info size={16} />
                            {t('Maximum allowed dimensions are 300x200x160 cm.')}
                          </span>
                        )
                      else
                        return (
                          <span className='text-dark-6 txt-asst-rg d-flex gap-8px'>
                            <Info size={16} />
                            {t('Provide both weight and dimension. Bills based on higher value.')}
                          </span>
                        )
                    }
                  })()
                )}
              </div>
              {isFetch_multi_dimensions && (
                <>
                  <Button icon={() => <FrameLogo />} onClick={handlePackageDetailsSideSheet} type='button' ofType='compressed' ofStyle='noBackground'>
                      Customise
                  </Button>
                  <PackageDetailsSideSheet
                    isSideSheetOpen={isPackageDetailsOpen}
                    handleCloseSideSheet={() => setIsPackageDetailsOpen(false)}
                    numberOfPackage={numberOfBoxes}
                    packageWeight={watch('weight')}
                    packageData={packageData}
                    setPackageData={setPackageData}
                    setValue={setValue}
                    setIsCustom={setIsCustom}
                  />
                </>
              )}
            </div>
          </div>
        )}

        {isCityMappingRequired && (
          <>
            <div>
              <div className='flex-start-between text-primary cursor-pointer txt-asst-md'>
                {/* it will always be in primary color(blue) */}
                <span className='txt-sub-md text-dark'>{isReverseShipment ? t('Pickup City Mapping') : t('Delivery City Mapping')}</span>
                {cityMappingDetails.is_city_approved || !cityMappingDetails.drop_city_mapping_id ? null : (
                  <>
                    <div id='city_mapped_history' className='flex-center-start gap-4px'>
                      <Info size={16} />
                      <span>{t('History')}</span>
                    </div>
                    <UncontrolledTooltip placement='top-start' target='city_mapped_history' offset={[0, 8]} innerClassName='mw-100'>
                      <div className='d-flex gap-4px'>
                        <span>{t('Last Updated By')}:</span>
                        <span>{cityMappingDetails.tenant_city_mapping_info.updated_by_user_name}</span>
                      </div>
                      <div className='d-flex gap-4px'>
                        <span>{t('Updated At')}:</span>
                        <span>{cityMappingDetails.tenant_city_mapping_info.updated_at}</span>
                      </div>
                    </UncontrolledTooltip>
                  </>
                )}
              </div>
              <div className='d-flex align-items-center gap-12px mt-12px'>
                <div className={classNames('w-50', { 'mb-20px': errors.mapped_city })} id='omniful_city'>
                  <InputField key='omniful_city' name='length' label={isReverseShipment ? 'Pickup City' : 'Destination City'} value={watch('city')?.label} disabled />
                </div>
                <UncontrolledTooltip placement='bottom' target='omniful_city' offset={[0, 5]}>
                  {watch('city')?.label}
                </UncontrolledTooltip>

                <div className={classNames({ 'mb-20px': errors.mapped_city })}>
                  <img src={mapping} alt='city_mapping' height='40px' width='40px' />
                </div>

                {relevantCity ? (
                  isCityEditing ? (
                    renderMappedCity()
                  ) : (
                    <div className='w-100 d-flex gap-12px'>
                      <div className='w-100' id='shipping_partner_city'>
                        <InputField key='omniful_city' name='length' label='Shipping Partner City' value={relevantCity} disabled className='flex-grow-1' />
                      </div>
                      <UncontrolledTooltip placement='bottom' target='shipping_partner_city'>
                        {relevantCity}
                      </UncontrolledTooltip>
                      {!isMappedCityApproved && (
                        <div className='d-flex height-40px'>
                          <button
                            type='button'
                            className='px-12px rounded-4px bg-primary-lighter border-0'
                            onClick={() => {
                              if (!hasCityMappingPermission) {
                                CustomToast('You are not authorized to map the city.', { my_type: 'warning' })
                              } else {
                                setValue('mapped_city', { label: relevantCity, value: relevantCityId })
                                setIsCityEditing(true)
                              }
                            }}
                            disabled={isPending}
                          >
                            <Edit2 className='text-primary' size={18} />
                          </button>
                        </div>
                      )}
                    </div>
                  )
                ) : (
                  renderMappedCity()
                )}
              </div>
              {!hasCityMappingPermission && !relevantCity && (
                <div className='d-flex align-items-center gap-12px bg-warning-light px-16px py-12px mt-12px rounded-4px'>
                  <div>
                    <img src={warningIcon} alt='warningIcon' height='24px' width='24px' />
                  </div>
                  <div className='text-dark txt-sub-rg'>{t('You are not authorized to map the city')}</div>
                </div>
              )}
            </div>
          </>
        )}
        {showNearbyPoint &&
          <div className='mt-24px'>
            <FloatingDropDown
              isAsync
              name='nearby_points_details'
              onMenuOpe
              control={control}
              errors={errors}
              value={watch('nearby_points_details')}
              // defaultValue={shippingPartnerOptions[0]}
              loadOptions={loadNearbyPointsOptions}
              validationSchema={{ nearby_points_details: { required:false } }}
              title='Select Nearby Point'
              onChangeFunc={(shippingAccount) => {
                handleChangeShippingAccount(shippingAccount)
              }}
              additional={{
                'details': {
                  'nearby_points_details': {
                    'lat': `${shippingDetailsData.entity_data.hub?.address?.latitude}`,
                    'long': `${shippingDetailsData.entity_data.hub?.address?.longitude}`
                  }
                },
                'account_id':  `${watch('shipping_partner')?.id}`,
                'shipping_partner_tag':  watch('shipping_partner')?.shipping_partner?.tag,
                'type': 'get_nearby_points'
              }}
              additionalComponents={{ Option: CustomDropdownOptionForNearbyPoints }}
              modifiedStyles={{
                menuList: (provided) => ({
                  ...provided,
                  maxHeight: '240px',
                }), 
              }}
            />
          </div>
        }
        <CityMappingConfirmationPopUp
          isOpen={isMappingOpen}
          setIsOpen={setIsMappingOpen}
          addCityMapping={addCityMapping}
          watch={watch}
          setIsCityEditing={setIsCityEditing}
          isCityEditing={isCityEditing}
          setValue={setValue}
        />
      </div>
      {/* While replacing getAsyncVillages with loadVillagesOptions, 
          testing was not done because backend support was not available at the time. 
          So, whenver backend support will be availaible, 
           make sure to testing is done once beforehand   */}
      {isVillagesDropdownVisible && (
        <div className='shipping-partner-all-fields-wrapper mt-2'>
          <FloatingDropDown
            isAsync
            isRequired
            name='from_village'
            title='From Village'
            control={control}
            errors={errors}
            loadOptions={loadVillagesOptions}
            validationSchema={{ from_village: { required: 'From Village is required' } }}
          />
          <FloatingDropDown
            isAsync
            isRequired
            name='to_village'
            title='To Village'
            control={control}
            errors={errors}
            loadOptions={loadVillagesOptions}
            validationSchema={{ to_village: { validate: villageValidate } }}
          />
        </div>
      )}
      <CityMappingConfirmationPopUp
        isOpen={isMappingOpen}
        setIsOpen={setIsMappingOpen}
        addCityMapping={addCityMapping}
        watch={watch}
        setIsCityEditing={setIsCityEditing}
        isCityEditing={isCityEditing}
        setValue={setValue}
      />
    </div>
  )
}

export default ShippingPartner