import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner';
import { AppDispatch, RootState } from '@src/redux/store';
import FallBack from '@src/views/home/Fallback';
import SidebarContainer from '@src/views/sales/picking-wave/pages/sidebarContainer/SidebarContainer';
import { ORDER_LOG_EVENT_BY } from '@src/views/sales/sales.constant';
import { clearOrderLogs, getOrderLogs, getPicklistsOfStatus } from '@src/views/sales/store/store';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LogItem from './components/log-item';
import OrderLogDetailsSidesheet from './components/order-log-details-sidesheet';
import './order-logs.scss';
import { iOrderLog, iOrderLogProps, iOrderLogsData, iPickListsOfStatus, iToggleOrderLogDetailsSideSheet, iWaveDetails } from './types';

/**
 * Main order logs component
 * Manages the display of all order log entries and their interactions
 * Handles wave details sidebar and log details side sheet
 * @component
 */
const OrderLogs: FC<iOrderLogProps> = ({ orderId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const {t} = useTranslation();

  const orderLogs = useSelector((store: RootState) => store.sales.orderLogs) as iOrderLogsData | null;
  const loading = useSelector((store: RootState) => store.sales.loading) as {
    getOrderLogs: boolean
  };
  const error = useSelector((store: RootState) => store.sales.error) as {
    getOrderLogs: boolean
  };
  const pickListsOfStatus = useSelector((store: RootState) => store.sales.picklistsOfStatus) as iPickListsOfStatus;
  const selectedPickListStatus = useSelector((store: RootState) => store.sales.selectedPicklistStatus);

  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [selectedWave, setSelectedWave] = useState<iWaveDetails | null>(null);
  const [isOrderLogDetailsSidesheetOpen, setIsOrderLogDetailsSidesheetOpen] = useState<boolean>(false);
  const [selectedOrderLog, setSelectedOrderLog] = useState<iOrderLog | null>(null);

  const toggleOrderLogDetailsSideSheet = ({ item, status }: iToggleOrderLogDetailsSideSheet) => {
    setSelectedOrderLog(item || null);
    setIsOrderLogDetailsSidesheetOpen(status);
  };

  const handleFetchOrderLogs = () => {
    // @ts-expect-error Fix Me
    dispatch(getOrderLogs(orderId));
  };

  const handleWaveDetails = (item: iOrderLog, index: number) => {
    if (item.event_updated_by_user_type === ORDER_LOG_EVENT_BY.WAVE.key && !pickListsOfStatus.loading) {
      setSelectedWave({ waveId: item.event_updated_by_id, index });
      dispatch(
        // @ts-expect-error Fix Me
        getPicklistsOfStatus({
          hub_id: orderLogs?.hub_id,
          waveId: item.event_updated_by_id,
        })
      );
    }
  };

  useEffect(() => {
    if (!orderLogs) {
      handleFetchOrderLogs();
    }
    return () => {
      dispatch(clearOrderLogs());
    };
  }, []);

  useEffect(() => {
    if (pickListsOfStatus.success && !isSidebarOpen) {
      setIsSidebarOpen(true);
      // @ts-expect-error TODO: Fix pickListsOfStatus data type
      const wave = pickListsOfStatus.data?.data?.[0]?.wave && {
        // @ts-expect-error TODO: Fix pickListsOfStatus data type
        name: pickListsOfStatus.data.data[0].wave.number,
        // @ts-expect-error TODO: Fix pickListsOfStatus data type
        time: pickListsOfStatus.data.data[0].wave.start_time,
      };
      if (wave) {
        setSelectedWave((prev) => (prev ? { ...prev, ...wave } : null));
      }
    }
  }, [pickListsOfStatus]);

  if (loading.getOrderLogs) {
    return <ComponentSpinner className='mt-5' />;
  }

  if (error.getOrderLogs) {
    return <FallBack handleRetryFunc={handleFetchOrderLogs} message={t('Please try again later')} />;
  }

  return orderLogs?.log ? (
    <>
      <div className='order-logs-main d-flex flex-column gap-16px'>
        {orderLogs.log.map((item, index) => (
          <LogItem
            key={index}
            item={item}
            index={index}
            totalLogs={orderLogs.log.length}
            onWaveClick={handleWaveDetails}
            isLoading={pickListsOfStatus.loading}
            selectedWaveIndex={selectedWave?.index}
            toggleOrderLogDetailsSideSheet={toggleOrderLogDetailsSideSheet}
          />
        ))}
      </div>

      <SidebarContainer
        is_sidebar_open={isSidebarOpen}
        set_is_sidebar_open={setIsSidebarOpen}
        waveDetails={{ wave: selectedWave }}
        hub_id={orderLogs?.hub_id}
        // @ts-expect-error define selectedPickListStatus type
        status={selectedPickListStatus?.value}
      />
      <OrderLogDetailsSidesheet isOpen={isOrderLogDetailsSidesheetOpen} toggleOrderLogDetailsSideSheet={toggleOrderLogDetailsSideSheet} preloadData={selectedOrderLog} />
    </>
  ) : null;
};

export default OrderLogs;
