import { ErrorState, NoMatchesFound, NoRecordFound } from '@src/assets/data/assets';
import { useTranslation } from 'react-i18next';
import ProgressLoader from '../../progressLoader/index';

const TableNoDataStateWhenSearchOrFilterApplied = () => {
  const {t} = useTranslation()
  return (
    <>
      <NoMatchesFound primary_dark='var(--bs-primary-dark)' primary_light='var(--bs-primary-light)' width={140} height={120} />
      <h4 className='mt-2'>{t('No Matches Found in the Data Universe')}</h4>
    </>
  )
}

const TableNoDataStateWhenSearchOrFilterNotApplied = () => {
  const {t} = useTranslation()
  return (
    <>
      <NoRecordFound primary='var(--bs-primary)' primary_dark='var(--bs-primary-dark)' width={160} height={160}/>
      <h4 className='mt-2'>{t('No Record Is Present to Display')}</h4>
    </>
  )
}

const TableNoDataStateWhenError = () => {
  const {t} = useTranslation()
  return (
    <>
      <ErrorState primary='var(--bs-primary)' primary_dark='var(--bs-primary-dark)' primary_light='var(--bs-primary-light)' width={160} height={120}/>
      <h4 className='mt-2'>{t('Oops, It Seems There Is An Issue In the Request')}.</h4>
      <p>{t('Please verify the provided information and retry')}.</p>
    </>
  )
}

export const TableNoDataState = ({error, success, isFilterOrSearchApplied}) => {
  return (
    <div className='d-flex flex-column align-items-center my-5'>
      { error && <TableNoDataStateWhenError /> }
      { success && isFilterOrSearchApplied && <TableNoDataStateWhenSearchOrFilterApplied /> }
      {success && !isFilterOrSearchApplied && <TableNoDataStateWhenSearchOrFilterNotApplied />}

    </div>
  )
}

export const TableLoadingState = () => {
  const {t} = useTranslation()

  return (
    <div className='d-flex flex-column align-items-center mx-4 mb-4 position-relative'>
      <ProgressLoader dimensions={170}/>
      <h4>{t('Hang Tight, We\'re Fetching Your Data')}</h4>
    </div>
  )
}