import SideSheet from '@src/@core/components/ui/sideSheet';
import { isObjEmpty } from '@src/utility/Utils';
import { FC, useMemo } from 'react';
import { ModalBody } from 'reactstrap';
import { ORDER_EDIT_UPDATED_FIELDS, ORDER_LOG_TRANSITION_TYPE } from '../constants';
import { iOrderLog, iToggleOrderLogDetailsSideSheet } from '../types';
import { formatName } from '../utils';
import DetailsCard from './details-card';
import OrderSummaryCard from './order-summary-card';
import SkuDetailsCard from './sku-details-card';

interface iOrderLogDetailsSidesheetProps {
  isOpen: boolean
  // eslint-disable-next-line no-unused-vars
  toggleOrderLogDetailsSideSheet: ({ item, status }: iToggleOrderLogDetailsSideSheet) => void
  preloadData: iOrderLog | null
}

/**
 * Side sheet component that displays detailed order log information
 * Shows changes in items, customer details, and shipping information
 * @component
 */
const OrderLogDetailsSidesheet: FC<iOrderLogDetailsSidesheetProps> = ({ 
  isOpen, 
  toggleOrderLogDetailsSideSheet, 
  preloadData 
}) => {
  const {
    subTextData,
    customerTransition,
    shippingAddressTransition,
    isCustomerUpdated,
    isShippingAddressUpdated,
    isInvoiceTransition
  } = useMemo(() => {
    // metadata will be available here always as this sidesheet will be opened only when metadata.show_updates is true
    const orderTransition = preloadData?.metadata.order_transition;

    return {
      subTextData: orderTransition ? [`${preloadData.event_updated_at} by ${preloadData.event_updated_by}`] : [],
      customerTransition: orderTransition ? orderTransition.customer_transition : { updated: null, previous: null },
      shippingAddressTransition: orderTransition ? orderTransition.shipping_address_transition : { updated: null, previous: null },
      isCustomerUpdated: orderTransition?.customer_transition.updated ? !isObjEmpty(orderTransition.customer_transition.updated) : false,
      isShippingAddressUpdated: orderTransition?.shipping_address_transition.updated ? !isObjEmpty(orderTransition.shipping_address_transition.updated) : false,
      isInvoiceTransition: orderTransition?.invoice_transition.updated ? !isObjEmpty(orderTransition.invoice_transition.updated) : false
    };
  }, [preloadData]);

  const customerDetails = useMemo(() => {
    if(!customerTransition.updated) return [];
    return [
      { 
        label: 'Name', 
        value: formatName(customerTransition.updated.first_name, customerTransition.updated.last_name),
        previousValue: formatName(customerTransition.previous.first_name, customerTransition.previous.last_name)
      },
      { 
        label: 'Contact Number', 
        value: customerTransition.updated.mobile, 
        previousValue: customerTransition.previous.mobile 
      },
      { 
        label: 'Email ID', 
        value: customerTransition.updated.email, 
        previousValue: customerTransition.previous.email 
      }
    ].filter((item) => item.value !== item.previousValue);
  }, [customerTransition]);

  const shippingDetails = useMemo(() => {
    if(!shippingAddressTransition.updated) return [];
    return [
      {
        label: 'Recipient\'s Name',
        value: formatName(
          shippingAddressTransition.updated.first_name, 
          shippingAddressTransition.updated.last_name
        ),
        previousValue: formatName(
          shippingAddressTransition.previous.first_name, 
          shippingAddressTransition.previous.last_name
        )
      },
      { 
        label: 'Email ID', 
        value: shippingAddressTransition.updated.email, 
        previousValue: shippingAddressTransition.previous.email 
      },
      { 
        label: 'Address', 
        value: shippingAddressTransition.updated.display_address, 
        previousValue: shippingAddressTransition.previous.display_address 
      },
      { 
        label: 'City', 
        value: shippingAddressTransition.updated.city, 
        previousValue: shippingAddressTransition.previous.city 
      },
      { 
        label: 'State/Region', 
        value: shippingAddressTransition.updated.state, 
        previousValue: shippingAddressTransition.previous.state 
      },
      { 
        label: 'Country', 
        value: shippingAddressTransition.updated.country, 
        previousValue: shippingAddressTransition.previous.country 
      },
      { 
        label: 'Zip Code', 
        value: shippingAddressTransition.updated.zip, 
        previousValue: shippingAddressTransition.previous.zip 
      },
      { 
        label: 'Contact Number', 
        value: shippingAddressTransition.updated.phone, 
        previousValue: shippingAddressTransition.previous.phone 
      }
    ].filter((item) => item.value !== item.previousValue);
  }, [shippingAddressTransition]);

  if (!preloadData) {
    return null;
  }

  return (
    // @ts-expect-error fix me
    <SideSheet
      isOpen={isOpen}
      toggle={() => toggleOrderLogDetailsSideSheet({ status: false })}
      modalClassName='modal-slide-in order-log-details-side-sheet'
      size='sm'
      title='Order Edit Updates'
      subTextData={subTextData}
    >
      <ModalBody className='d-flex flex-column gap-16px p-24px'>
        <SkuDetailsCard
          {...ORDER_EDIT_UPDATED_FIELDS.ITEMS}
          preloadData={preloadData}
        />

        {isInvoiceTransition && (
          <OrderSummaryCard
            {...ORDER_EDIT_UPDATED_FIELDS.ORDER_SUMMARY}
            preloadData={preloadData}
          />
        )}

        {isCustomerUpdated && customerTransition && (
          <DetailsCard
            {...ORDER_EDIT_UPDATED_FIELDS.CUSTOMER_DETAILS}
            status={ORDER_LOG_TRANSITION_TYPE.UPDATED}
            details={customerDetails}
          />
        )}

        {isShippingAddressUpdated && shippingAddressTransition && (
          <DetailsCard
            {...ORDER_EDIT_UPDATED_FIELDS.SHIPPING_ADDRESS}
            status={ORDER_LOG_TRANSITION_TYPE.UPDATED}
            details={shippingDetails}
          />
        )}
      </ModalBody>
    </SideSheet>
  );
};

export default OrderLogDetailsSidesheet;