import { FC } from 'react';
import { iDetail } from '../types';
import { CardHeader } from './card-header';
import DetailsList from './details-list';

interface iDetailsCardProps {
  icon: string
  label: string
  value: string
  status: string
  details: iDetail[]
}

/**
 * Card component for displaying grouped details
 * Used for customer and shipping address changes
 * @component
 */
const DetailsCard: FC<iDetailsCardProps> = ({ icon, label: title, value, status, details }) => {
  return (
    <div className='details-card border border-light-4 rounded-10px'>
      <CardHeader 
        icon={icon}
        title={title}
        value={value}
        status={status}
      />
      <DetailsList details={details} />
    </div>
  );
};

export default DetailsCard;
