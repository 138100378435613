// ** Dropdowns Imports
import NavbarHub from './NavbarHub'
// import CartDropdown from './CartDropdown'
import UserDropdown from './UserDropdown'
import './styles.scss'
// import NavbarSearch from './NavbarSearch'
// import NotificationDropdown from './NotificationDropdown'

// ** Third Party Components

// ** Reactstrap Imports
// import DownloadedReportDetailAction from '@src/views/components/downloadedReportDetail/DownloadedReportDetailAction'

import Freshdesk from '@src/@core/components/ui/freshdesk'
import GlobalSearch from '@src/components/GlobalSearch'
import { useTranslation } from 'react-i18next'

const NavbarUser = () => {

  // Conditional rendering of GlobalSearch component if language is English
  const translationInstance = useTranslation()
  const { i18n } = translationInstance
  const isEnglish = i18n.resolvedLanguage === 'en'

  // const selectedGlobalHubId = useSelector((state) => state.auth.selectedGlobalHubId)
  // const shouldGlobalDropdownBeHidden = !selectedGlobalHubId || shouldNotDisplayGlobalHubDropdown({ location })
  
  // const { toggleWidget, isRTL } = useFreshdesk()
  
  return (
    <ul className='nav navbar-nav align-items-center ms-auto gap-16px'>
      <li className='tw-w-[360px]'>
        {isEnglish && <GlobalSearch />}
      </li>
      <NavbarHub />
      <Freshdesk />
      <div className='navbar-user-name-link-separator' />
      <UserDropdown
        // isRTL={isRTL}
        // toggleWidget={toggleWidget}
      />
    </ul>
  )
}
export default NavbarUser
