import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import { axiosInstance } from '@src/network/AxiosInstance'
import { paramsSerializer } from '@src/utility/Utils'

/**********************Common *************************/

//roles and user setting
export const getRoles = createAsyncThunk('roleSettings/getRoles', async params => {
  const res = await axiosInstance.get('/api/v1/tenant/roles', { params })
  return res.data
})

//user setting and manage invoice
export const getSellersList = createAsyncThunk('sellers', async () => {
  const res = await axiosInstance.get('/api/v1/tenant/sellers')
  const data = await res.data
  const sellers = data.data.map(role => {
    return {
      value: role.id,
      label: role.name
    }
  })

  return sellers
})

/********************************* User Setting ****************************/
export const getUsersList = createAsyncThunk('settings/usersList', async ({ hub_id, ...params }) => {
  if (hub_id) {
    const response = await axiosInstance.get(`/api/v1/tenant/hubs/${hub_id}/users`, { params: { ...params } })
    return response?.data
  }
})

export const getHubs = createAsyncThunk('hubs', async () => {

  const res = await axiosInstance.get('/api/v1/wms/hubs')
  const data = await res?.data?.data
  const hubs = data?.map(role => {
    return {
      value: role.id,
      label: role.name
    }
  })
  return hubs
})

export const getHubsAsyncData = async (search, loadedOptions, { page, is_external_hub }) => {
  try {
    const response = await axiosInstance.get('/api/v1/wms/hubs', { params: { search_column: 'name', search_query: search, page, exclude_external_hub:is_external_hub } })
    const data = response?.data

    return {
      options: data?.data?.map(ele => ({
        value: ele.id,
        label: ele.name
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        is_external_hub
      }
    }
  } catch (error) {
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching sellers:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page,
        is_external_hub
      }
    }
  }
}

export const getHubsAsyncDataWithAllOption = async (search, loadedOptions, { page }) => {
  try {
    const response = await axiosInstance.get('/api/v1/wms/hubs', { params: { search_column: 'name', search_query: search, page } })
    const data = response?.data?.data
    const meta = response?.data?.meta

    if (page === 1 && Array.isArray(data)) {
      data.unshift({ id: 'all', name: 'All Hubs' })
    }

    return {
      options: data.map(ele => ({
        value: ele.id,
        label: ele.name
      })),
      hasMore: page < meta?.last_page,
      additional: {
        page: page + 1
      }
    }
  } catch (error) {
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching sellers:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getCitiesAsyncData = async (search, loadedOptions, {page}) => {
  try {
    const response = await axiosInstance.get('/api/v1/tenant/cities', {params: {search_column: 'name', search_query: search, page}})
    const data = response?.data
    return {
      options: data?.data?.map(ele => ({
        value: ele.id,
        label: ele.name
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }
  } catch (error) {
    console.warn(error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getSalesChannel = async (search, loadedOptions, { page }) => {
  try {
    const response = await axiosInstance.get('/api/v2/scs/sales_channels', { params: { search_column: 'name', search_query: search, page, sort: 'name_asc' } })
    const data = response?.data

    return {
      options: data?.data?.map(ele => ({
        value: ele.tag,
        label: ele.name,
        logo: ele.logo_url
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }
  } catch (error) {
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching sellers:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}
export const getSellersAsyncData = async (search, loadedOptions, { page }) => {
  try {
    const response = await axiosInstance.get('/api/v1/tenant/sellers', { params: { search_column: 'name', search_query: search, page, sort: 'name_asc', is_active: true } })
    const data = response?.data
    const options = data?.data?.length ? data.data.map(ele => ({
      value: ele.id,
      label: ele.name,
      code: ele.code,
      ...ele
    })) : []

    return {
      options,
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }
  } catch (error) {
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching sellers:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}
export const getSellersAsyncDataUpdated = async (search, loadedOptions, { page }) => {
  try {
    const response = await axiosInstance.get('/api/v1/tenant/sellers', { params: { search_column: 'name', search_query: search, page, sort: 'name_asc', is_active: true } })
    const data = response?.data

    return {
      options: data?.data?.map(ele => ({
        value: ele.id,
        label: ele.name
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }
  } catch (error) {
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching sellers:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getSellersWithAllOption = async (search, loadedOptions, { page }) => {
  try {
    const response = await axiosInstance.get('/api/v1/tenant/sellers', { params: { search_column: 'name', search_query: search, page, sort: 'name_asc', is_active: true } })
    const data = response?.data

    const allOption = {value: 'all', label: 'All'}

    const otherOptions = data?.data?.map(ele => ({
      value: ele.id,
      label: ele.name
    }))
    return {
      options: page === 1 ? [allOption, ...otherOptions] : [...otherOptions],
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getUnintegratedSellersAsyncData = async (search, loadedOptions, { is_active = true, page, exclude_ids, is_all_seller_mapped, is_edit_mode }) => {
  try {
    if (!is_edit_mode && is_all_seller_mapped) {
      throw new Error('No sellers available for mapping')
    }

    let params

    if (search === '') {
      params = {
        page,
        exclude_ids,
        sort: 'name_asc',
        is_active
      }
    } else {
      params = {
        page,
        exclude_ids,
        sort: 'name_asc',
        search_column: 'name',
        search_query: search,
        is_active
      }
    }

    const response = await axiosInstance.get(`/api/v1/tenant/sellers?${paramsSerializer(params)}`)
    const data = response?.data

    const allOption = {value: 'all', label: 'All Sellers'}

    const sellerOptions = data?.data?.map(ele => ({
      value: ele.id,
      label: ele.name
    }))

    return {
      options: sellerOptions.length > 0 ? (page === 1 ? [allOption, ...sellerOptions] : [...sellerOptions]) : [],
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        exclude_ids,
        is_all_seller_mapped,
        is_edit_mode
      }
    }
  } catch (error) {
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching sellers:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page,
        exclude_ids,
        is_all_seller_mapped,
        is_edit_mode
      }
    }
  }
}

export const getRolesAsyncData = async (search, loadedOptions, { page }) => {
  try {
    const response = await axiosInstance.get('/api/v1/tenant/roles', { params: { search_column: 'name', search_query: search, page } })
    const data = response?.data

    return {
      options: data?.data?.map(ele => ({
        value: ele.id,
        label: ele.name
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }
  } catch (error) {
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching sellers:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

/**  billing  **/ 

export const getBillingProfiles = async (search, loadedOptions, { page }) => {
  try {
    const response = await axiosInstance.get('/api/v1/billing/billing_profiles/?is_draft=false', { params: { search_column: 'name', search_query: search, page, sort: 'name_asc' } })
    const data = response?.data

    return {
      options: data?.data?.map(ele => ({
        value: ele.id,
        label: ele.name,
        ...ele
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }
  } catch (error) {
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching sellers:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getContracts = async (search, loadedOptions, { page, sellerId, startDate, endDate }) => {
  const params = {
    search_column: 'name',
    search_query: search,
    page,
    sort: 'active_desc',
    seller_id: sellerId,
    start_date: startDate,
    end_date: endDate
  }
  try {
    const response = await axiosInstance.get('/api/v1/billing/contracts', { params })
    const data = response?.data

    return {
      options: data?.data?.map(ele => ({
        value: ele.id,
        label: ele.name,
        isActive: ele.is_active
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        sellerId,
        startDate,
        endDate
      }
    }
  } catch (error) {
    // Handle the 403 error or other errors that may occur during the API request
    console.warn('Error fetching contracts:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page,
        sellerId,
        startDate,
        endDate
      }
    }
  }
}

export const getSkuOptionsForActions = async (search, _loadedOptions, {sellerId, params: restParams}) => {
  try {
    const  params = { search_value: search, ...restParams }
    const response = await axiosInstance.get(`/api/v1/products/sellers/${sellerId}/order_skus?${paramsSerializer({ ...params })}`)

    const data = response?.data?.data ?? []
    const options = data?.map(ele => ({
      ...ele,
      value: ele.sku.seller_sku_code,
      label: ele.sku.seller_sku_code,
      logo: ele.sku.images?.[0]?.default,
      type: ele.sku.type,
      uom: ele.sku.uom,
      is_weighted: ele.sku.is_weighted,
      name: ele.sku.name,
      weight: ele.sku.weight.value || 1,
      quantity: 1
    }))

    return {
      options: options,
      hasMore: restParams.page < response?.data?.meta?.last_page,
      additional: {
        page: restParams.page + 1,
        ...restParams
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
      }
    }
  }
}

export const getSkuOptionsForConditions = async (search, _loadedOptions, {sellerId, params: restParams}) => {
  try {
    const  params = { search_value: search, ...restParams }
    const response = await axiosInstance.get(`/api/v1/products/sellers/${sellerId}/order_skus?${paramsSerializer({ ...params })}`)

    const data = response?.data?.data ?? []
    const options = data?.map(ele => ({
      ...ele,
      value: ele.sku.id,
      label: ele.sku.seller_sku_code,
      logo: ele.sku.images?.[0]?.default,
      type: ele.sku.type,
      uom: ele.sku.uom,
      is_weighted: ele.sku.is_weighted,
      name: ele.sku.name,
      weight: ele.sku.weight.value,
      quantity: 1
    }))

    return {
      options: options,
      hasMore: restParams.page < response?.data?.meta?.last_page,
      additional: {
        page: restParams.page + 1,
        ...restParams
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
      }
    }
  }
}

/************* Role setting *********************/
export const getPermissions = createAsyncThunk('roleSettings/getPermissions', async () => {
  const res = await axiosInstance.get('/api/v1/tenant/permissions')
  return res?.data?.data
})

export const getRole = createAsyncThunk('roleSettings/getRole', async params => {
  const res = await axiosInstance.get(`/api/v1/tenant/roles/${params.id}`)
  return res?.data
})

export const createRole = createAsyncThunk('roleSettings/createRole', async body => {
  const res = await axiosInstance.post('/api/v1/tenant/roles', body)
  return res?.data
})

export const updateRole = createAsyncThunk('roleSettings/updateRole', async body => {
  const res = await axiosInstance.put(`/api/v1/tenant/roles/${body.id}`, body)
  return res?.data
})

export const deleteRole = createAsyncThunk('roleSettings/deleteRole', async body => {
  const res = await axiosInstance.delete(`/api/v1/tenant/roles/${body.id}`, body)
  return res?.data
})

/************************************manage invoice******************************* */
export const getInvoiceSettings = createAsyncThunk('manageInvoice/getInvoiceSettings', async id => {
  if (id === undefined) {
    return undefined
  }
  const res = await axiosInstance.get(`/api/v1/oms/invoice/settings/sellers/${id}`)
  return res.data
})

export const createInvoiceSettings = createAsyncThunk('manageInvoice/createInvoiceSettings', async body => {
  const id = body.seller_id
  const res = await axiosInstance.post(`/api/v1/oms/invoice/settings/sellers/${id}`, body)
  return res.data
})
export const updateInvoiceSettings = createAsyncThunk('/updateInvoice', async body => {
  const id = body.seller_id
  const res = await axiosInstance.put(`/api/v1/oms/invoice/settings/sellers/${id}`, body)
  return res.data
})
/****************************User Profile ************************************/

export const updateUserInfo = createAsyncThunk('userProfileInformation/updateUserInfo', async body => {
  const res = await axiosInstance.put('/api/v1/tenant/users', body)
  return res.data
})

export const changePassword = createAsyncThunk('userProfileInformation/change_password', async body => {
  const res = await axiosInstance.post('/api/v1/users/change_password', body)
  return res
})

/****************************OMS Settings ************************************/

export const getOrderSettings = createAsyncThunk('settings/getorderSettings', async () => {
  const res = await axiosInstance.get('/api/v1/oms/configurations')
  return res.data
})

export const createOrderSettings = createAsyncThunk('settings/createOrderSettings', async body => {
  const res = await axiosInstance.post('/api/v1/oms/configurations', body)
  return res.data
})

export const getRulesConditions = createAsyncThunk('settings/orderTags/conditions', async () => {
  const res = await axiosInstance.get('/api/v1/oms/orders/tags/conditions')
  return res.data
})
export const getAllOrderTags = createAsyncThunk('settings/orderTags/getAllOrderTags', async ({params, searchParams}) => {
  delete params?.page
  delete params?.per_page
  const res = await axiosInstance.get('/api/v1/oms/orders/tags', {params})
  return { data: res?.data.data, searchParams}
})
export const getSingleOrderTags = createAsyncThunk('settings/orderTags/getSingleOrderTags', async ({id, params}, store) => {
  const isOrderTagsVersionTwoEnabled = store.getState()?.auth?.isOrderTagsVersionTwoEnabled
  const res = await axiosInstance.get(`/api/v1/oms/orders/tags/${id}`, {params})
  return {data: res?.data, isOrderTagsVersionTwoEnabled}
})
export const createOrderTags = createAsyncThunk('settings/orderTags/createOrderTags', async body => {
  const res = await axiosInstance.post('/api/v1/oms/orders/tags', body)
  return res.data
})
export const updateOrderTags = createAsyncThunk('settings/orderTags/updateOrderTags', async ({selectedTag, data}) => {
  const res = await axiosInstance.put(`/api/v1/oms/orders/tags/${selectedTag}`, {...data, action: 'edit'})
  return res.data
})
export const deleteOrderTags = createAsyncThunk('settings/orderTags/deleteOrderTags', async id => {
  const res = await axiosInstance.put(`/api/v1/oms/orders/tags/${id}`, {action: 'delete'})
  return res.data
})
export const changeOrderTagsStatus = createAsyncThunk('settings/orderTags/changeOrderTagsStatus', async ({id, status}) => {
  const res = await axiosInstance.put(`/api/v1/oms/orders/tags/${id}`, {action: 'update_status', is_active: status})
  return res.data
})

export const getOrderTagsAsyncPaginate = async (search = '', loadedOptions, { page = 1 }) => {
  const params = search ? { name:search, status: 'active' } : { page, status: 'active' }
  delete params?.page
  delete params?.per_page
  try {
    const res = await axiosInstance.get('/api/v1/oms/orders/tags', {params})
    const arr = res?.data?.data
    // arr.unshift({ id: 'all', name: 'All' })
    return {
      options: arr.map((tags) => ({
        value: tags.id,
        label: tags.name,
        color: tags.color
      })),
      hasMore: res?.data?.meta?.current_page < res?.data?.meta?.last_page,
      additional: {
        page: res?.data?.meta?.current_page + 1
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getEligibilityConditions = createAsyncThunk('settings/automation/eligibility', async () => {
  const res = await axiosInstance.get('/api/v1/shipping/rules/conditions')
  return res.data
})

export const createEligibilityRules = createAsyncThunk('settings/automation/create-eligibility-rules', async (body) => {
  const res = await axiosInstance.post('/api/v1/shipping/rules', body)
  return res.data
})

export const getEligibilityRules = createAsyncThunk('settings/automation/get-eligibility-rules', async (allParams) => {
  const res = await axiosInstance.get(`/api/v1/shipping/rules?${paramsSerializer({...allParams})}`)
  return res.data
})

export const getSingleEligibilityRules = createAsyncThunk('settings/automation/get-single-eligibility-rules', async ({id}) => {
  const res = await axiosInstance.get(`/api/v1/shipping/rules/${id}`)
  return res.data
})

export const changeEligibilityRules = createAsyncThunk('settings/automations/changeEligibilityStatus', async ({id, ...body}) => {
  const res = await axiosInstance.put(`/api/v1/shipping/rules/${id}`, {operation: 'edit', ...body})
  return res.data
})

export const getProductConfiguration = createAsyncThunk('settings/catalog-setting/get-bulk-upload', async ({seller_id}) => {
  // const res = await axiosInstance.get(`api/v1/products/configurations/${id}`)
  const res = await axiosInstance.get(`/api/v1/products/sellers/${seller_id}/skus/configuration`)
  return res.data
})

export const updateProductConfiguration = createAsyncThunk('settings/catalog-setting/update-bulk-upload', async ({seller_id, body}) => {
  // const res = await axiosInstance.get(`api/v1/products/configurations/${id}`)
  const res = await axiosInstance.post(`/api/v1/products/sellers/${seller_id}/skus/configuration`, body)
  return res.data
})

export const getTenantSetting = createAsyncThunk('settings/get/tenant-settings', async ({tenant_id}) => {
  const res = await axiosInstance.get(`/api/v1/tenant/${tenant_id}`)
  return res.data
})

export const updateTenantSetting = createAsyncThunk('settings/update/tenant-settings', async ({tenant_id, body}) => {
  const res = await axiosInstance.put(`/api/v1/tenant/${tenant_id}`, body)
  return res.data
})


const initialState = {
  loading: {},
  error: {},
  success: {},
  usersList: [],
  usersTableMeta: {},
  rolesList: null,
  hubsList: null,
  role: {},
  createRoleResponse: {},
  updateRoleResponse: {},
  deleteRoleResponse: {},
  sellersList: null,
  permissions: null,
  invoiceSetting: null,
  invoiceSettingUpdate: null,
  user: null,
  userCreated: false,
  userDeleted: false,
  userEdited: false,
  ordersConfiguration: null,
  conditions: null,
  OrderTagResponse:{},
  allOrderTags: null,
  singleOrderTag: null,
  orderTagMeta: null,
  createEligibilityRuleResponse: { loading: false, data: null, error: null },
  createPriorityRuleResponse: { loading: false, data: null, error: null },
  eligibilityConditions: {},
  createEligibilityRulesRes: { loading: false, data: null, error: null, is_success: false},
  getEligibilityRulesResponse: { loading: false, data: null, error: null },
  updateEligibilityRulesResponse: {loading: false, data: null, error: null, is_success: false},
  getSingleEligibilityRulesResponse: { loading: false, data: null, error: null },
  productConfiguration: { loading: false, data: null, error: null },
  updateProductConfigurationRes: {loading: false, data: null, error: null, is_success: false},
  tenantSettingRes: null,
  createEligibilityRuleResponseData: {},
  sellerDataList: {},
  configSetting: { isOpen: false, reasonConfig: { entity: '', reasons: [] }}
}
const allSettingsSlice = createSlice({
  name: 'allSettings',
  initialState,
  reducers: {
    cleanupRoleResponses: state => {
      state.updateRoleResponse = {}
      state.createRoleResponse = {}
      state.role = {}
      state.loading = {}
      state.error = {}
      state.success = {}  
    },
    cleanupInvoiceResponses: state => {
      state.invoiceSetting = null
    },
    clearOrderTagResponses : state => {
      state.OrderTagResponse = {}
    },
    setOrderTagsMeta: (state, action) => {
      state.orderTagMeta = {
        ...state.orderTagMeta,
        ...action.payload
      }
    },
    clearUpdateOrderTag : state => {
      state.singleOrderTag = null
      state.conditions = null
    },
    clearOrderConfiguration : state => {
      state.ordersConfiguration = null
    },
    clearCreateEligibilityRule: state => {
      state.createEligibilityRuleResponse = {data: null, loading: false, error: null}
    },
    clearCreateEligibilityRuleResponseData: state => {
      state.createEligibilityRuleResponseData = {}
    },
    clearCreateEligibilityRules: state => {
      state.createEligibilityRulesRes = {data: null, loading: false, error: null, is_success: false}
    },
    clearCreatePriorityRule: state => {
      state.createPriorityRuleResponse = {data: null, loading: false, error: null}
    },
    clearUpdateEligibilityRule: state => {
      state.updateEligibilityRulesResponse = {data: null, loading: false, error: null, is_success: false}
    },
    clearGetSingleEligibilityRule: state => {
      state.getSingleEligibilityRulesResponse = { loading: false, data: null, error: null }
    },
    clearUpdateProductConfiguration: state => {
      state.updateProductConfigurationRes = { loading: false, data: null, error: null, is_success: false }
    },
    resetSuccess: state => {
      state.success = {}
    },
    clearTenantSettingRes: state => {
      state.tenantSettingRes = null
    },
    sellerDataListRes: (state, action) => {
      state.sellerDataList = action.payload
    },
    setOmsReasonConfig: (state, action) => {
      state.configSetting = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getUsersList.pending, state => {
        state.loading = { ...state.loading, getUsersList: true }
      })
      .addCase(getUsersList.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getUsersList: false }
        state.error = { ...state.error, getUsersList: false }
        state.usersList = action.payload?.data
        state.usersTableMeta = action.payload?.meta
        state.userDeleted = false
        state.userCreated = false
        state.userEdited = false
      })
      .addCase(getUsersList.rejected, state => {
        state.loading = { ...state.loading, getUsersList: false }
        state.error = { ...state.error, getUsersList: true }
      })
      .addCase(getRoles.pending, state => {
        state.loading = { ...state.loading, getRolesData: true }
        state.error = { ...state.error, getRolesData: false }
        state.success = {...state.success, getRolesData: false}
        // state.rolesList = { loading: true }
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.loading = {...state.loading, getRolesData: false}
        state.error = { ...state.error, getRolesData: false }
        state.success = {...state.success, getRolesData: true}
        state.rolesList = action.payload
      })
      .addCase(getRole.fulfilled, (state, action) => {
        state.loading = {...state.loading, getRolesData: false}
        state.error = { ...state.error, getRolesData: false }
        state.success = {...state.success, getRolesData: true}
        state.role = action.payload.data
      })
      .addCase(getRole.rejected, (state) => {
        state.loading = {...state.loading, getRolesData: false}
        state.error = { ...state.error, getRolesData: true }
        state.success = {...state.success, getRolesData: false}
      })
      .addCase(getHubs.fulfilled, (state, action) => {
        state.hubsList = action.payload
      })
      .addCase(getSellersList.fulfilled, (state, action) => {
        state.sellersList = action.payload
      })
      .addCase(getPermissions.pending, state => {
        state.loading = { ...state.loading, getPermissions: true }
      })
      .addCase(getPermissions.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getPermissions: false }
        state.permissions = action.payload
      })
      .addCase(getPermissions.rejected, state => {
        state.loading = { ...state.loading, getPermissions: false }
      })
      .addCase(createRole.pending, state => {
        state.loading = { ...state.loading, createRole: true }
        state.error = { ...state.error, createRole: false }
        state.success = { ...state.success, createRole: false }
      })
      .addCase(createRole.fulfilled, (state, action) => {
        state.loading = { ...state.loading, createRole: false }
        state.success = { ...state.success, createRole: true }
        state.createRoleResponse = action.payload
        state.error = { ...state.error, createRole: false }
      })
      .addCase(createRole.rejected, state => {
        state.loading = { ...state.loading, createRole: false }
        state.success = { ...state.success, createRole: false }
        state.error = { ...state.error, createRole: true }
      })
      .addCase(updateRole.pending, state => {
        state.loading = { ...state.loading, updateRole: true }
        state.success = { ...state.success, updateRole: false }
        state.error = { ...state.error, updateRole: false }
      })
      .addCase(updateRole.fulfilled, (state, action) => {
        state.loading = { ...state.loading, updateRole: true }
        state.success = { ...state.success, updateRole: false }
        state.updateRoleResponse = action.payload
        state.error = { ...state.error, updateRole: false }
      })
      .addCase(updateRole.rejected, state => {
        state.loading = { ...state.loading, updateRole: false }
        state.success = { ...state.success, updateRole: false }
        state.error = { ...state.error, updateRole: true }
      })
      .addCase(deleteRole.fulfilled, (state, action) => {
        state.deleteRoleResponse = action.payload
      })
      .addCase(getInvoiceSettings.fulfilled, (state, action) => {
        state.invoiceSetting = action.payload
        state.loading = { ...state.loading, getInvoiceSettings: false }
        state.invoiceSettingUpdate = false
      })
      .addCase(createInvoiceSettings.fulfilled, (state, action) => {
        if (action?.payload?.status_code === 200) {
          CustomToast('Invoice settings set successfully', { my_type: 'success' })
          state.invoiceSettingUpdate = action.payload.is_success
          state.loading = { ...state.loading, createInvoiceSettings: false }
        }
      })
      .addCase(updateInvoiceSettings.fulfilled, (state, action) => {
        if (action?.payload?.status_code === 200) {
          CustomToast('Invoice settings updated successfully', { my_type: 'success' })
          state.invoiceSettingUpdate = action.payload.is_success
          state.loading = { ...state.loading, updateInvoiceSettings: false }
        }
      })
      .addCase(changePassword.pending, (state) => {
        state.loading = { ...state.loading, changePassword: true }
        state.error = { ...state.error, changePassword: false }
        state.success = { ...state.success, changePassword: false }
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.loading = { ...state.loading, changePassword: false }
        state.error = { ...state.error, changePassword: false }
        state.success = { ...state.success, changePassword: true }
        if (action?.payload?.status === 200) {
          CustomToast('Password changed successfully', { my_type: 'success' })
        }
      })
      .addCase(changePassword.rejected, (state) => {
        state.loading = { ...state.loading, changePassword: false }
        state.error = { ...state.error, changePassword: true }
        state.success = { ...state.success, changePassword: false }
      })
      .addCase(createOrderSettings.pending, state => {
        state.loading = { ...state.loading, createOrderSettings: true }
      })
      .addCase(createOrderSettings.fulfilled, (state) => {
        state.loading = { ...state.loading, createOrderSettings: false }
        state.ordersConfiguration = null
        CustomToast('Settings saved successfully', { my_type: 'success' })
      })
      .addCase(createOrderSettings.rejected, state => {
        state.loading = { ...state.loading, createOrderSettings: false }
      })
      .addCase(getOrderSettings.fulfilled, (state, action) => {
        if (action?.payload?.status_code === 200) {
          state.ordersConfiguration = action.payload?.data
        }
      })
      .addCase(getOrderSettings.rejected, (state, action) => {
        state.ordersConfiguration = null
      })
      .addCase(getRulesConditions.fulfilled, (state, action) => {
        if (action?.payload?.status_code === 200) {
          state.conditions = action.payload?.data
        }
      })
      .addCase(getAllOrderTags.pending, (state) => {
        state.loading = { ...state.loading, getAllOrderTags: true }
      })
      .addCase(getAllOrderTags.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getAllOrderTags: false }
        state.success = { ...state.success, getAllOrderTags: true }
        state.error = { ...state.error, getAllOrderTags: false }
        state.allOrderTags = action.payload?.data
        const per_page = action.payload.searchParams.get('per_page') || 20
        const total = action.payload?.data?.length
        const last_page = Math.abs(Math.ceil(total / per_page))
        const current_page =
          Number(action.payload.searchParams.get('page')) || 1
        const start = (current_page - 1) * per_page
        const end = start + per_page
        state.orderTagMeta = {
          current_page,
          last_page,
          per_page,
          start,
          end,
          total
        }
      })
      .addCase(getAllOrderTags.rejected, (state) => {
        state.loading = { ...state.loading, getAllOrderTags: false }
        state.error = { ...state.error, getAllOrderTags: true }
        state.success = { ...state.success, getAllOrderTags: false }
      })
      .addCase(getSingleOrderTags.fulfilled, (state, action) => {
        console.log(action.payload, 'payload')
        if (action?.payload?.data?.status_code === 200) {
          state.loading = { ...state.loading, getSingleOrderTags: false }
          state.conditions = action.payload.isOrderTagsVersionTwoEnabled ? { condition_group:{conditions: action.payload.data?.data?.rule_entity?.condition_group?.conditions} } : { conditions: action.payload.data.data?.available_conditions?.conditions }
          const tempObj = { ...action.payload.data.data?.condition }
          delete tempObj.sub_conditions
          if (action.payload.data.data?.condition?.sub_conditions) {
            state.singleOrderTag = { ...action.payload?.data?.data, condition: [tempObj, ...action.payload.data.data?.condition?.sub_conditions] }
          } else {
            state.singleOrderTag = { ...action.payload?.data?.data, condition: [tempObj] }
          }
        }
      })
      .addCase(getSingleOrderTags.rejected, (state) => {
        state.loading = { ...state.loading, getSingleOrderTags: false }
      })
      .addCase(getSingleOrderTags.pending, (state) => {
        state.loading = { ...state.loading, getSingleOrderTags: true }
      })
      .addCase(createOrderTags.fulfilled, (state, action) => {
        if (action.payload?.status_code === 200) {
          CustomToast('Order tag created successfully', { my_type: 'success' })
          state.OrderTagResponse = { ...state.OrderTagResponse, createOrderTags: true }
          state.loading = { ...state.loading, createOrderTags: false }
        }
      })
      .addCase(createOrderTags.pending, (state) => {
        state.loading = { ...state.loading, createOrderTags: true }
      })
      .addCase(updateOrderTags.pending, (state) => {
        state.loading = { ...state.loading, updateOrderTags: true }
      })
      .addCase(createOrderTags.rejected, (state) => {
        state.loading = { ...state.loading, createOrderTags: false }
      })
      .addCase(updateOrderTags.rejected, (state) => {
        state.loading = { ...state.loading, updateOrderTags: false }
      })
      .addCase(updateOrderTags.fulfilled, (state, action) => {
        if (action.payload?.status_code === 200) {
          CustomToast('Order tag updated successfully', { my_type: 'success' })
          state.OrderTagResponse = { ...state.OrderTagResponse, updateOrderTags: true }
          state.loading = { ...state.loading, updateOrderTags: false }
        }
      })
      .addCase(deleteOrderTags.fulfilled, (state, action) => {
        if (action.payload?.status_code === 200) {
          CustomToast('Order tag deleted successfully', { my_type: 'success' })
          state.OrderTagResponse = { ...state.OrderTagResponse, deleteOrderTags: true }
          state.loading = { ...state.loading, deleteOrderTags: false }
        }
      })
      .addCase(deleteOrderTags.rejected, (state) => {
        state.loading = { ...state.loading, deleteOrderTags: false }
      })
      .addCase(deleteOrderTags.pending, (state) => {
        state.loading = { ...state.loading, deleteOrderTags: true }
      })
      .addCase(changeOrderTagsStatus.fulfilled, (state, action) => {
        if (action.payload?.status_code === 200) {
          CustomToast('Order tag status updated successfully', { my_type: 'success' })
          state.OrderTagResponse = { ...state.OrderTagResponse, changeOrderTagsStatus: true }
        }
      })
      .addCase(getInvoiceSettings.pending, (state) => {
        state.loading = {...state.loading, getInvoiceSettings:true}
      })
      .addCase(getInvoiceSettings.rejected, (state) => {
        state.loading = {...state.loading, getInvoiceSettings:false}
      })
      .addCase(updateInvoiceSettings.pending, (state) => {
        state.loading = {...state.loading, updateInvoiceSettings:true}
      })
      .addCase(updateInvoiceSettings.rejected, (state) => {
        state.loading = {...state.loading, updateInvoiceSettings:false}
      })
      .addCase(createInvoiceSettings.pending, (state) => {
        state.loading = {...state.loading, createInvoiceSettings:true}
      })
      .addCase(createInvoiceSettings.rejected, (state) => {
        state.loading = {...state.loading, createInvoiceSettings:false}
      })
      .addCase(getEligibilityConditions.pending, (state) => {
        state.loading = { ...state.loading, eligibilityConditions: true }
        state.error = {...state.error, eligibilityConditions: false }
      })
      .addCase(getEligibilityConditions.fulfilled, (state, action) => {
        state.eligibilityConditions = action.payload?.data
        state.error = { ...state.error, eligibilityConditions: false }
        state.loading = { ...state.loading, eligibilityConditions: false }
      })
      .addCase(getEligibilityConditions.rejected, (state) => {
        state.error = { ...state.error, eligibilityConditions: true }
        state.loading = { ...state.loading, eligibilityConditions: false }
      })
      .addCase(createEligibilityRules.pending, (state) => {
        state.createEligibilityRulesRes = { data: null, loading: false, error: null, is_success: false }
      })
      .addCase(createEligibilityRules.fulfilled, (state, action) => {
        state.createEligibilityRulesRes = { ...action.payload, loading: false, is_success: true }
        state.createEligibilityRuleResponseData = action.payload.data
        CustomToast('Rule created successfully.', { my_type: 'success' })
      })
      .addCase(createEligibilityRules.rejected, (state) => {
        state.createEligibilityRulesRes = { error: { message: 'Could not create Eligibility Rule' }, loading: false, is_success: false}
      })
      .addCase(getEligibilityRules.pending, (state) => {
        state.getEligibilityRulesResponse = { data: null, loading: true, error: null }
      })
      .addCase(getEligibilityRules.fulfilled, (state, action) => {
        state.getEligibilityRulesResponse = {data: action.payload, loading: false, is_success: true}
      })
      .addCase(getEligibilityRules.rejected, (state) => {
        state.getEligibilityRulesResponse  = { data: null, loading: false, error: {message: 'Could not get Eligibility Rule'}, is_success: false }
      })
      .addCase(getSingleEligibilityRules.pending, (state) => {
        state.getSingleEligibilityRulesResponse = { data: null, loading: false, error: null }
      })
      .addCase(getSingleEligibilityRules.fulfilled, (state, action) => {
        state.getSingleEligibilityRulesResponse = { ...action.payload, loading: false}
      })
      .addCase(getSingleEligibilityRules.rejected, (state) => {
        state.getSingleEligibilityRulesResponse  = { data: null, loading: false, error: {message: 'Could not get Eligibility Rule'} }
      })
      .addCase(changeEligibilityRules.pending, (state) => {
        state.updateEligibilityRulesResponse = { ...state.updateEligibilityRulesResponse, loading: true }
      })
      .addCase(changeEligibilityRules.fulfilled, (state, action) => {
        if (action.payload?.status_code === 200) {
          CustomToast('Rule updated successfully.', { my_type: 'success' })
          state.updateEligibilityRulesResponse = { ...state.updateEligibilityRulesResponse, is_success: true, loading: false }
        }
      })
      .addCase(changeEligibilityRules.rejected, (state) => {
        state.updateEligibilityRulesResponse = { ...state.updateEligibilityRulesResponse, loading: false }
      })
      .addCase(getProductConfiguration.pending, (state) => {
        state.productConfiguration = { data: null, loading: true, error: null }
      })
      .addCase(getProductConfiguration.fulfilled, (state, action) => {
        state.productConfiguration = { ...action.payload, loading: false}
      })
      .addCase(getProductConfiguration.rejected, (state) => {
        state.productConfiguration  = { data: null, loading: false, error: {message: 'Could not get product configuration'} }
      })
      .addCase(updateProductConfiguration.pending, (state) => {
        state.updateProductConfigurationRes = { data: null, loading: true, error: null }
      })
      .addCase(updateProductConfiguration.fulfilled, (state, action) => {
        state.updateProductConfigurationRes = { ...action.payload, loading: false, is_success: true }
        CustomToast('Bulk SKU configuration edited successfully', { my_type: 'success' })
      })
      .addCase(updateProductConfiguration.rejected, (state) => {
        state.updateProductConfigurationRes  = { data: null, loading: false, is_success: false, error: {message: 'Could not update product configuration'} }
      })
      // getTenantSetting
      .addCase(getTenantSetting.pending, (state) => {
        state.loading = {...state.loading, getTenantSetting:true}
        state.error = {...state.error, getTenantSetting:false}
        state.success = { ...state.success, getTenantSetting: false }
        state.tenantSettingRes = null
      })
      .addCase(getTenantSetting.fulfilled, (state, action) => {
        state.loading = {...state.loading, getTenantSetting:false}
        state.success = { ...state.success, getTenantSetting: true }
        state.tenantSettingRes = action.payload.data
      })
      .addCase(getTenantSetting.rejected, (state) => {
        state.loading = {...state.loading, getTenantSetting:false}
        state.error = { ...state.error, getTenantSetting: true }
      })
      // updateTenantSetting
      .addCase(updateTenantSetting.pending, (state) => {
        state.loading = {...state.loading, updateTenantSetting:true}
        state.error = {...state.error, updateTenantSetting:false}
        state.success = { ...state.success, updateTenantSetting: false }
        state.tenantSettingRes = null
      })
      .addCase(updateTenantSetting.fulfilled, (state, action) => {
        state.loading = {...state.loading, updateTenantSetting:false}
        state.success = { ...state.success, updateTenantSetting: true }
        state.tenantSettingRes = action.payload
      })
      .addCase(updateTenantSetting.rejected, (state) => {
        state.loading = {...state.loading, updateTenantSetting:false}
        state.error = { ...state.error, updateTenantSetting: true }
      })
    
  }
})

export const { clearOrderConfiguration, cleanupRoleResponses, cleanupInvoiceResponses, clearOrderTagResponses, setOrderTagsMeta, clearUpdateOrderTag, clearCreateEligibilityRules, clearCreateEligibilityRuleResponseData, clearUpdateEligibilityRule, clearGetSingleEligibilityRule, clearUpdateProductConfiguration, resetSuccess, clearTenantSettingRes, sellerDataListRes, setOmsReasonConfig} = allSettingsSlice.actions

export default allSettingsSlice.reducer


export const getUsersListAsyncData = async (search, loadedOptions, { page, hub_id }) => {
  try {
    const response = await axiosInstance.get(`/api/v1/tenant/hubs/${hub_id}/users`, { params: { search_column: 'name', search_query: search, page } })
    const data = response?.data

    return {
      options: data?.data?.map(ele => ({
        value: ele.id,
        label: ele.name
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        hub_id
      }
    }
  } catch (error) {
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching sellers:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}


export const getLocationListAsyncData = async (search, loadedOptions, { page, hub_id }) => {
  try {
    const response = await axiosInstance.get(`/api/v1/wms//hubs/${hub_id}/locations`, { params: { search_column: 'name', search_query: search, page } })
    const data = response?.data

    return {
      options: data?.data?.map(ele => ({
        value: ele.id,
        label: ele.barcode
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        hub_id
      }
    }
  } catch (error) {
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching sellers:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getAsyncAdHoc = async (search, loadedOptions, { page, selectedAdHoc }) => {
  try {
    const response = await axiosInstance.get('/api/v1/billing/adhoc/', {
      params: { query: search, page }
    })
    const data = response?.data

    if (selectedAdHoc?.length) {
      const filteredAdHoc = data?.data?.filter((el) => {
        if (!(selectedAdHoc.includes(el.name))) {
          return el
        }
      }) || []
      return {
        options: filteredAdHoc.map(ele => ({
          value: ele.name,
          label: ele.name,
          ...ele
        })),
        hasMore: page < data?.meta?.last_page,
        additional: {
          page: page + 1
        }
      }
    }
    
    return {
      options: data?.data?.map(ele => ({
        value: ele.name,
        label: ele.name,
        ...ele
      })) || [],
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getAsyncAdhocActivities = async (search, prevOptions, { page }) => {
  try {
    const params = {
      search_column: search ? 'name' : undefined,
      search_query: search || undefined,
      page
    }
    const response = await axiosInstance.get('/api/v1/billing/adhoc', { params })
    const data = response?.data
    if (page === 1 && !search && !data?.data?.length) {
      CustomToast('You cannot add any ad hoc usage as there are no ad hoc activities currently in the system. Please create an ad hoc activity first to add usage against it.', { my_type: 'error', duration: 2000, audioRequired: false })
    }
    return {
      options: data?.data?.map(ele => ({
        value: ele.id,
        label: ele.name,
        ...ele
      })) || [],
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getAsyncSkuConfigHubs = async (search, loadedOptions, { page, sellerSkuId, hubType, is_external_hub }) => {
  const params = search ? { search_column: 'name', search_query: search, type:hubType, seller_sku_id:sellerSkuId, exclude_external_hub:is_external_hub } : { page, type:hubType, seller_sku_id:sellerSkuId, exclude_external_hub:is_external_hub }
  try {
    const response = await axiosInstance.get('/api/v1/wms/skus/config/hubs', { params })
    const data = response?.data
    
    return {
      options: data?.data?.map(ele => ({
        value: ele.hub?.id,
        label: ele.hub?.name,
        ...ele
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        sellerSkuId,
        hubType
      }
    }
  } catch (error) {
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching sellers:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getVirtualHubsInSettings = async (search, loadedOptions, { page = 1, hasCreateHubPermission }) => {
  try {
    const response = await axiosInstance.get('/api/v1/oms/hubs/all', { params: { search_column: 'name', search_query: search, page } })
    const data = response?.data
    const modiFiedData = data?.data?.map(ele => ({
      value: {
        id: ele.id,
        type: ele.type,
        code: ele.hub_code
      },
      label: ele.name,
      address: ele.address
    }))
    const isHasMore = page < data?.meta?.last_page
    if (hasCreateHubPermission && page === 1 && Array.isArray(modiFiedData)) {
      modiFiedData.unshift({ value: 'add_new_virtual_hub', label: '+ Create Pickup Location', type: 'virtual' })
    }
    return {
      options: modiFiedData,
      hasMore: isHasMore,
      additional: {
        page: data?.meta?.current_page + 1,
        hasCreateHubPermission
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page,
        hasCreateHubPermission
      }
    }
  }
}

export const getOrderSkus = async (search, _loadedOptions, {sellerId, params: restParams, status='active'}) => {
  try {
    const  params = { search_value: search, ...restParams }
    const response = await axiosInstance.get(`/api/v1/products/sellers/${sellerId}/order_skus?${paramsSerializer({ ...params, status })}`)

    const data = response?.data?.data ?? []

    return {
      options: data,
      hasMore: restParams.page < response?.data?.meta?.last_page,
      additional: {
        page: restParams.page + 1,
        ...restParams
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
      }
    }
  }
}