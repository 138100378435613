export const EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL: 'email',
  MOBILE: 'mobile',
  COUNTRY_CODE: 'countryCode',
  CONTACT_NUMBER: 'contactNumber',
  ADDRESS1: 'address1',
  ADDRESS2: 'address2',
  CITY: 'city',
  COUNTRY: 'country',
  STATE: 'state',
  ZIP: 'zip',
};