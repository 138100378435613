import { RootState } from '@src/redux/store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Maximum number of searches to keep in history
const MAX_HISTORY_ITEMS = 5;

// Get search history from localStorage with user-specific storage
const getSearchHistory = (userId: string) => {
  try {
    // Get all search histories
    const allHistories = JSON.parse(localStorage.getItem('searchHistory') || '{}');
    
    if (userId) {
      // If user is logged in, return their specific history or empty array
      return allHistories[userId] || [];
    } else {
      // For backward compatibility - if no userId, check for direct array storage
      const legacyHistory = Array.isArray(allHistories) ? allHistories : [];
      return legacyHistory;
    }
  } catch {
    return [];
  }
};

// Save search history to localStorage with user-specific storage
const saveSearchHistory = (history: string[], userId: string) => {
  try {
    if (userId) {
      // Get existing histories
      const allHistories = JSON.parse(localStorage.getItem('searchHistory') || '{}');
      
      // Update history for specific user
      allHistories[userId] = history;
      
      // Save back to localStorage
      localStorage.setItem('searchHistory', JSON.stringify(allHistories));
    } else {
      // Backward compatibility - save directly as array if no userId
      localStorage.setItem('searchHistory', JSON.stringify(history));
    }
  } catch (error) {
    console.error('Error saving search history:', error);
  }
};

export const useSearchHistory = () => {
  const [searchHistory, setSearchHistory] = useState<string[]>([]);
  const userData = useSelector((state: RootState) => state.auth.userData);
  const userId = (userData as unknown as null | { id: string })?.id || '';

  // Load search history on mount and when userId changes
  useEffect(() => {
    setSearchHistory(getSearchHistory(userId));
  }, [userId]);

  const addToHistory = (query: string) => {
    if (!query.trim()) return;
    
    const history = getSearchHistory(userId);
    const newHistory = [
      query,
      ...history.filter((item: string) => item !== query)
    ].slice(0, MAX_HISTORY_ITEMS);
    
    saveSearchHistory(newHistory, userId);
    setSearchHistory(newHistory);
  };

  const removeFromHistory = (query: string) => {
    const history = getSearchHistory(userId);
    const newHistory = history.filter((item: string) => item !== query);
    saveSearchHistory(newHistory, userId);
    setSearchHistory(newHistory);
  };

  const clearHistory = () => {
    saveSearchHistory([], userId);
    setSearchHistory([]);
  };

  return {
    searchHistory,
    addToHistory,
    removeFromHistory,
    clearHistory
  };
}; 