import { getApiUrl } from '@src/utility/Utils.jsx'
import { API_ENDPOINTS } from './api.urls'
import { ALL_OPTION, LOAD_OPTION_SEARCH_COLUMN, LOAD_SHIPPING_PARTNER_CONFIG, MANUAL_OPTION } from './App.constants'
import { SORT_TYPE } from './views/inventory/inventory.constants'

export const sampleFetchApi = {
  apiKey: ['sample-fetch-api'],
  url: API_ENDPOINTS.BILLINGS.CREATE_AD_HOC_ACTIVITY,
}

export const getOmsReasonsListApi = {
  apiKey: ['get-oms-reasons-list'],
  url: API_ENDPOINTS.COMMON.GET_OMS_REASONS,
}

export const loadCitiesConfig = {
  apiUrl: API_ENDPOINTS.COMMON.GET_ALL_CITIES,
  select: (data) => {
    return (
      data.data?.map((ele) => ({
        label: ele.name,
        value: ele.id,
      })) || []
    )
  },
}
export const loadStatesCitiesConfig = {
  apiUrl: (additional) => {
    const url = getApiUrl(API_ENDPOINTS.COMMON.GET_CITIES_BASED_ON_THE_STATE, { stateId: additional.state.value })
    return url
  },
  select: (data) => {
    return (
      data.data?.map((ele) => ({
        label: ele.name,
        value: ele.id,
      })) || []
    )
  },
}

export const loadShippingPartnerConfig = (valueType) => {
  return {
    apiUrl: API_ENDPOINTS.COMMON.GET_SHIPPING_PARTNERS,
    additionalHandler: (search, previousAdditional) => ({
      ...previousAdditional,
      params: {
        name_filter: search ? search : undefined,
      },
    }),
    select: (data, { additional, search }) => {
      const options =
        data?.data?.map((ele) => ({
          id: ele.id,
          // eslint-disable-next-line no-undef
          value: valueType === LOAD_SHIPPING_PARTNER_CONFIG.ID ? ele[LOAD_SHIPPING_PARTNER_CONFIG.ID] : ele.tag,
          logo: ele.logo,
          label: ele.name,
        })) || []
      if (search !== '' && !MANUAL_OPTION.value.includes(search.toLowerCase())) {
        return options
      }
      return additional.isManualHidden ? options : [{ ...MANUAL_OPTION, logo: null }, ...options]
    },
    hasMore: () => false,
  }
}

export const loadCountryConfig = {
  apiUrl: API_ENDPOINTS.COMMON.GET_ALL_COUNTRIES,
  select: (data) =>
    data.data?.map((ele) => ({
      value: ele.id,
      label: ele.name,
      code: ele.iso2,
      currencyCode: ele.currency.code,
      currency: ele.currency,
    })) || [],
}

export const getLoadOrderTagsConfig = (props) => {
  const { valueAsName, isMultiSelect } = props || { valueAsName: true, isMultiSelect: false }
  return {
    apiUrl: () => API_ENDPOINTS.COMMON.GET_OMS_ORDER_TAGS,
    hasMore: () => false,
    select: (data, { search }) => {
      const options = data.data
        ?.filter((tag) => {
          if (search) {
            const regex = new RegExp(search, 'i')
            return regex.test(tag.name)
          }
          return true
        })
        .map((tag) => ({
          value: valueAsName ? tag.name : tag.id,
          color: tag.color,
          label: tag.name,
        }))
      return isMultiSelect ? [{ label: '', options }] : options
    },
  }
}
export const loadStatesConfig = {
  apiUrl: (additional) => {
    const url = getApiUrl(API_ENDPOINTS.COMMON.GET_STATES_BASED_ON_THE_COUNTRY, { countryId: additional.country.value })
    return url
  },

  select: (data) =>
    data.data?.map((ele) => ({
      value: ele.id,
      label: ele.name,
      code: ele.iso2,
    })) || [],
}

export const loadAllSellersConfig = (props) => {
  const { includeAllOption } = props || {}
  return {
    apiUrl: API_ENDPOINTS.COMMON.GET_ALL_SELLERS,
    additionalHandler: (search, prevAdditional) => ({
      ...prevAdditional,
      params: {
        search_query: search ? search : undefined,
        search_column: search ? 'name' : undefined,
        page: (prevAdditional.params?.page || 0) + 1,
        is_active: true,
        sort: SORT_TYPE.ASC,
      },
    }),
    select: (data, { search, additional }) => {
      const options =
        data?.data?.map((ele) => ({
          value: ele.id,
          label: ele.name,
          code: ele.code,
        })) || []

      if (includeAllOption) {
        const shouldIncludeAllOption = search ? ALL_OPTION.value.includes(search.toLowerCase()) : additional.params.page === 1
        // Add the 'All' option if applicable
        const updatedOptions = shouldIncludeAllOption ? [ALL_OPTION, ...options] : options
        return updatedOptions
      } else {
        return options
      }
    },
  }
}

export const loadCourierPartnersConfig = {
  apiUrl: API_ENDPOINTS.COMMON.GET_COURIER_PARTNERS,
  additionalHandler: (search, prevAdditional) => {
    return {
      ...prevAdditional,
      params: {
        name_filter: search ? search : undefined,
        page: (prevAdditional.params?.page || 0) + 1,
      },
    }
  },
  select: (data, { search, additional }) => {
    const options =
      data?.data?.map((ele) => ({
        id: ele.id,
        value: ele.tag,
        logo: ele.logo,
        label: ele.name,
      })) || []

    // Determine if the manual option should be included based on search
    const shouldIncludeManualOption = search ? MANUAL_OPTION.value.includes(search.toLowerCase()) : additional.params.page === 1

    // Add the manual option if applicable
    const updatedCourierOptions = shouldIncludeManualOption ? [MANUAL_OPTION, ...options] : options

    return updatedCourierOptions
  },
}

export const loadSellerSalesChannelConfig = {
  apiUrl: (additional) => {
    if (!additional?.seller) {
      return null;
    }
    const url = getApiUrl(API_ENDPOINTS.COMMON.GET_SELLER_SALES_CHANNEL, { sellerId: additional.seller });
    return url;
  },
  additionalHandler: (search, prevAdditional) => {
    return {
      ...prevAdditional,
      params: {
        search_query: search || undefined,
        search_column: search ? LOAD_OPTION_SEARCH_COLUMN.SELLER_SALES_CHANNEL_NAME: undefined,
        page: (prevAdditional.params?.page || 0) + 1,
        status_not_in: prevAdditional.statusNotIn || undefined,
        type: prevAdditional.type || undefined
      }
    }
  },
  select: (data) => data?.data?.map(ele => ({
    value: ele.id,
    label: ele.name,
    logo: ele.sales_channel_logo
  })) || [],
  hasMore: () => false
}

export const loadVillagesConfig = {
  apiUrl: API_ENDPOINTS.COMMON.GET_VILLAGES,
  select: (data) =>
    data?.data?.map((ele) => ({
      value: ele.id,
      label: ele.name,
      ...ele,
    })) || [],
}
export const getLoadHubsConfig = (props) => {
  const { includeAllOption } = props || {}
  return {
    apiUrl: API_ENDPOINTS.HUBS.GET_HUBS,
    select: (data, { search, additional }) => {
      const options =
        data?.data?.map((ele) => ({
          value: ele.id,
          label: ele.name,
        })) || []

      if (includeAllOption) {
        const shouldIncludeAllOption = search ? ALL_OPTION.value.includes(search.toLowerCase()) : additional.params.page === 1
        // Add the 'All' option if applicable
        const updatedOptions = shouldIncludeAllOption ? [ALL_OPTION, ...options] : options
        return updatedOptions
      } else {
        return options
      }
    },
  }
}

export const loadOmsReasonsConfig = {
  apiUrl: API_ENDPOINTS.COMMON.GET_OMS_REASONS,
  select: (data) => data.data?.[0]?.reasons.map(ele => ({
    value: ele,
    label: ele
  })) || [],
  hasMore: () => false
}

export const loadAllCustomersConfig = () => {
  return {
    apiUrl: (additional) => getApiUrl(API_ENDPOINTS.ORDERS.GET_CUSTOMER_DETAILS_OR_CREATE_CUSTOMER, { sellerId: additional.seller_id }),
    additionalHandler: (search, previousAdditional) => ({
      ...previousAdditional,
      params: {
        query: search,
        page: (previousAdditional.params?.page || 0) + 1,
      },
    }),
    select: (data) =>
      data.data?.map((ele) => ({
        ...ele,
        value: ele.id,
        label: ele.last_name ? `${ele.first_name} ${ele.last_name}` : ele.first_name,
      })) || [],
  }
}

export const loadAsyncSellerSkusConfig = () => {
  return {
    apiUrl: (additional) => getApiUrl(API_ENDPOINTS.ORDERS.GET_SELLER_SKUS, { sellerId: additional.seller_id }),
    select: (data, { additional }) => {
      const { originalItemsData } = additional
      return (
        data.data?.map((ele) => ({
          id: ele.id,
          name: ele.name,
          sku_code: ele.seller_sku_code,
          image: ele.images?.[0]?.default,
          selling_price: ele.display_selling_price,
          retail_price: ele.display_retail_price,
          disabled: originalItemsData?.some((item) => item.seller_sku_id === ele.id),
          ...ele,
        })) || []
      )
    },
    additionalHandler: (search, previousAdditional) => ({
      ...previousAdditional,
      params: {
        query: search,
        type: 'simple',
        page: (previousAdditional.params?.page || 0) + 1,
        per_page: 20,
        status: previousAdditional.status,
      },
    }),
  }
}

export const loadAllPickupLocationsAsyncConfig = () => {
  return {
    apiUrl: (additional) => getApiUrl(API_ENDPOINTS.ORDERS.GET_ALL_PICKUP_LOCATIONS, { sellerId: additional.seller_id }),
    additionalHandler: (search, prevAdditional) => ({
      ...prevAdditional,
      params: {
        search_query: search ? search : undefined,
        page: (prevAdditional.params?.page || 0) + 1,
      },
    }),
    select: (data) =>
      data.data?.map((ele) => ({
        value: ele.id,
        label: ele.name,
      })) || [],
  }
}
