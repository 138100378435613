
import SideSheet from '@src/@core/components/ui/sideSheet'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalBody, ModalHeader } from 'reactstrap'
import './styles.scss'
// import classNames from 'classnames'
// import defaultImg from '@src/assets/images/catalog/bundlesku/default-img.svg'
import ImageActions from '@src/@core/components/ui/image-preview-modal'
import { useDispatch, useSelector } from 'react-redux'
import { cleanupSingleSku, getSku, getSkuSalesChannel } from '../../store'
import SimpleSkuDetail from './SimpleSkuDetail'
import SimpleSkuInfo from './SimpleSkuInfo'
// import maximizeIcon from '@src/assets/images/catalog/maximize.svg'
// import error_img from '@src/assets/images/catalog/bundlesku/error_img.svg'
import CustomTag from '@src/@core/components/ui/badge/CustomTag'
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner'
import { STATUS_TYPES } from '@src/App.constants'
import BarcodeListViewModal from '@src/components/barcode-view-modal'
import classNames from 'classnames'
import SkuImageHandler from '../SkuImageHandler'

const SimpleSkuDetailModal = ({
  skuForDetails,
  toggleSimpleSkuModal,
  isArchive,
  selectedSalesChannel,
  isWeightedAvgCost,
  ...rest
}) => {
  const [uploadedImages, setUploadedImages] = useState([])
  const [currentSelectedImage, setCurrentSelectedImage] = useState(0)
  const [barcodeModalOpen, setBarcodeModalOpen] = useState(false)
  const [barcodeList, setBarcodeList] = useState([])
  const [isImageActionModalOpen, setIsImageActionModalOpen] = useState(false)

  const getSkuDetail = (skuForDetails?.sales_channel_sku_code || skuForDetails?.sales_channel_sku_id) ? useSelector(state => state.catalog?.skuSalesChannel?.data) : useSelector(state => state.catalog?.sku)
  const loading = (skuForDetails?.sales_channel_sku_code || skuForDetails?.sales_channel_sku_id) ? useSelector(state => state.catalog?.skuSalesChannel?.loading) : useSelector((state) => state.catalog.skuLoading)
  const { t } = useTranslation()
  const imageRef = useRef(null)
  const dispatch = useDispatch()

  const onModalOpened = () => {
    const mappedImages = (skuForDetails.seller_sku || skuForDetails.sku || skuForDetails).images?.map((ele) => {
      return { value: ele.default || ele.url }
    }) || []

    setUploadedImages(mappedImages)
    if ((skuForDetails?.sales_channel_sku_code || skuForDetails?.sales_channel_sku_id)) {
      dispatch(getSkuSalesChannel({ sku_id: skuForDetails?.id, sales_channel_id: selectedSalesChannel?.value }))
    } else {
      if (skuForDetails?.seller_id || skuForDetails?.seller_sku?.id || skuForDetails?.id || skuForDetails?.seller?.id || skuForDetails.sku?.seller_sku_id) {
        dispatch(getSku({ params: {sellerId: skuForDetails.seller_sku?.seller_id ||  skuForDetails.seller_id || skuForDetails.seller?.id || skuForDetails.sku?.seller_id, sku_id: skuForDetails.sku?.seller_sku_id  || skuForDetails.seller_sku?.id ||  skuForDetails.id || skuForDetails.seller_sku_id || skuForDetails.sku?.seller_sku_id}, query: {is_archived: isArchive ? `${isArchive}` : false } }))
      }
    }
  }

  const onModalClosed = () => {
    setCurrentSelectedImage(0)
    setUploadedImages([])
    dispatch(cleanupSingleSku())
  }
  return (
    <div>
      <SideSheet
        modalClassName='modal-slide-in simple-sku-detail-modal'
        size='md'
        toggle={toggleSimpleSkuModal}
        onOpened={onModalOpened}
        onClosed={onModalClosed}
        {...rest}
      >
        <ModalHeader toggle={toggleSimpleSkuModal}>
          <div className='d-flex align-items-center gap-1'>
            <div className='txt-h2-md'>{t('SKU Details')}</div>
            {((getSkuDetail?.status) && !loading) && <CustomTag className={classNames({
              'text-success bg-success-light': (getSkuDetail?.status) === STATUS_TYPES.ACTIVE.value,
              'text-dark bg-dark-1': (getSkuDetail?.status) === STATUS_TYPES.INACTIVE.value
            })} title={Object.values(STATUS_TYPES).find((type) => (getSkuDetail?.status) === type.value)?.label} />}
          </div>
        </ModalHeader>
        <ModalBody>
          {loading ? <ComponentSpinner /> : 
            <div className='simple-sku-detail-modal'>
              <div className='d-flex flex-column gap-24px p-24px'>
                <div className='d-flex gap-2'>
                  <div>
                    <SkuImageHandler
                      uploadedImages={uploadedImages}
                      currentSelectedImage={currentSelectedImage}
                      imageRef={imageRef}
                      setCurrentSelectedImage={setCurrentSelectedImage}
                      setUploadedImages={setUploadedImages}
                      isView={true}
                    />
                  </div>
                  <SimpleSkuDetail sku={getSkuDetail?.id ? getSkuDetail : skuForDetails} t={t} setBarcodeModalOpen={setBarcodeModalOpen} setBarcodeList={setBarcodeList} barcodeModalOpen={barcodeModalOpen} isWeightedAvgCost={isWeightedAvgCost}/>
                </div>
                <div>
                  <SimpleSkuInfo t={t} sku={getSkuDetail?.id ? getSkuDetail : skuForDetails}/>
                </div>

                <div>
                </div>
              </div>
            </div>
          }
        </ModalBody>
      </SideSheet>

      <BarcodeListViewModal
        modalOpen={barcodeModalOpen}
        toggleSidebar={() => setBarcodeModalOpen(false)}
        data={barcodeList}
      />
      <ImageActions
        isOpen={isImageActionModalOpen}
        toggle={() => setIsImageActionModalOpen(false)}
        imgArray={uploadedImages}
        setCurrentSelectedImage={setCurrentSelectedImage}
        currentSelectedImage={currentSelectedImage}
        uploadedImages={uploadedImages}
        imageRef={imageRef}
        isView={true}
      />
    </div>
  )

}

export default SimpleSkuDetailModal

