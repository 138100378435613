// ** Third Party Components
import { useTranslation } from 'react-i18next'
import ReactCountryFlag from 'react-country-flag'
import { useRTL } from '@hooks/useRTL'
import Select, {components} from 'react-select'
import './styles.scss'
import { ChevronDown, ChevronUp } from 'react-feather'
import { useState } from 'react'
import { selectThemeColors } from '@src/utility/Utils'

const IntlDropdown = () => {
  // ** Hooks
  const { i18n } = useTranslation()
  const [, setValue] = useRTL()
  const [isOpen, setIsOpen] = useState(false)
  const language = localStorage.getItem('i18nextLng')

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      paddingTop : '1px',
      height: '36px',
      '&:hover': {
        background: state.isSelected ? provided.background : 'var(--bs-primary-lighter) !important', // Remove background color change on hover
        color: state.isSelected ? 'var(--bs-white)' : 'var(--bs-primary) !important'
      },
      color: state.isSelected ? 'var(--bs-white)' : 'var(--bs-dark)'
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '8px',
      marginTop: '8px',
      animationDuration: '0.3s',
      animationFillMode: 'both',
      animationName: 'slideIn',
      color: 'black',
      border: 'none',
      overflow: 'hidden',
      top: '2.75rem',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' // Customize the box shadow of the dropdown menu
    }),
    control: (provided, state) => ({
      ...provided,
      boxSize: 'borderBox',
      height: '35px !important',
      minHeight : '35px !important',
      cursor: 'pointer',
      width: '135px',
      marginTop: '0px',
      borderRadius: '8px',
      fontSize:'14px',
      boxShadow: (state.isFocused && state.isOpen) && state.menuIsOpen  ? '0 2px 5px rgba(0, 0, 0, 0.1) !important' : 'none',
      border: state.isFocused && state.menuIsOpen ? '1px solid var(--bs-primary)' : '1px solid #dedede',
      '&:hover': {
        borderColor: 'var(--bs-primary)'
      },
      '&:focus': {
        border: '1px solid var(--bs-primary)',
        borderColor: 'rgba(84, 104, 250, 0.5)' // Remove border on focus
      }
    }),
    valueContainer: provided => ({
      ...provided,
      overflow: 'visible',
      marginTop: '-2px'
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: 'black',
      marginTop: '-1px',
      paddingLeft: 0,
      // marginInlineEnd:"10px",
      marginInlineStart:'10px',
      width:'20px',
      '&:hover': {
        color: 'black'
      }
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none'
    })
  }

  // ** Vars
  // const langObj = {
  //   en: 'English',
  //   de: 'German',
  //   fr: 'French',
  //   pt: 'Portuguese'
  // }

  // const langObjCustom = {
  //   ['en-US']: { name: "English", countryCode: "us", isRTL: false },
  //   // de: {name: 'German', countryCode: "de", isRTL: false},
  //   // fr: {name: 'French', countryCode: "fr", isRTL: false},
  //   // pt: {name: 'Portuguese', countryCode: "pt", isRTL: false},
  //   ar: { name: "العربية", countryCode: "sa", isRTL: true }
  // }

  const langObjCustomSelect = [
    {value: 'English', label:'English', lang: 'en-US', countryCode: 'us', isRTL: false },
    { value: 'العربية', label: 'العربية', lang: 'ar',  countryCode: 'sa', isRTL: true }
  ]

  const handleMenuOpen = () => {
    setIsOpen(true)
  }

  const handleMenuClose = () => {
    setIsOpen(false)
  }

  // ** Function to switch Language
  const handleLangUpdated = (val) => {
    setIsOpen(!isOpen)
    setValue(val?.isRTL)
    // setSelectedValue(val)
    i18n.changeLanguage(val?.lang)
    //Google Maps loader is being initialized multiple times with different language options when the language changes so we need to reload the page to fix it
    window.location.reload();
  }

  const CustomOption = (props) => {
    const {Option} = components
    return (
      <Option {...props}>
        <div style={{marginTop: '6px'}} className="cursor-pointer" >
          <ReactCountryFlag
            svg
            style={{width: '27px', height: '16px', marginBottom: '1px'}}
            className="cursor-pointer"
            countryCode={props?.data?.countryCode  ? props?.data?.countryCode : langObjCustomSelect?.[0]?.countryCode}
          />
          <span style={{marginInlineStart: '12px'}}>{props.label}</span>  
        </div>
      </Option>
    )
  }

  const CustomSingleValue = ({ children, ...props }) => {
    const {SingleValue} = components
    return (
      <SingleValue {...props}>
        <ReactCountryFlag
          svg
          style={{width: '27px', height: '16px'}}
          className="cursor-pointer"
          countryCode={
            props?.data?.countryCode ||
            langObjCustomSelect?.[0]?.countryCode
          }
        />
        <span style={{marginInlineStart: '12px'}} className="cursor-pointer">{children}</span>
      </SingleValue>
    )

  }

  return (
    <>
      <Select 
        id="mySelect"
        styles={customStyles}
        options={langObjCustomSelect}
        isOpen={isOpen}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        isSearchable = {false}
        components={{ Option: CustomOption, SingleValue: CustomSingleValue, DropdownIndicator: (state) => (state.isFocused && isOpen ? <ChevronUp style={{marginInlineEnd: '12px', color: 'black'}} size={12}/> : <ChevronDown  style={{marginInlineEnd: '12px', color: 'black'}} size={12} />)}} // Use the custom Option component 
        defaultValue={
          language === null ||
      language === 'en-US' ||
      language === 'en-GB' ||
      language === 'en' ||
       language !== 'ar'
            ? langObjCustomSelect[0]
            : langObjCustomSelect[1]
        }
        onChange={handleLangUpdated}
        theme= {selectThemeColors}
      />
    </>
  )
}

export default IntlDropdown