import Button from '@src/@core/components/ui/button';
import InputField from '@src/@core/components/ui/input-field';
import { INPUT_TYPES } from '@src/App.constants';
import EditIcon from '@src/assets/images/icons/EditIcon';
import React, { FC, useState } from 'react';
import { Trash2, X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { CONFIRM_CHANGES_FORM_FIELD_NAMES } from '../constants';
import { iCodChargesProps } from '../types';

const CodCharges: FC<iCodChargesProps> = ({
  confirmChangesForm,
  currency,
  maxLimit
}) => {
  const {
    setValue,
    watch,
    formState: { errors }
  } = confirmChangesForm;
  
  const { t } = useTranslation();

  const [codChargesAmount, setCodChargesAmount] = useState<string>(
    watch(CONFIRM_CHANGES_FORM_FIELD_NAMES.COD_CHARGES_AMOUNT)
  );

  const toggleCodCharges = (status: boolean) => {
    setValue(CONFIRM_CHANGES_FORM_FIELD_NAMES.IS_COD_CHARGES_EDIT_MODE, status);
    setCodChargesAmount(watch(CONFIRM_CHANGES_FORM_FIELD_NAMES.COD_CHARGES_AMOUNT));
  };

  const handleDelete = () => {
    setValue(CONFIRM_CHANGES_FORM_FIELD_NAMES.COD_CHARGES_AMOUNT, '');
    setValue(CONFIRM_CHANGES_FORM_FIELD_NAMES.IS_COD_CHARGES_EDIT_MODE, false);
  };

  const handleApply = () => {
    const roundedAmount = +codChargesAmount > 0 ? (+codChargesAmount).toFixed(2) : '';
    setValue(CONFIRM_CHANGES_FORM_FIELD_NAMES.COD_CHARGES_AMOUNT, roundedAmount);
    setValue(CONFIRM_CHANGES_FORM_FIELD_NAMES.IS_COD_CHARGES_EDIT_MODE, false);
  };

  const handleChargesOnChange = (newValue: string) => {
    // Validates input to allow numbers with up to 2 decimal places
    const regex = /^\d*\.?\d{0,2}$/;
    
    if (!regex.test(newValue)) return;
    
    if (newValue === '') {
      setCodChargesAmount(newValue);
      return;
    }
    
    const numericNewValue = +newValue;
    
    if (numericNewValue < 0) {
      return;
    }

    // Add maximum limit validation
    if (maxLimit && numericNewValue > maxLimit) {
      return;
    }
    
    setCodChargesAmount(newValue);
  };

  return (
    <>
      {watch(CONFIRM_CHANGES_FORM_FIELD_NAMES.IS_COD_CHARGES_EDIT_MODE) ? (
        <div className='d-flex flex-column gap-16px bg-light-2 p-16px rounded-8px'>
          <div className='d-flex flex-center-between txt-body-md text-dark'>
            <div className='flex-center-start gap-6px text-dark-5'>
              {t('COD Charges')}
            </div>
            <span className='cursor-pointer' onClick={() => toggleCodCharges(false)}>
              <X size={20} />
            </span>
          </div>
          <div className='d-flex gap-16px h-40px'>
            <div className='w-100'>
              <InputField
                /* @ts-expect-error fix */
                label={`Amount (${currency})`}
                value={codChargesAmount}
                errors={errors}
                type={INPUT_TYPES.TEXT}
                isClearable
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChargesOnChange(e.target.value)}
              />
            </div>
            <div className='flex-center-between gap-16px rounded-8px'>
              <Button ofStyle='outlined' type='button' onClick={handleApply}>
                {t('Apply')}
              </Button>
              {watch(CONFIRM_CHANGES_FORM_FIELD_NAMES.COD_CHARGES_AMOUNT) && (
                <div 
                  className='flex-center-center border border-danger p-10px rounded-8px cursor-pointer' 
                  onClick={handleDelete}
                >
                  <Trash2 size={20} color='var(--bs-danger)' />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className='text-dark flex-center-between txt-body-md'>
          <div className='flex-center-start gap-8px'>
            <span className='text-dark-5'>{t('COD Charges')}</span>
            {watch(CONFIRM_CHANGES_FORM_FIELD_NAMES.COD_CHARGES_AMOUNT) ? (
              <span
                className='cursor-pointer edit-button txt-sub-md bg-primary-global'
                onClick={() => toggleCodCharges(true)}
              >
                {EditIcon({ width: 16, height: 16 })}
              </span>
            ) : null}
          </div>

          {watch(CONFIRM_CHANGES_FORM_FIELD_NAMES.COD_CHARGES_AMOUNT) ? (
            <div>
              {watch(CONFIRM_CHANGES_FORM_FIELD_NAMES.COD_CHARGES_AMOUNT)}{' '}{currency}
            </div>
          ) : (
            <div
              className='text-primary cursor-pointer'
              onClick={() => toggleCodCharges(true)}
            >
              {t('Apply')}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CodCharges; 