import { PAGINATION_PER_PAGE_VALUES } from '@src/App.constants'
import { useOnClickOutside } from '@src/utility/hooks/useOnClickOutside'
import { useRTL } from '@src/utility/hooks/useRTL'
import { useEffect, useRef, useState } from 'react'
import { ChevronDown, ChevronLeft, ChevronRight, ChevronUp } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

const TablePagination = props => {

  const [isRtl] = useRTL()
  const { t } = useTranslation()
  const paginationDropdownRef = useRef(null)
  const [tempItemsPerPage, setTempItemsPerPage] = useState(20)
  const [isPaginationDropdownOpen, setIsPaginationDropdownOpen] = useState(false)

  const { currentPage, onChangePage, paginationComponentOptions: { meta, handlePagination } } = props
  const { current_page, last_page, 
    // total,
    per_page
  } = meta

  const toggleDropdown = () => {
    setIsPaginationDropdownOpen(prev => !prev)
  }
  
  const handleOnPaginationDropdownItemClick = perPage => {
    handlePagination({ selected: 1, per_page: perPage })
    setTempItemsPerPage(perPage)
    setIsPaginationDropdownOpen(false)
  }
  
  useOnClickOutside(paginationDropdownRef, () => {
    setIsPaginationDropdownOpen(false)
  })

  useEffect(() => {
    if (currentPage !== current_page) onChangePage(current_page)
    setTempItemsPerPage(per_page)
  }, [current_page])
  
  return (
    <div className="custom-paginate flex-center-between">
      <div className="items-per-page d-flex align-items-center">
        <div className="no-of-items flex-center-between" ref={paginationDropdownRef}>
          <Dropdown isOpen={isPaginationDropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle
              className="btn-sm border p-0 bg-white me-1 pagination-menu d-flex"
              color="flat-dark"
            >
              <div className="pagination-dropdown-button flex-center-between">
                <div>{tempItemsPerPage}</div>
                {isPaginationDropdownOpen ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
              </div>
            </DropdownToggle>
            <DropdownMenu className="pagination-dropdown-menu">
              {PAGINATION_PER_PAGE_VALUES.map((perPage, idx) => (
                <div className="pagination-dropdown-item d-flex align-items-center cursor-pointer" key={idx}>
                  <input type="radio" onChange={() => handleOnPaginationDropdownItemClick(perPage)} checked={per_page === perPage} />
                  <div onClick={() => handleOnPaginationDropdownItemClick(perPage)}>{perPage}</div>
                </div>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
        <div className="text">{t('Items per page')}</div>
      </div>
      <div className="move-prev-next d-flex align-items-center">
        <div className="pages pe-1">
          {currentPage} {t('of')} {last_page} {last_page > 1 ? t('Pages') : t('Page')}
        </div>
        <div className="prev-next d-flex ms-1 me-1 align-items-center">
          <button
            disabled={current_page === 1}
            className='ms-1 me-1 chevron-icons'
            onClick={() => {
              handlePagination({ selected: currentPage - 1, per_page })
            }}
          >
            {isRtl ? <ChevronRight/> : <ChevronLeft />}
          </button>
          <div className="page-count flex-center-center">
            <div color='var(--bs-primary)'>{current_page}</div>
          </div>
          <button
            disabled={current_page === last_page}
            className='ms-1 me-1 chevron-icons '
            onClick={() => {
              handlePagination({ selected: (currentPage || 0) + 1, per_page })
            }}
          >
            {isRtl ? <ChevronLeft/> : <ChevronRight/>}
          </button>
        </div>
      </div>
    </div>
  )
}

export default TablePagination;