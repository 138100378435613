import CustomTag from '@src/@core/components/ui/badge/CustomTag';
import { FINAL_ORDER_TOTAL_LABELS } from '@src/views/sales/sales.constant';
import { JSX } from 'react';
import { ORDER_LOG_TRANSITION_TYPE } from './constants';
import { iDetail, iDifferenceParams, iOrderItem, iTransitionDetailsParams, iTransitionValueParams } from './types';

/**
 * Formats a full name from first and last name components
 * @param first - First name
 * @param last - Last name
 * @returns Formatted full name or empty string if both names are missing
 */
export const formatName = (first?: string, last?: string): string => {
  if (!first && !last) return '';
  return `${first || ''} ${last || ''}`.trim();
};

/**
 * Returns appropriate transition tag component based on transition type
 * Used to visually indicate the type of change (Added/Removed/Updated)
 * @param transitionsType - Type of transition from ORDER_LOG_TRANSITION_TYPE
 */
export const getTransitionTag = (transitionsType: string | undefined | null) => {
  switch (transitionsType) {
  case ORDER_LOG_TRANSITION_TYPE.ITEM_ADDED:
    return <CustomTag title='Newly Added' className='text-success bg-success-light' />;
  case ORDER_LOG_TRANSITION_TYPE.ITEM_REMOVED:
    return <CustomTag title='Removed' className='text-danger bg-danger-light' />;
  case ORDER_LOG_TRANSITION_TYPE.ITEM_UPDATED:
    return <CustomTag title='Updated' className='text-info bg-info-light' />;
  case ORDER_LOG_TRANSITION_TYPE.UPDATED:
    return <CustomTag title='Updated' className='text-info bg-info-light' />;
  default:
    return null;
  }
};

/**
 * Calculates and formats the difference between two values
 * Shows if value increased or decreased with appropriate formatting for prices and weights
 * @param params - Object containing current and previous values with format flags
 */
export const getDifference = ({ current, previous = 0, isPrice = false, currency, isWeighted = false, uom = '' }: iDifferenceParams): JSX.Element => {
  const diff = current - previous;
  const formattedDiff = isPrice ? Math.abs(diff).toFixed(2) : Math.abs(diff);
  const unit = isPrice ? ` ${currency}` : isWeighted ? ` ${uom}` : '';

  if (diff > 0) {
    return (
      <span>
        (Increased by {formattedDiff}
        {unit})
      </span>
    );
  }
  return (
    <span>
      (Reduced by {formattedDiff}
      {unit})
    </span>
  );
};

/**
 * Formats transition values with appropriate display format
 * Handles both price and non-price values, showing differences when values have changed
 * @param params - Object containing value details and formatting options
 * @returns Formatted value as number, string or JSX element
 */
export const formatTransitionValue = ({ current, previous, isUpdated, formatter, options }: iTransitionValueParams): number | string | JSX.Element => {
  if (!isUpdated || current === previous) {
    if (options?.isPrice && options.currency) {
      return `${current} ${options.currency}`;
    }
    if (options?.isWeighted && options.uom) {
      return `${current} ${options.uom}`;
    }
    return current;
  }

  return (
    <div className='flex-center-start gap-8px'>
      <span className='txt-sub-md'>
        {current}
        {options?.isPrice && options.currency ? ` ${options.currency}` : ''}
        {options?.isWeighted && options.uom ? ` ${options.uom}` : ''}
      </span>
      <span className='txt-sub-rg'>
        {formatter({
          current,
          previous: previous || 0,
          isPrice: options?.isPrice,
          currency: options?.currency,
          isWeighted: options?.isWeighted,
          uom: options?.uom,
        })}
      </span>
    </div>
  );
};

/**
 * Formats tax percentage value with currency
 * Displays both percentage and actual tax amount with currency
 * @param itemData - Order item containing tax details
 */
export const formatTaxPercentageValue = (itemData: iOrderItem): JSX.Element => {
  return (
    <div className='flex-center-start gap-8px'>
      <span className='txt-sub-md'>{itemData.tax_percent}%</span>
      <span className='txt-sub-rg'>
        ({itemData.tax.toFixed(2)}&nbsp;{itemData.currency})
      </span>
    </div>
  );
};

/**
 * Extracts SKU details from order item
 * Used for displaying SKU information in the UI
 * @param itemData - Order item containing SKU details
 */
export const getSkuDetails = (itemData: iOrderItem) => ({
  img: itemData.sku_images?.[0]?.default,
  name: itemData.sku_name,
  code: itemData.seller_sku_code,
});

/**
 * Gets basic item details for non-update transitions
 */
const getBasicItemDetails = (itemData: iOrderItem): iDetail[] => {
  if (itemData.is_weighted) {
    return [{
      label: 'Ordered Weight',
      value: `${itemData.ordered_weight.value} ${itemData.uom}`,
      previousValue: null
    }];
  }
  
  return [{
    label: 'Ordered Quantity',
    value: itemData.quantity,
    previousValue: null
  }];
};

/**
 * Gets weight-related transition details
 */
const getWeightTransitionDetails = (
  itemData: iOrderItem,
  previousItemData: iOrderItem | null,
  isItemUpdatedTransition: boolean
): iDetail | null => {
  const weightChanged = itemData.ordered_weight.value !== previousItemData?.ordered_weight.value;
  if (!weightChanged) return null;

  return {
    label: 'Ordered Weight',
    value: formatTransitionValue({
      current: itemData.ordered_weight.value,
      previous: previousItemData?.ordered_weight.value,
      isUpdated: isItemUpdatedTransition,
      formatter: getDifference,
      options: {
        isWeighted: true,
        uom: itemData.uom,
      },
    }),
    previousValue: previousItemData ? (
      <>{previousItemData.ordered_weight.value}&nbsp;{previousItemData.ordered_weight.uom}</>
    ) : null,
  };
};

/**
 * Gets quantity-related transition details
 */
const getQuantityTransitionDetails = (
  itemData: iOrderItem,
  previousItemData: iOrderItem | null,
  isItemUpdatedTransition: boolean
): iDetail | null => {
  const quantityChanged = itemData.quantity !== previousItemData?.quantity;
  if (!quantityChanged) return null;

  return {
    label: 'Ordered Quantity',
    value: formatTransitionValue({
      current: itemData.quantity,
      previous: previousItemData?.quantity,
      isUpdated: isItemUpdatedTransition,
      formatter: getDifference,
    }),
    previousValue: previousItemData?.quantity || null,
  };
};

/**
 * Gets price-related transition details
 */
const getPriceTransitionDetails = (
  itemData: iOrderItem,
  previousItemData: iOrderItem | null,
  isItemUpdatedTransition: boolean
): iDetail | null => {
  const priceChanged = itemData.unit_price !== previousItemData?.unit_price;
  if (!priceChanged) return null;

  return {
    label: 'Unit Price',
    value: formatTransitionValue({
      current: itemData.unit_price,
      previous: previousItemData?.unit_price || null,
      isUpdated: isItemUpdatedTransition,
      formatter: getDifference,
      options: {
        isPrice: true,
        currency: itemData.currency,
      },
    }),
    previousValue: previousItemData ? (
      <>{previousItemData.unit_price}&nbsp;{previousItemData.currency}</>
    ) : null,
  };
};

/**
 * Gets price-related transition details
 */
const getItemTotalTransitionDetails = (
  itemData: iOrderItem,
  previousItemData: iOrderItem | null,
  isItemUpdatedTransition: boolean
): iDetail | null => {
  const priceChanged = itemData.total !== previousItemData?.total;
  if (!priceChanged) return null;

  return {
    label: 'Total',
    value: formatTransitionValue({
      current: itemData.total,
      previous: previousItemData?.total || null,
      isUpdated: isItemUpdatedTransition,
      formatter: getDifference,
      options: {
        isPrice: true,
        currency: itemData.currency,
      },
    }),
    previousValue: previousItemData ? (
      <>{previousItemData.total}&nbsp;{previousItemData.currency}</>
    ) : null,
  };
};

/**
 * Gets note-related transition details
 */
const getNoteTransitionDetails = (
  itemData: iOrderItem,
  previousItemData: iOrderItem | null
): iDetail | null => {
  const noteChanged = itemData.customer_note !== previousItemData?.customer_note;
  if (!noteChanged) return null;

  return {
    label: 'Customer Note',
    value: itemData.customer_note || '-',
    previousValue: previousItemData?.customer_note || null,
  };
};

/**
 * Gets tax-related transition details
 */
const getTaxTransitionDetails = (
  itemData: iOrderItem,
  previousItemData: iOrderItem | null
): iDetail | null => {
  const taxChanged = itemData.tax_percent !== previousItemData?.tax_percent || 
                    itemData.tax !== previousItemData?.tax;
  if (!taxChanged) return null;

  return {
    label: 'Tax Percentage',
    value: formatTaxPercentageValue(itemData),
    previousValue: previousItemData ? formatTaxPercentageValue(previousItemData) : null,
  };
};

/**
 * Main function to generate transition details for an item
 * Orchestrates the collection of all relevant details based on item state
 */
export const getItemTransitionDetails = ({ 
  itemData, 
  previousItemData, 
  isItemUpdatedTransition 
}: iTransitionDetailsParams): iDetail[] => {
  // For non-update transitions, return basic details
  if (!isItemUpdatedTransition) {
    return getBasicItemDetails(itemData);
  }

  // For update transitions, collect all changed details
  const details: iDetail[] = [];

  // Get quantity or weight changes
  const quantityOrWeightDetails = itemData.is_weighted
    ? getWeightTransitionDetails(itemData, previousItemData, isItemUpdatedTransition)
    : getQuantityTransitionDetails(itemData, previousItemData, isItemUpdatedTransition);

  if (quantityOrWeightDetails) details.push(quantityOrWeightDetails);

  // Get other changes
  const priceDetails = getPriceTransitionDetails(itemData, previousItemData, isItemUpdatedTransition);
  const itemTotalDetails = getItemTotalTransitionDetails(itemData, previousItemData, isItemUpdatedTransition);
  const noteDetails = getNoteTransitionDetails(itemData, previousItemData);
  const taxDetails = getTaxTransitionDetails(itemData, previousItemData);

  // Add all changed details
  [priceDetails, noteDetails, taxDetails, itemTotalDetails].forEach(detail => {
    if (detail) details.push(detail);
  });

  return details;
};

export const getTransactionAction = (transactionType: string) => {
  if (transactionType === FINAL_ORDER_TOTAL_LABELS.REFUND_DUE.transactionType) {
    return 'Refunded';
  } else if (transactionType === FINAL_ORDER_TOTAL_LABELS.DUE_AMOUNT.transactionType) {
    return 'Paid';
  }
};