import React, { RefObject, useEffect } from 'react';

interface UseKeyboardNavigationProps {
  isOpen: boolean
  // eslint-disable-next-line no-unused-vars
  setIsOpen: (isOpen: boolean) => void
  query: string
  // eslint-disable-next-line no-unused-vars
  setQuery: (query: string) => void
  // eslint-disable-next-line no-unused-vars
  setResults: (results: unknown) => void
  inputRef: RefObject<HTMLInputElement>
  flattenedResults: unknown[]
  selectedIndex: number
  // eslint-disable-next-line no-unused-vars
  setSelectedIndex: (index: number) => void
  // eslint-disable-next-line no-unused-vars
  setIsNavigatingWithKeyboard: (isNavigating: boolean) => void
  // eslint-disable-next-line no-unused-vars
  handleNavigate: (path: string) => void
}

export const useKeyboardNavigation = ({
  isOpen,
  setIsOpen,
  setQuery,
  setResults,
  inputRef,
  flattenedResults,
  selectedIndex,
  setSelectedIndex,
  setIsNavigatingWithKeyboard,
  handleNavigate
}: UseKeyboardNavigationProps) => {
  // Handle keyboard shortcuts
  useEffect(() => {
    const handleGlobalKeyDown = (e: KeyboardEvent) => {
      if (e.key === '/' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setIsOpen(true);
        inputRef.current?.focus();
      }
      if (e.key === 'Escape') {
        setIsOpen(false);
        setQuery('');
        setResults({});
        inputRef.current?.blur();
      }
    };

    document.addEventListener('keydown', handleGlobalKeyDown);
    return () => document.removeEventListener('keydown', handleGlobalKeyDown);
  }, []);

  // Reset keyboard navigation when closing search
  useEffect(() => {
    if (!isOpen) {
      setIsNavigatingWithKeyboard(false);
      setSelectedIndex(-1);
    }
  }, [isOpen]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (!flattenedResults.length) return;

    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      e.preventDefault();
      setIsNavigatingWithKeyboard(true);
      
      if (selectedIndex === -1) {
        setSelectedIndex(e.key === 'ArrowDown' ? 0 : flattenedResults.length - 1);
      } else {
        // @ts-expect-error fixme
        setSelectedIndex(prev => {
          if (e.key === 'ArrowDown') {
            return prev + 1 >= flattenedResults.length ? 0 : prev + 1;
          } else {
            return prev - 1 < 0 ? flattenedResults.length - 1 : prev - 1;
          }
        });
      }
    } else if (e.key === 'Enter' && selectedIndex >= 0) {
      e.preventDefault();
      const selected = flattenedResults[selectedIndex];
      handleNavigate((selected as { path: string, parentPath: string }).path || (selected as { parentPath: string }).parentPath);
    }
  };

  return {
    handleKeyDown
  };
}; 