import React from 'react';
import { REGEX_PATTERN } from '../../../App.constants';

interface HighlightProps {
  text: string;
  query: string;
  className?: string;
}

const Highlight: React.FC<HighlightProps> = ({ 
  text, 
  query, 
  className = 'tw-bg-yellow tw-rounded'  
}) => {
  if (!query || !text) return <>{text}</>;
  
  const words = query.toLowerCase()
    // Split the query string on whitespace (spaces/tabs/newlines)
    .split(REGEX_PATTERN.WHITESPACE)
    // Remove single-character words to avoid highlighting common characters
    .filter(word => word.length > 1)
    // Escape special regex characters to match them literally
    .map(word => word.replace(REGEX_PATTERN.SPECIAL_CHARS, '\\$&'));
  
  if (words.length === 0) return <>{text}</>;
  
  // Create regex to match whole words only:
  // Example: if query is "hello world", creates pattern: \b(hello|world)\b
  const regex = new RegExp(`${REGEX_PATTERN.WORD_BOUNDARY}(${words.join('|')})${REGEX_PATTERN.WORD_BOUNDARY}`, 'gi');
  const parts = text.split(regex);
  
  return (
    <>
      {parts.map((part, i) => {
        if (words.some(word => part.toLowerCase() === word.toLowerCase())) {
          return <span key={i} className={className}>{part}</span>;
        }
        return part;
      })}
    </>
  );
};

export default Highlight;