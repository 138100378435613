import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import TableActionDropdown from './components/TableActionDropdown';
import { ACTION_COLUMN, ACTION_ICONS } from './globalTable.constant';

export const externalTableStyles = {
  headCells: {
    style: {
      color: 'var(--bs-dark)',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '20px !important'
    }
  },
  cells: {
    style: {
      fontSize: '12px',
      fontWeight: 400,
      color: 'var(--bs-dark)',
      lineHeight: '18px'
    }
  },
  expanderButton: {
    style: {
      color: 'var(--bs-primary)',
      backgroundColor: 'transparent',
      '&:hover:enabled': {
        cursor: 'pointer',
        color: 'var(--bs-primary)',
        backgroundColor: 'var(--bs-primary-lighter) !important'
      },
      '&:disabled': {
        cursor: 'not-allowed',
        color: 'var(--bs-primary-light)'
      },
      '&:focus': {
        backgroundColor: 'transparent !important'
      }
    }
  },
}

export const useCustomTableStyles = (customStyles) => {
  const customTableStyles = useMemo(() => {
    return {
      ...externalTableStyles,
      ...customStyles
    }
  }, [customStyles])

  return customTableStyles
};

export const useTransformedTableColumns = ({columns, getActionColumnData, meta, hasSerialNumber}) => {
  const { t } = useTranslation();
  const copyColumns = [...columns]
  const transformedColumn = useMemo(() => {
    // if (hasSerialNumber) {
    //   const serialNumberColumn = {
    //     id: 'serial_no',
    //     key: 'serial_no',
    //     name: t('S. No.'),
    //     width: '60px',
    //     reorder: false,
    //     center: true,
    //     selector: (row, index) => {
    //       const serialNumber = meta?.current_page ? ((meta.current_page - 1) * meta.per_page) + index + 1 : index + 1
    //       return serialNumber
    //     }
    //   }
    //   copyColumns.unshift(serialNumberColumn)
    // }

    const columnsData = copyColumns.map((column) => {
      return {
        ...column,
        // id: index + 1,
        // key: column.hasOwnProperty('key') ? column.key : index + 1,
        // reorder: Object.hasOwn(column, 'reorder') ? column.reorder : true,
        name: (column.name && typeof column.name === 'string') ? <div title={column.name} className='h-100 w-100 flex-center-start px-10px custom-table-header-cell'>
          <span className='text-truncate'>{column.name}</span>
        </div> : <div className='h-100 w-100 flex-center-start px-10px'>{column.name}</div>,
        reorderColumnName: (column.reorderColumnName && typeof column.reorderColumnName === 'string') ? <div title={column.reorderColumnName} className='h-100 w-100 flex-center-start px-10px custom-table-reorder-column-name'>
          <span className='text-truncate'>{column.reorderColumnName}</span>
        </div> : column.reorderColumnName
      }
    })

    if (getActionColumnData) {
      const actionColumn = {
        id: 'action',
        key: 'action',
        name: <div className='h-100 w-100 flex-center-start px-10px custom-table-header-cell'>{t('Action')}</div>,
        width: '80px',
        reorder: false,
        allowOverflow: true,
        cell: (row) => {
          const actionColumnData = getActionColumnData(row)
          return <TableActionDropdown row={row} actionColumnData={actionColumnData} />
        }
      }

      columnsData.push(actionColumn)
    }
    return columnsData
  }, [columns])
  return transformedColumn
}

export const getActionIcon = (icon, size) => {
  const actionIcon = ACTION_ICONS.find((item) => item.key === icon);
  return actionIcon ? <actionIcon.icon width={size || '16'} height={size || '16'} /> : null;
}

export const handleCheckActionColumnPresent = (columnsToShow) => {
  return columnsToShow.find((column) => {
    let columnTitle = null;
  
    // Extract title if column.name is a React element
    if (typeof column.name === 'object' && column.name?.props?.title) {
      columnTitle = column.name.props.title;
    } else if (typeof column.name === 'string') {
      columnTitle = column.name;
    }
  
    // Check if the column title includes "action"
    if (columnTitle && !column.omit) {
      return columnTitle.toLowerCase().includes(ACTION_COLUMN.value);
    } else if (!columnTitle && !column.omit  && column.key === ACTION_COLUMN.key) {
      return true;
    }
    return false;
  });
}